import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@zefr/style-guide';
import { commonNS, i18n } from '@zefr/style-guide/dist/core/i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { resources as growthResources } from '../../plugins/gatsby-plugin-zefr-growth/locale/resources';
import { pageSize } from '../../src/helpers/style';
import { getMaxWidthPercentage, getMaxWidthPixels } from './helpers/themeHelpers';
import { Resources, resources } from './locale/resources';
import muiTheme, { BG_COLOR } from './theme';

// import createStore from "./src/state/createStore"

const allResources: Resources[] = [resources, growthResources];

const geti18nInstance = () => {
  allResources.forEach((resource) => {
    Object.keys(resource).forEach((lng) => {
      const details = resource[lng];
      Object.keys(details).forEach((ns) => {
        i18n.addResourceBundle(lng, ns, resource[lng][ns], true);
      });
    });
  });
  i18n.setDefaultNamespace(commonNS);
  return i18n;
};

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      body: {
        margin: 0,
      },
      '.MuiTooltip-tooltip': {
        backgroundColor: theme.palette.info.contrastText,
        boxShadow: `0px 4px 30px 0px rgba(1, 162, 255, 0.50)`,
      },
      '.MuiButton-root, .landing-login-button': {
        textTransform: 'capitalize',
        letterSpacing: '2px',
        padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
      },
      '.landing-login-button': {
        width: '200px',
        height: '38px',
      },
      '.MuiButtonBase-root.MuiButton-root.MuiButton-sizeLarge': {
        fontSize: '0.75rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
        },
      },
      'html, body, #___gatsby, #gatsby-focus-wrapper': {
        backgroundColor: BG_COLOR,
      },
      'html, body, #___gatsby, #gatsby-focus-wrapper, .site-layout, .site-layout-container, #gatsby-focus-wrapper, .h-full':
        {
          height: '100%',
        },
      '.h-page': {
        minHeight: pageSize,
      },
      '.bg-decal': {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 0',
        backgroundImage: 'url("/images/zefr-bg-decal.jpg")',
      },
      'div.apexcharts-tooltip.apexcharts-theme-dark': {
        backgroundColor: theme.palette.info.contrastText,
        color: theme.palette.info.main,
        padding: theme.spacing(2),
        boxShadow: `0px 4px 30px 0px rgba(1, 162, 255, 0.50)`,
        '& span': {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.body1.fontSize,
          color: theme.palette.info.main,
          maxWidth: '150px',
          height: 'fit-content',
          textWrap: 'wrap',
        },
      },
      '.max-width-percentage': {
        ...getMaxWidthPercentage(theme),
      },
      '.max-width-pixel': {
        ...getMaxWidthPixels(theme),
      },
      '.markdown': {
        '& *': {
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.secondary.main,
          margin: 0,
        },
        '& strong': {
          fontWeight: '700',
        },
        '& p:not(:first-of-type):has(> :not(p))': {
          marginTop: theme.spacing(4),
        },
      },
      '.form-logo-icon.big': {
        fontSize: '4rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '5rem',
        },
      },
      '.zefr-form': {
        '& .MuiInputBase-input': {
          fontWeight: '500',
        },

        '& .MuiFormLabel-root, & .MuiFormControlLabel-label, & .MuiCheckbox-root:not(.Mui-checked),  & .MuiInputBase-root':
          {
            color: theme.palette.secondary.main,
          },
        '& .MuiFormControlLabel-label': {
          fontWeight: '400',
          fontSize: '1rem',
        },
        '& .MuiFormLabel-root,  & .MuiInputBase-root': {
          fontWeight: '700',
          fontSize: '1.125rem',
        },
        '&.MuiList-root': {
          '& .MuiMenuItem-root': {
            color: theme.palette.common.black,
            fontWeight: '700',
            fontSize: '1.125rem',
            '&:hover': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.dark,
            },
            '&.Mui-selected': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
      },
    })}
  />
);
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: React.ReactNode }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  //   const store = createStore()
  //   return <Provider store={store}>{element}</Provider>
  const i18nInstance = geti18nInstance();
  return (
    <I18nextProvider i18n={i18nInstance}>
      <ThemeProvider theme={muiTheme}>
        {globalStyles}
        {element}
      </ThemeProvider>
    </I18nextProvider>
  );
};
