import { Box, CircularProgress, LinearProgress, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@zefr/style-guide';
import Typography from '@zefr/style-guide/dist/ui/Typography';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../gatsby-theme-zefr/components/Link';
import { APP_ImageVerifai } from '../atoms/toolsAtom';
import useImageVerifaiMutation from '../hooks/api/v0/useImageVerifaiMutation';
import { ImageVerifaiExamplesFlagData } from '../types/launchDarkly';
import TextInputBar from './TextInputBar';

const defaultExampleData: ImageVerifaiExamplesFlagData = {};

const SImg = styled('img')(() => {
  return {
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: '25vh',
  };
});

const StyledLink = styled(Link)(({ theme }) => {
  return {
    '&, & *': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    '&:hover': {
      '&, & *': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
      },
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1,
  };
});

interface SubmitData {
  src: string;
}

interface FormProps {
  onSubmit?: (data: SubmitData) => void;
  onCancel?: () => void;
}

interface ImageVerifaiProps extends FormProps {}

const ImageVerifai: React.FC<ImageVerifaiProps> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState('');
  const {
    mutateAsync,
    data,
    error,
    isPending: isLoading,
    isError,
    isSuccess,
  } = useImageVerifaiMutation();

  const setNewImage = useCallback(
    (src?: string) => {
      setImageSrc(src || '');
      if (src) {
        try {
          mutateAsync({ url: src });
        } catch {}
        onSubmit({ src: src });
      }
    },
    [onSubmit, setImageSrc, mutateAsync],
  );
  return (
    <Box
      data-cy="image-verifai"
      display={'flex'}
      width={'100%'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={8}
      maxHeight={'100%'}
      overflow={'auto'}
    >
      <Grid
        width={'100%'}
        container
        alignItems={'center'}
        boxSizing={'border-box'}
        sx={{
          '& > .MuiGrid2-root': {
            boxSizing: 'border-box',
            padding: '10px',
          },
        }}
      >
        <Grid
          xs={12}
          md={6}
          gap={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <img
              style={{ width: '100%', height: 'auto', maxWidth: '200px' }}
              src={'/images/image_verifai_logo.png'}
            />
            <Typography variant="h3" color="black" fontWeight={'bold'}>
              Quick & Accurate Identification of AI-Generated Images
            </Typography>
            <Typography variant="subtitle1" color="black" fontWeight={'bold'}>
              Part of Zefr's Multi-Modal AI Analysis & Fact Checking Integrated Approach to
              Detecting Misinformation
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <TextInputBar
              onSubmit={setNewImage}
              placeholder="https://example.com/image.jpg"
              value={imageSrc}
              required={true}
              endAdornmentTypography={'Verify'}
            />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Paper
            sx={{
              minWidth: '100%',
              minHeight: '400px',
              display: isSuccess || isError || isLoading ? 'flex' : 'none',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box component={Grid} container>
                <Grid
                  xs={12}
                  p={3}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <SImg src={imageSrc} />
                </Grid>

                <Grid
                  xs={12}
                  py={1}
                  px={2}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Typography variant="h5" color="black" fontWeight={'bold'}>
                    {t(`growth.score`)}
                  </Typography>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    mt={1}
                    gap={1}
                  >
                    {isSuccess ? (
                      <>
                        <Typography variant="subtitle1" color="black" fontWeight={'bold'}>
                          {(data?.confidence_score! * 100).toFixed(2).replace(/[.,]00$/, '')}%{' '}
                          {t(`growth.${APP_ImageVerifai}.confident`)}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          bgcolor={data?.is_fake === true ? 'red' : 'green'}
                          fontWeight={'bold'}
                        >
                          {data?.is_fake === true ? 'FAKE' : 'REAL'}
                        </Typography>
                      </>
                    ) : isLoading ? (
                      <LinearProgress sx={{ width: '15ch' }} />
                    ) : isError ? (
                      <>
                        <Typography variant="subtitle1" color="red" fontWeight={'bold'}>
                          {t(`growth.error`)}
                        </Typography>
                        <Typography variant="subtitle1" color="red" fontWeight={'bold'}>
                          {error.message}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle1" color="black" fontWeight={'bold'}>
                          -
                        </Typography>
                        <Typography variant="subtitle1" color="black" fontWeight={'bold'}>
                          -
                        </Typography>
                      </>
                    )}
                  </Box>
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageVerifai;
