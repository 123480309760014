import { withPrefix } from 'gatsby';
import React from 'react';
import { getIfUserHasPermissionsFunc } from '../../../src/helpers/pluginHandling/helpers';
import PrivateRoute from '../../gatsby-plugin-zefr-auth0/helpers/ProtectedRoute';
import DemoApp from './DemoApp';
import GrowthApp from './GrowthApp';

export const BASE_URL = process.env.GATSBY_GROWTH_PATH || 'tools';
export const DEMO_BASE_URL = process.env.GATSBY_DEMO_PATH || 'demo';
export const DEMO_FORM_URL = process.env.GATSBY_DEMO_FORM_PATH || 'form';

export const getGrowthRouter: getIfUserHasPermissionsFunc<React.ReactNode> = (user) => {
  if (user?.authenticated) {
    return <PrivateRoute component={GrowthApp} key={BASE_URL} path={withPrefix(`${BASE_URL}/*`)} />;
  }
  return undefined;
};

export const getDemoRouter: getIfUserHasPermissionsFunc<React.ReactNode> = (user) => {
  if (user?.authenticated) {
    return (
      <PrivateRoute component={DemoApp} key={BASE_URL} path={withPrefix(`${DEMO_BASE_URL}/*`)} />
    );
  }
  return undefined;
};
