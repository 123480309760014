import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

const StyledChipDots = styled(Box)(({ theme, children }) => {
  let kidCount = 0;
  React.Children.forEach(children, (k) => {
    if (typeof k !== 'undefined' && k !== null) {
      kidCount += 1;
    }
  });
  const lineHeightBase = 24;
  const lineHeightAdditive = 4;
  const lineHeight = `${lineHeightBase - lineHeightAdditive * (kidCount - 1)}px`;
  const fontSizeBase = 16;
  const fontSizeAdditive = 4;
  const fontSize = `${fontSizeBase - fontSizeAdditive * (kidCount - 1)}px`;
  return {
    '&.MuiChip-deleteIcon': {
      lineHeight,
      margin: 0,
      '& > *': {
        borderRadius: '50%',
        width: fontSize,
        height: fontSize,
      },
    },
  };
});

export interface ChipDotsProps extends BoxProps {}

const ChipDots: React.FC<ChipDotsProps> = ({
  display = 'flex',
  flexDirection = 'column',
  justifyContent = 'flex-start',
  alignItems = 'center',
  gap = '1px',
  children,
  className = '',
  ...props
}) => {
  return (
    <StyledChipDots
      className={`chip-dots ${className ? ` ${className}` : ''}`}
      {...props}
      {...{ display, flexDirection, justifyContent, alignItems, gap }}
    >
      {children}
    </StyledChipDots>
  );
};
export default ChipDots;
