import { InfoOutlined } from '@mui/icons-material';
import { Grid2Props, TooltipProps, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import ZefrTooltip from '../../../gatsby-theme-zefr/components/ZefrTooltip';
import { SummaryItemGrid } from './components';

export interface TextGridItemProps extends Grid2Props {
  value?: React.ReactNode;
  valueTypographyProps?: Partial<TypographyProps>;
  label?: React.ReactNode;
  labelTypographyProps?: Partial<TypographyProps>;
  tooltipTitle?: React.ReactNode;
  tooltipProps?: Partial<TooltipProps>;
}

const TextGridItem: React.FC<TextGridItemProps> = ({
  value,
  label,
  valueTypographyProps = {
    sx: {
      position: 'relative',
    },
  },
  labelTypographyProps = {},
  tooltipTitle,
  tooltipProps = {},
  ...props
}) => {
  return (
    <SummaryItemGrid
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      px={4}
      py={2}
      {...props}
    >
      <Typography fontWeight={'bold'} color="inherit" variant="h2" {...valueTypographyProps}>
        {value}
      </Typography>
      <Typography color="inherit" variant="subtitle1" fontWeight={'500'} {...labelTypographyProps}>
        {label}
      </Typography>
      {tooltipTitle && (
        <ZefrTooltip title={tooltipTitle} {...tooltipProps}>
          <Typography
            alignSelf={'flex-end'}
            display={'flex'}
            height={'100%'}
            alignItems={'flex-end'}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              opacity: '50%',
              fontSize: theme.typography.h6.fontSize,
            })}
          >
            <InfoOutlined fontSize="inherit" />
          </Typography>
        </ZefrTooltip>
      )}
    </SummaryItemGrid>
  );
};

export default TextGridItem;
