import { HIGH, LOW, MEDIUM, NO_RISK, RiskLevel } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import { SalesDemoTargetAudienceOptionsData } from '../types/launchDarkly';
import { setFormContentAlignmentAtom } from './encodedFormAtoms';

type State = boolean | undefined;

const initialDialogState: State = undefined;

export const demoContentAlignmentDialogOpenAtom = atom(initialDialogState as State);

demoContentAlignmentDialogOpenAtom.debugLabel = 'Demo Content Alignment Dialog Open';

export const RISK_LEVEL_MAP = {
  [NO_RISK]: 'No Risk',
  [LOW]: 'Low Risk',
  [MEDIUM]: 'Medium Risk',
  [HIGH]: 'High Risk',
};

export const RISK_LEVEL_OPTIONS = Object.keys(RISK_LEVEL_MAP).map((key) => {
  return {
    value: key,
    label: RISK_LEVEL_MAP[key as keyof typeof RISK_LEVEL_MAP],
  };
});

const initialState: SalesDemoTargetAudienceOptionsData = {
  vertical: {
    label: 'Vertical',
    type: 'select',
    options: {
      auto: 'Auto',
      cpg_food_and_beverage: 'CPG (Consumer Packaged Goods) - Food & Beverage',
      cpg_household: 'CPG - Household',
      entertainment: 'Entertainment',
      fashion_and_apparel: 'Fashion & Apparel',
      financial_services: 'Financial Services',
      healthcare_and_pharmaceuticals: 'Healthcare & Pharmaceuticals',
      alcohol: 'Alcohol',
      music: 'Music',
      other: 'Other',
      restaurant_and_quick_serve: 'Restaurant & QSR (Quick Serve)',
      retail: 'Retail',
      software_and_technology: 'Software & Technology',
      telecommunications: 'Telecommunications',
      television: 'Television',
      travel: 'Travel',
    },
  },
};

const initialFormState: Record<string, string | string[] | number> = {};

const contentAlignmentOptionsAtom = atom(initialState);
contentAlignmentOptionsAtom.debugLabel = 'Content Alignment Options State';
const contentAlignmentOptionsFormAtom = atomWithReset(initialFormState);
contentAlignmentOptionsFormAtom.debugLabel = 'Content Alignment Form State';
const readContentAlignmentOptionsFormAtom = atom((get) => {
  return get(contentAlignmentOptionsFormAtom);
});

const writeContentAlignmentOptionFormAtom = atom(
  null,
  (get, set, data: { key: string; value?: string | string[] | number }) => {
    const { key, value } = data;
    const existingForm = get(contentAlignmentOptionsFormAtom);
    if (typeof value === 'undefined') {
      if (typeof existingForm[key] !== 'undefined') {
        delete existingForm[key];
      }
    } else {
      existingForm[key] = value;
    }
    set(setFormContentAlignmentAtom, existingForm);
  },
);

const clearContentAlignmentOptionsFormAtom = atom(null, (get, set) => {
  set(contentAlignmentOptionsFormAtom, RESET);
});

clearContentAlignmentOptionsFormAtom.debugPrivate = true;
writeContentAlignmentOptionFormAtom.debugPrivate = true;
readContentAlignmentOptionsFormAtom.debugPrivate = true;

const filteredContentAlignmentOptionsAtom = atom((get) => {
  const data = get(contentAlignmentOptionsAtom);
  const newData: Record<
    'text' | 'select' | 'multiselect' | string,
    SalesDemoTargetAudienceOptionsData
  > = {
    multiselect: {},
    select: {},
    text: {},
  };
  Object.keys(data).forEach((key) => {
    const myType = data[key].type;
    if (typeof myType !== 'undefined') {
      if (typeof newData[myType] === 'undefined') {
        newData[myType] = {};
      }
      newData[myType][key] = data[key];
    }
  });
  return newData;
});

filteredContentAlignmentOptionsAtom.debugLabel = 'Filtered Content Alignment Options';

export {
  clearContentAlignmentOptionsFormAtom,
  contentAlignmentOptionsAtom,
  filteredContentAlignmentOptionsAtom,
  readContentAlignmentOptionsFormAtom,
  writeContentAlignmentOptionFormAtom,
};

export default contentAlignmentOptionsFormAtom;
