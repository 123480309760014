import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@zefr/style-guide';
import Box, { BoxProps } from '@zefr/style-guide/dist/core/Box';
import { MainBody } from '@zefr/style-guide/dist/ui/MainBody';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { appAtom } from '../../atoms/appAtom';
import { pageTitleAtom } from '../../atoms/pageTitleAtom';
import { sidebarOpenAtom } from '../../atoms/sidebarOpenAtom';
import { SITE_APP_NONE } from '../../types/shared';
import MarkdownDialog from '../MarkdownDialog';
import SEO from '../SEO';
import SideBar from '../SideBar';
import TopNav from '../TopNav';

export interface LayoutProps extends BoxProps {}

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  [`&.app-${SITE_APP_NONE} > div`]: {
    width: '100%',
  },
  '& .site-layout-container': {
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
  },
}));

const Layout: React.FC<LayoutProps> = ({ children, id = 'site-layout', ...props }) => {
  const [sidebarStatus, setSidebar] = useAtom(sidebarOpenAtom);
  const pageTitle = useAtomValue(pageTitleAtom);
  const app = useAtomValue(appAtom);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  // This effect is only loaded once per window
  useEffect(() => {
    // If we're on desktop, and we haven't toggled the sidebar in any way yet, open it
    if (matches && typeof sidebarStatus === 'undefined') {
      if (app !== 'NONE') setSidebar(true);
    }
  }, [setSidebar, matches, app]);

  return (
    <Wrapper component={'section'} className={`site-layout app-${app}`} id={id} {...props}>
      <SEO title={pageTitle} />
      {app === 'NONE' ? (
        children
      ) : (
        <>
          <TopNav />
          <SideBar />
          <MainBody
            data-testid="layout-main"
            boxSizing={'border-box'}
            className="site-layout-container"
          >
            {children}
          </MainBody>
        </>
      )}
      <MarkdownDialog />
    </Wrapper>
  );
};

export default Layout;
