import { PluginOptions } from 'gatsby';
import wrapWithLayout from './wrap-with-layout';
import wrapWithProvider from './wrap-with-provider';
declare global {
  interface Window {
    VERSION: string;
  }
}

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithLayout;
export const onClientEntry = (_: undefined, opts: PluginOptions) => {
  const VERSION = (opts.version as string) || 'unknown';
  console.log(`[ZEFR] Site Loaded (v${VERSION})`);
  window.VERSION = VERSION;
};
