import { atom } from 'jotai';
import React from 'react';
import SideNavContent from '../../gatsby-plugin-zefr-growth/components/SideNavContent';
import { SITE_APP_GROWTH } from '../types/shared';
import { appAtom } from './appAtom';

type State = React.ReactNode | undefined;

const initialState: State = undefined;

const sidebarComponentAtom = atom<State>((get) => {
  const currentApp = get(appAtom);
  if (currentApp === SITE_APP_GROWTH) {
    return <SideNavContent />;
  }
  return initialState;
});

sidebarComponentAtom.debugLabel = 'Sidebar Component';

export { sidebarComponentAtom };
