import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import React from 'react';

interface LinkProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  disabled?: boolean;
  children?: React.ReactNode;
}

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = (props: LinkProps) => {
  const { children, to, activeClassName, ...other } = props;

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with zero or more periods with exactly one slash, and that anything else is external.
  const internal = /^\.*\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

export type { LinkProps };
export default Link;
