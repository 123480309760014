import { useMediaQuery, useTheme } from '@mui/material';
import { commonNS } from '@zefr/style-guide/dist/core/i18next';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { useAtom, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../../src/routes/routes';
import { sidebarOpenAtom } from '../../gatsby-theme-zefr/atoms/sidebarOpenAtom';
import Link from '../../gatsby-theme-zefr/components/Link';
import { SpacedList } from '../../gatsby-theme-zefr/components/SideBar/styled-components';
import { ToolAppData, ToolDetails, toolsAtom } from '../atoms/toolsAtom';
import { BASE_URL as MY_BASE_URL } from '../routes/routes';
import { AppsFlag } from '../types/launchDarkly';
import SideNavContentItem from './SideNavContentItem';

interface SideNavContentProps {}

const SideNavContent: React.FC<SideNavContentProps> = () => {
  const flags = useFlags();
  const setSidebar = useSetAtom(sidebarOpenAtom);
  const [toolApp, setToolApp] = useAtom(toolsAtom);
  const theme = useTheme();
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const availableApps = useMemo(() => {
    return flags[AppsFlag] || undefined;
  }, [flags]);
  const sideNavContent = useMemo(() => {
    const items: ToolAppData[] = [];
    Object.keys(ToolDetails).forEach((key) => {
      const enabled =
        typeof availableApps === 'undefined'
          ? true
          : typeof availableApps[key] === 'undefined'
            ? true
            : undefined;
      if (enabled) {
        const priority =
          typeof availableApps === 'undefined'
            ? ToolDetails[key as keyof typeof ToolDetails]?.priority
            : availableApps[key];
        items.push({
          ...ToolDetails[key as keyof typeof ToolDetails],
          active: key === toolApp,
          key,
          priority,
        });
      }
    });
    items.sort((a, b) => {
      return (
        t(a?.title || '', { defaultValue: a?.title }).localeCompare(
          t(b?.title || '', { defaultValue: b?.title }),
        ) || 0
      );
    });
    items.sort((a, b) => {
      return (a?.priority || 0) - (b?.priority || 0);
    });
    return items;
  }, [toolApp, t, availableApps]);
  const contentElements = useMemo(() => {
    return sideNavContent.map((data) => {
      const active = data.active;
      const key = data.key;
      return (
        <Link
          key={key}
          data-dd-action-name={`Side Nav Item ${key}`}
          to={`/${BASE_URL}/${MY_BASE_URL}${data?.href || '#'}`}
        >
          <SideNavContentItem
            onClick={() => {
              setToolApp(key as keyof typeof ToolDetails);
              if (!matches) {
                setSidebar(false);
              }
            }}
            content={t(`growth.tools.${data.title}` || '', {
              defaultValue: data.title,
              ns: commonNS,
            })}
            selected={active}
            disabled={active}
          />
        </Link>
      );
    });
  }, [sideNavContent, setToolApp, matches, t]);
  return <SpacedList data-cy="tools-side-nav-list">{contentElements}</SpacedList>;
};

export default SideNavContent;
