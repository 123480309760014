import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider, createStore } from 'jotai';
import { DevTools } from 'jotai-devtools';
import React, { Suspense } from 'react';
import { ENABLE_DEV_SSR, IS_DEV, getEnvVar, isProdUrl } from './helpers';

// import createStore from "./src/state/createStore"
const queryClient = new QueryClient();
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: React.ReactNode }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  //   const store = createStore()
  //   return <Provider store={store}>{element}</Provider>
  const store = createStore();
  const isProd = isProdUrl();
  const DATADOG_CLIENT_TOKEN = getEnvVar('GATSBY_DATADOG_CLIENT_ID', undefined) as string;
  datadogLogs.init({
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    env: isProd ? 'production' : 'stage',
    service: 'growth-team',
    version: typeof window !== 'undefined' ? window.VERSION : '0.0.0',
    clientToken: DATADOG_CLIENT_TOKEN,
  });
  datadogRum.init({
    site: 'datadoghq.com',
    env: isProd ? 'production' : 'stage',
    service: 'growth-team',
    version: typeof window !== 'undefined' ? window.VERSION : '0.0.0',
    applicationId: '3913b240-b563-4bf4-8f49-d6e6b9626253',
    clientToken: DATADOG_CLIENT_TOKEN,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
  });
  return (
    <Provider store={store}>
      {IS_DEV && !ENABLE_DEV_SSR ? <DevTools store={store} /> : undefined}
      <QueryClientProvider client={queryClient}>
        <Suspense fallback="Loading..">{element}</Suspense>
      </QueryClientProvider>
    </Provider>
  );
};
