import { styled, useTheme } from '@mui/material';
import { PieChartProps } from '@mui/x-charts/PieChart';
import { ApexOptions } from 'apexcharts';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { BRAND_GREEN } from '../../../gatsby-theme-zefr/theme';
import { chipFilterAtom } from '../../atoms/chipAtom';

const StyledChart = styled(Chart)(({ theme }) => {
  return {
    '& .apexcharts-legend': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& .apexcharts-legend-marker': {
        marginRight: theme.spacing(2),
      },
      '& .apexcharts-legend-text': {
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: '700 !important',
        fontFamily: `${theme.typography.fontFamily} !important`,
      },
    },
    '& foreignObject': {
      height: '100%',
    },
  };
});

export interface DonutChartProps extends Omit<PieChartProps, 'series'> {
  showLegend?: boolean;
}

const DonutChart: React.FC<DonutChartProps> = ({ showLegend = true, ...props }) => {
  const chipData = useAtomValue(chipFilterAtom);
  const theme = useTheme();
  const chartData: ApexOptions = useMemo(() => {
    const colors: string[] = [];
    const labels: string[] = [];
    Object.keys(chipData).forEach((key) => {
      const data = chipData[key];
      labels.push(key);
      colors.push(data.color || BRAND_GREEN);
    });
    const results: ApexOptions = {
      colors,
      labels,
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      legend: {
        position: 'bottom',
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        formatter: function (seriesName, opts) {
          return `${chipData[seriesName].title}`;
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        selection: {
          enabled: false,
        },
      },
      tooltip: {
        custom: ({ w, seriesIndex, ...params }) => {
          const key = w.globals.labels[seriesIndex];
          const data = chipData[key];
          return `<span>${data.title}: ${data.description}<span>`;
        },
      },
      responsive: [
        {
          breakpoint: theme.breakpoints.values.md,
          options: {
            chart: {
              // width: 200,
            },
          },
        },
      ],
    };

    return results;
  }, [chipData, theme.breakpoints.values.md]);
  return (
    <StyledChart options={chartData} series={[1, 1, 1]} type="donut" width="100%" height={'100%'} />
  );
};

export default DonutChart;
