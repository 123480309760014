/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { gzip } from 'pako';
import { getEnvVar } from '../../../../src/helpers';
import { API_PATH } from '../config/growth/v0';
import HttpInstance from '../http';

const AUTHORIZATION_HEADER_KEY = 'Authorization';

class ApiInstance extends HttpInstance {
  ifInvalid?: (config: AxiosRequestConfig) => boolean;
  constructor() {
    const APIGEE_API_KEY = getEnvVar(
      'GATSBY_APIGEE_API_KEY',
      undefined,
      'vXPamaWePgEKsyFWJ6s8uNNBjaG1LSej',
    ) as string;
    super({
      baseURL: API_PATH,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Apikey: APIGEE_API_KEY,
      },
      transformRequest: function (data, headers) {
        const verifiedData = typeof data === 'object' ? JSON.stringify(data) : data;
        // compress strings if over 1KB
        if (verifiedData === 'string' && verifiedData.length > 1024) {
          headers['Content-Encoding'] = 'gzip';
          return gzip(verifiedData);
        } else {
          // delete is slow apparently, faster to set to undefined
          headers['Content-Encoding'] = undefined;
          return verifiedData;
        }
      },
    });
    this.reqConfigHandler = async (
      config: InternalAxiosRequestConfig,
    ): Promise<InternalAxiosRequestConfig> => {
      if (process.env.NODE_ENV === 'test') {
        return Promise.resolve(config);
      }
      const hasAuth = config.headers ? Boolean(config.headers[AUTHORIZATION_HEADER_KEY]) : false;
      // if (!hasAuth) {
      //   // console.log('Aborting call to ', config.url)
      //   throw config;
      // }
      if (this.ifInvalid) {
        // console.log('triggering invalid function')
        if (this.ifInvalid(config)) {
          // console.log(
          //   'request is invalid be invalid, aborting call to ',
          //   config.url
          // )
          throw config;
        }
      }
      // console.log('Making request to', config.url);

      return Promise.resolve(config);
    };
    this.setupInterceptors();
  }

  updateJWT = (jwt?: string) => {
    if (jwt) {
      // @ts-ignore-next-line
      this.client.defaults.headers[AUTHORIZATION_HEADER_KEY] = `Bearer ${jwt}`;
    } else {
      // @ts-ignore-next-line
      delete this.client.defaults.headers[AUTHORIZATION_HEADER_KEY];
    }
    return jwt;
  };
}

export default new ApiInstance();
