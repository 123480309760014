import { LDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { getEnvVar } from '../../src/helpers';
import AuthProvider from './components/AuthProvider';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: { element: React.ReactNode }) => {
  const LAUNCH_DARKLY_CLIENT_SIDE_ID = getEnvVar(
    'GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID',
    undefined,
    '656f67b9f7bba40f8d1b1d97',
  ) as string;
  return (
    <LDProvider clientSideID={LAUNCH_DARKLY_CLIENT_SIDE_ID}>
      <AuthProvider>{element}</AuthProvider>;
    </LDProvider>
  );
};
