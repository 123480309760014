/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { ChevronRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SuitableMaxIcon } from '@zefr/style-guide/dist/ui/icons';
import getViewsAbbreviated from '@zefr/style-guide/dist/ui/utils/strings/getViewsAbbreviated';
import React, { useCallback, useMemo, useState } from 'react';
import { RISK_LEVEL_MAP } from '../../../../atoms/contentAlignmentAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import AverageDailyViewsGridItem from '../../../ChannelResults/AverageDailyViewsGridItem';
import ConceptsBox from '../../../ChannelResults/ConceptsBox';
import FakeLinearProgressBar from '../../../ChannelResults/FakeLinearProgressBar';
import RiskGridItem from '../../../ChannelResults/RiskGridItem';
import {
  PaddedFormControl,
  SummaryAccordion,
  SummaryItems,
} from '../../../ChannelResults/components';
import InternalIcon from '../../../InternalIcon';
import VideoTable from '../../../VideoTable';
import DownloadListButton from '../DownloadListButton';
import ChannelResultsHeader from '../StepInclusionResults/ChannelResultsHeader';
import { FormStepProps } from '../types';

const itemGridSize = {
  xs: 12,
  md: 6,
};

export const StepExclusionResults: React.FC<FormStepProps> = ({
  step: myStep = 5,
  'data-testid': testId,
  ...props
}) => {
  const [videoExpanded, setVideoExpanded] = useState(true);
  const {
    step,
    selectedPlatform,
    exclusionResults,
    riskLevel,
    listType,
    isExclusionResultLoading,
    isExclusionResultFetching,
    isExclusionResultSuccess,
  } = useDemoForm();
  /* #region 🐙 States */
  const riskLevelTitle = useMemo(() => {
    return typeof riskLevel !== 'undefined' && riskLevel >= 0
      ? Object.values(RISK_LEVEL_MAP)[riskLevel!]
      : 'Unknown';
  }, [riskLevel]);

  const isLoading = useMemo(
    () => isExclusionResultLoading || isExclusionResultFetching,
    [isExclusionResultFetching, isExclusionResultLoading],
  );
  const isSuccess = useMemo(() => isExclusionResultSuccess, [isExclusionResultSuccess]);

  const handleVideoAccordionClick = useCallback(
    (e: any) => {
      if (e.target.id !== 'download-button') {
        setVideoExpanded((o) => !o);
      }
    },
    [setVideoExpanded],
  );
  /* #endregion */
  /* #region 🎃 Events Handlers */
  if (step !== myStep || listType !== 'exclusion') {
    return null;
  }

  /* #endregion */
  return (
    <PaddedFormControl fullWidth focused={true} data-testid={testId}>
      <Grid
        container
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={10}
        boxSizing={'border-box'}
      >
        <Grid id="projected-performance-summary" xs={12}>
          <SummaryAccordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={8}
                flexWrap={'wrap'}
              >
                <Typography
                  variant="h6"
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  fontWeight={'700'}
                  textAlign={'center'}
                  color="inherit"
                >
                  Projected Performance Summary
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'stretch'}
                  gap={3}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      height: 'unset',
                    },
                  }}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    boxSizing={'border-box'}
                  >
                    <InternalIcon
                      sx={{ fontSize: '3.75rem' }}
                      iconName={selectedPlatform?.logoIcon}
                    />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    boxSizing={'border-box'}
                  >
                    <Divider orientation={'vertical'} />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    gap={1}
                    boxSizing={'border-box'}
                    p={1}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={1}
                    >
                      <InternalIcon
                        sx={{ fontSize: '3.75rem' }}
                        iconName={selectedPlatform?.labelIcon}
                      />
                      {/* <Typography variant="h6" fontWeight={'bold'} color="inherit">
                        {selectedPlatform?.label}
                      </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <SummaryItems container gap={3} id="project-performance-summary-splitter">
                <SummaryItems
                  xs={true}
                  rowGap={1}
                  container
                  columnGap={3}
                  id="project-performance-summary-items"
                >
                  <Grid container gap={3} width={'100%'}>
                    <Grid
                      xs={12}
                      container
                      gap={3}
                      sx={(theme) => ({
                        flexWrap: 'wrap',
                        [theme.breakpoints.up('md')]: {
                          flexWrap: 'nowrap',
                        },
                      })}
                    >
                      <AverageDailyViewsGridItem
                        {...itemGridSize}
                        value={
                          isLoading ? (
                            <FakeLinearProgressBar />
                          ) : isSuccess ? (
                            exclusionResults?.aggregates.total_average_daily_views
                          ) : (
                            <FakeLinearProgressBar
                              linearProgressProps={{
                                variant: 'determinate',
                                value: 100,
                                color: 'error',
                              }}
                            />
                          )
                        }
                      />
                      <RiskGridItem
                        {...itemGridSize}
                        label={`Garm Risk Tolerance Level`}
                        value={
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {riskLevelTitle}
                            <ChevronRight />
                          </Box>
                        }
                      />
                    </Grid>
                    <Grid xs={12} container gap={3} wrap="nowrap">
                      <ConceptsBox xs={12} md={12} isExclusion={true} />
                    </Grid>
                  </Grid>
                </SummaryItems>
              </SummaryItems>
            </AccordionDetails>
          </SummaryAccordion>
        </Grid>
        <Grid id="channel-exclusion-list" xs={12}>
          <SummaryAccordion
            expanded={videoExpanded}
            color="black"
            sx={{ backgroundColor: 'white' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              onClick={handleVideoAccordionClick}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{
                  width: '100%',
                }}
              >
                <ChannelResultsHeader>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    sx={(theme) => ({
                      color: theme.palette.secondary.main,
                    })}
                  >
                    Channel Exclusion List:{' '}
                    <Typography component={'span'} variant="h6" fontWeight={'thin'} color="inherit">
                      {getViewsAbbreviated(exclusionResults?.aggregates.total_number_of_channels)} Total
                    </Typography>
                  </Typography>
                  <SuitableMaxIcon />
                </ChannelResultsHeader>
                <Box
                  display={videoExpanded ? 'flex' : 'none'}
                  flexDirection={'row'}
                  justifyContent={'space-evenly'}
                  alignItems={'center'}
                  gap={2}
                >
                  <DownloadListButton />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={4} pb={4}>
                <Typography component={'span'} variant="body1" fontWeight={'thin'}>
                  Below are a list of unique channels that you can exclude in your campaign
                  placement
                </Typography>
              </Box>
              <VideoTable
                rows={exclusionResults?.channel_results}
                isExclusion={true}
                isLoading={isExclusionResultLoading || isExclusionResultFetching}
                totalRows={exclusionResults?.aggregates.total_number_of_channels}
              />
            </AccordionDetails>
          </SummaryAccordion>
        </Grid>
      </Grid>
    </PaddedFormControl>
  );
};

export default StepExclusionResults;
