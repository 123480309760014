/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { ChevronRight } from '@mui/icons-material';
import { Box, Divider, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useCallback } from 'react';
import { TEXT_GREY } from '../../../../../gatsby-theme-zefr/theme';
import { SalesDemoPlatform } from '../../../../types/launchDarkly';
import ClickablePaper from '../../../ClickablePaper/ClickablePaper';
import InternalIcon from '../../../InternalIcon';
import FormStepBox, { FormStepBoxProps } from '../FormStepBox';
import { DetailsList, FormTitle } from '../components';

const StyledDetailsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 1,
  '& .details-list': {
    margin: `${theme.spacing(4)}`,
    '&:is(ul)': {
      paddingInlineStart: '15px',
    },
    '& .MuiTypography-root': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: '400',
      color: `${TEXT_GREY}`,
    },
    '& li': {
      transition: theme.transitions.create(['textDecoration'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
      '&:not(:first-child)': {
        paddingTop: '5px',
      },
      '&:hover': {
        // textDecoration: 'underline',
      },
    },
  },
}));

const StyledLearnMoreBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(0),
  cursor: 'pointer',
  userSelect: 'none',
  '& .MuiTypography-root, & *': {
    userSelect: 'none',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  '&:hover': {
    '& *, & .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTypography-root, & *': {
      transform: 'scale(1.1)',
    },
  },
  '&:active': {
    '& *, & .MuiTypography-root': {
      color: theme.palette.primary.dark,
    },
  },
  '& *, & .MuiTypography-root': {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  //   gap: theme.spacing(4),
  margin: theme.spacing(4),
  position: 'relative',
  flexGrow: 1,
}));

const StyledPlatformOptionCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  justifyContent: 'center',
  alignContent: 'center',
  position: 'absolute',
  top: `-${theme.spacing(4)}`,
  transform: 'translateY(-50%)',
}));

const FormPlatformIcon = styled(InternalIcon)(({ theme }) => ({
  fontSize: '5.25rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '7.25rem',
  },
}));

export interface FormStepDemoPlatformCardsProps extends Omit<FormStepBoxProps, 'children'> {
  titleLabel: string;
  ctaLabel?: string;
  options: SalesDemoPlatform[];
  selectedOption?: string | null;
  setValue: (value: string) => void;
  ctaClick?: (data: SalesDemoPlatform) => void;
  floatingIcon?: boolean;
}

export const FormStepDemoPlatformCards: React.FC<FormStepDemoPlatformCardsProps> = ({
  step: myStep = 2,
  titleLabel,
  options,
  selectedOption,
  setValue,
  ctaClick,
  ctaLabel,
  floatingIcon,
  ...props
}) => {
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */
  const handleClick = useCallback(
    (data: SalesDemoPlatform) => {
      if (!data.disabled && data.value) {
        setValue(data.value);
      }
    },
    [setValue],
  );

  const handleCtaClick = useCallback(
    (data: SalesDemoPlatform) => {
      if (ctaClick) {
        ctaClick(data);
      }
    },
    [ctaClick],
  );

  /* #endregion */
  return (
    <FormStepBox
      step={myStep}
      isNextDisabled={!Boolean(selectedOption)}
      titleElement={<FormTitle marginBottom={6}>{titleLabel}</FormTitle>}
      {...props}
    >
      <Grid2
        container
        flexDirection={'row'}
        flexWrap={'wrap'}
        justifyContent={'center'}
        spacing={8}
        height={'100%'}
        sx={{
          '& > *': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        {options.map(({ ...data }) => {
          const numberOfDetails = data.details?.length || 0;
          return (
            <Grid2 xs={12} md={6} key={data.value}>
              <ClickablePaper
                key={data.value}
                onClick={() => {
                  handleClick(data);
                }}
                id={data.value}
                active={data.value === selectedOption}
                disabled={data.disabled}
                className="platform-option-paper"
              >
                <StyledBox>
                  {floatingIcon ? (
                    <StyledPlatformOptionCard>
                      <Box className="platform-option-card-icon-container">
                        <InternalIcon
                          iconName={data['logoIcon']}
                          className="platform-option-card-icon"
                          sx={{ fontSize: '4.25rem' }}
                        />
                      </Box>
                    </StyledPlatformOptionCard>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={4}
                      justifyContent="center"
                      alignContent="center"
                      className="platform-card-content"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          height: 'unset',
                        },
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignContent="center"
                      >
                        <FormPlatformIcon iconName={data['logoIcon']} />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        boxSizing="border-box"
                      >
                        <Divider orientation="vertical" />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignContent="center"
                      >
                        <FormPlatformIcon iconName={data['labelIcon']} />
                      </Box>
                    </Box>
                  )}
                  <StyledDetailsBox
                    id={`option-${data.value}-details`}
                    className="platform-option-card-details"
                  >
                    {data.label && (
                      <Typography
                        textAlign={'center'}
                        fontWeight={'700'}
                        className="platform-option-detail"
                        variant="h3"
                        mt={4}
                        sx={(theme) => ({
                          color: theme.palette.secondary.main,
                        })}
                      >
                        {data.label}
                      </Typography>
                    )}
                    {numberOfDetails > 1 ? (
                      <DetailsList className="details-list">
                        {data.details?.map((detail, index) => (
                          <Typography
                            key={index}
                            {...data.detailProps}
                            component={'li'}
                            className="platform-option-detail"
                          >
                            {detail}
                          </Typography>
                        ))}
                      </DetailsList>
                    ) : (
                      <Box className="details-list">
                        {data.details?.map((detail, index) => (
                          <Typography
                            key={index}
                            {...data.detailProps}
                            className="platform-option-detail"
                          >
                            {detail}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </StyledDetailsBox>
                  <StyledLearnMoreBox>
                    {data.learnMore ? (
                      <Box
                        className="learn-more-box"
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        onClick={
                          typeof ctaClick !== 'undefined'
                            ? () => {
                                handleCtaClick(data);
                              }
                            : undefined
                        }
                      >
                        <Typography
                          textAlign="center"
                          variant="subtitle1"
                          fontWeight={'800'}
                          className="learn-more-text"
                        >
                          {ctaLabel || data.learnMore.title}
                        </Typography>
                        <ChevronRight fontSize="small" className="chevron-right-icon" />
                      </Box>
                    ) : undefined}
                  </StyledLearnMoreBox>
                </StyledBox>
              </ClickablePaper>
            </Grid2>
          );
        })}
      </Grid2>
    </FormStepBox>
  );
};

export default FormStepDemoPlatformCards;
