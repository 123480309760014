import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import React from 'react';

const StepNumberAvatarStyled = styled(Avatar)<{ filled?: boolean }>(({ theme, filled }) => {
  return {
    boxShadow: `3px 8px 14px -3px rgba(0,0,0,0.75)`,
  };
});

export interface StepNumberAvatarProps extends AvatarProps {
  filled?: boolean;
  step?: number;
}

const StepNumberAvatar: React.FC<StepNumberAvatarProps> = ({ ...props }) => {
  return <StepNumberAvatarStyled {...props} />;
};

export default StepNumberAvatar;
