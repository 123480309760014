import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import CsvDownload from 'react-csv-downloader';
import { useDemoForm } from '../../../context/DemoFormContext';

const columns = [
  {
    id: 'channelId',
    displayName: 'Channel ID',
  },
];

export interface DownloadListButtonProps extends Omit<ButtonProps, 'children'> {}

const DownloadListButton: React.FC<DownloadListButtonProps> = ({ ...props }) => {
  const { downloadSampleList } = useDemoForm();
  return (
    <CsvDownload columns={columns} datas={downloadSampleList} filename="sample-list" suffix={true}>
      <Button id="download-button" color={'primary'} variant="contained" {...props}>
        Download List
      </Button>
    </CsvDownload>
  );
};

export default DownloadListButton;
