import { useAuth0 } from '@auth0/auth0-react';
import { Login } from '@mui/icons-material';
import { styled } from '@zefr/style-guide';
import ZefrTopNav from '@zefr/style-guide/dist/ui/TopNav/TopNav';
import {
  CenterTopNavComponent,
  LeftTopNavComponent,
  RightTopNavComponent,
} from '@zefr/style-guide/dist/ui/TopNav/zefr';
import Tab from '@zefr/style-guide/dist/ui/TopNav/zefr/CenterTopNavComponent/components/StyledTab';
import { ZefrIcon } from '@zefr/style-guide/dist/ui/icons';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTabs } from '../../../../src/helpers/pluginHandling/data';
import { userAtom } from '../../../gatsby-plugin-zefr-auth0/atoms/userAtom';
import { LOGIN } from '../../../gatsby-plugin-zefr-auth0/constants/routes';
import { appAtom } from '../../atoms/appAtom';
import { sidebarHiddenAtom } from '../../atoms/sidebarHiddenAtom';
import { sidebarOpenAtom } from '../../atoms/sidebarOpenAtom';
import { TopNavTab } from '../../types/shared';
import Link from '../Link';
import StyledTopNavButton from './StyledTopNavButton';
import TopNavMenu from './TopNavMenu';

// Hack to get the height right for the top nav User Menu
const SZefrTopNav = styled(ZefrTopNav)(() => {
  return {
    '&.glow': {
      boxShadow: '0px 15px 25px rgb(0 122 220 / 49%)',
    },

    '&>*:nth-of-type(2)>*:nth-of-type(3)>div>div': {
      height: '100%',
    },
  };
});

export interface TopNavProps {}

const TopNav: React.FC<TopNavProps> = (props) => {
  const { ...rest } = props;

  const { isAuthenticated, isLoading } = useAuth0();
  const { t } = useTranslation();
  const [open, setOpen] = useAtom(sidebarOpenAtom);
  const sidebarHidden = useAtomValue(sidebarHiddenAtom);
  const app = useAtomValue(appAtom);
  const userState = useAtomValue(userAtom);
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);

  const dispatchToggleOpen = useCallback(() => setOpen((old) => !old), [setOpen]);

  const tabs: TopNavTab[] | undefined = useMemo(
    () => (userState ? getTabs(userState) : []),
    [userState],
  );

  useEffect(() => {
    const newActiveTab = tabs?.findIndex((t) => t.value === app);
    setActiveTab(
      newActiveTab === -1 || typeof newActiveTab === 'undefined' ? undefined : newActiveTab,
    );
  }, [tabs, setActiveTab, app]);

  const loginButton = useMemo(() => {
    return (
      <StyledTopNavButton
        disabled={isLoading}
        variant="outlined"
        component={Link}
        {...{ to: LOGIN }}
        color="white"
      >
        <Login />
        {isLoading ? t('loading') : t('log_in')}
      </StyledTopNavButton>
    );
  }, [isLoading]);

  return (
    <SZefrTopNav
      className={`TopNav ${app === 'DEMO' ? 'glow' : ''}`}
      open={sidebarHidden === true ? false : open}
      includeExpandCollapse={sidebarHidden === true ? false : undefined}
      onExpandCollapseClick={dispatchToggleOpen}
      data-testid={'zefr-top-nav'}
      {...rest}
      leftComponent={
        <LeftTopNavComponent
          companyLogoComponent={
            <Link to="/" style={{ display: 'flex' }} target="_blank">
              <ZefrIcon />
            </Link>
          }
        />
      }
      rightComponent={
        <RightTopNavComponent
          notificationComponent={isAuthenticated ? <TopNavMenu /> : loginButton}
        />
      }
    >
      <CenterTopNavComponent value={activeTab}>
        {tabs?.map((tab, i) => (
          <Tab
            component={Link}
            {...{ target: tab.target, to: tab.link }}
            key={tab.link}
            icon={tab.icon}
            label={t(tab.label, { defaultValue: tab.label })}
            value={i}
          />
        ))}
      </CenterTopNavComponent>
    </SZefrTopNav>
  );
};

export default TopNav;
