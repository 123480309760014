import { Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';

export interface InformationalTitleProps extends TypographyProps {}

const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: '21px',
  };
});

const InformationalTitle: React.FC<InformationalTitleProps> = ({ ...props }) => {
  return <Title {...props} />;
};

export default InformationalTitle;
