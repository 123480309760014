import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import { SalesDemoTargetAudienceOptionsData } from '../types/launchDarkly';
import { setFormTargetAudienceAtom } from './encodedFormAtoms';

type State = boolean | undefined;

const initialDialogState: State = undefined;

export const demoTargetAudienceDialogOpenAtom = atom(initialDialogState as State);

demoTargetAudienceDialogOpenAtom.debugLabel = 'Demo Target Audience Dialog Open';

const initialState: SalesDemoTargetAudienceOptionsData = {
  genders: {
    label: 'Include Gender(s)',
    type: 'multiselect',
    options: {
      male: 'Male',
      female: 'Female',
      unknown: 'Unknown',
    },
    defaultValue: ['male', 'female', 'unknown'],
  },
  ageRange: {
    label: 'Age Range',
    type: 'multiselect',
    options: {
      '18 - 24': '18 - 24',
      '25 - 34': '25 - 34',
      '35 - 44': '35 - 44',
      '45 - 54': '45 - 54',
      '55 - 64': '55 - 64',
      '65+': '65+',
      unknown: 'Unknown',
    },
    defaultValue: ['18 - 24', '25 - 34', '35 - 44', '45 - 54', '55 - 64', '65+'],
  },
  regions: {
    label: 'Region(s)',
    type: 'multiselect',
    options: {
      us: 'US',
      canada: 'Canada',
    },
  },
  interests: {
    type: 'text',
    label: 'Audience Interests',
    placeholder: 'Must be comma-separated',
  },
};

const initialFormState: Record<string, string | string[] | number> = {};

const targetAudienceOptionsAtom = atom(initialState);
targetAudienceOptionsAtom.debugLabel = 'Target Audience Options State';
const targetAudienceOptionsFormAtom = atomWithReset(initialFormState);
targetAudienceOptionsFormAtom.debugLabel = 'Target Audience Form State';
const readTargetAudienceOptionsFormAtom = atom((get) => {
  return get(targetAudienceOptionsFormAtom);
});

const writeTargetAudienceOptionFormAtom = atom(
  null,
  (get, set, data: { key: string; value?: string | string[] | number }) => {
    const { key, value } = data;
    const existingForm = get(targetAudienceOptionsFormAtom);
    if (typeof value === 'undefined') {
      if (typeof existingForm[key] !== 'undefined') {
        delete existingForm[key];
      }
    } else {
      existingForm[key] = value;
    }
    set(setFormTargetAudienceAtom, existingForm);
  },
);

const writeTargetAudienceFormAtom = atom(
  null,
  (get, set, data: Record<string, string | number | string[]>) => {
    set(setFormTargetAudienceAtom, data);
  },
);

const clearTargetAudienceOptionsFormAtom = atom(null, (get, set) => {
  set(targetAudienceOptionsFormAtom, RESET);
});

clearTargetAudienceOptionsFormAtom.debugPrivate = true;
writeTargetAudienceOptionFormAtom.debugPrivate = true;
readTargetAudienceOptionsFormAtom.debugPrivate = true;

const filteredTargetAudienceOptionsAtom = atom((get) => {
  const data = get(targetAudienceOptionsAtom);
  const newData: Record<
    'text' | 'select' | 'multiselect' | string,
    SalesDemoTargetAudienceOptionsData
  > = {
    multiselect: {},
    select: {},
    text: {},
  };
  Object.keys(data).forEach((key) => {
    const myType = data[key].type;
    if (typeof myType !== 'undefined') {
      if (typeof newData[myType] === 'undefined') {
        newData[myType] = {};
      }
      newData[myType][key] = data[key];
    }
  });
  return newData;
});

filteredTargetAudienceOptionsAtom.debugLabel = 'Filtered Target Audience Options';

const multiSelectTargetAudienceOptionsAtom = atom((get) => {
  const data = get(filteredTargetAudienceOptionsAtom);
  return data['multiselect'] || {};
});
multiSelectTargetAudienceOptionsAtom.debugPrivate = true;

export {
  clearTargetAudienceOptionsFormAtom,
  filteredTargetAudienceOptionsAtom,
  multiSelectTargetAudienceOptionsAtom,
  readTargetAudienceOptionsFormAtom,
  targetAudienceOptionsAtom,
  writeTargetAudienceFormAtom,
  writeTargetAudienceOptionFormAtom,
};

export default targetAudienceOptionsFormAtom;
