import { atom } from 'jotai';
import { SITE_APP_NONE, SiteApps } from '../types/shared';

type State = SiteApps;

const initialState: State = SITE_APP_NONE;

const appAtom = atom(initialState as State);

appAtom.debugLabel = 'Current App';

export { appAtom };
