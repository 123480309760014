import { DialogProps } from '@mui/material';
import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';

type OpenState = boolean | undefined;
const initialOpenState: OpenState = false;
const demoDialogOpenAtom = atom(initialOpenState as OpenState);
demoDialogOpenAtom.debugLabel = 'Demo Dialog Open';

type ContentNodeState = React.ReactNode | undefined;
const initialContentNodeState = undefined;
const demoDialogContentNodeAtom = atomWithReset(initialContentNodeState as ContentNodeState);
demoDialogContentNodeAtom.debugPrivate = true;

type DialogPropsState = Partial<Omit<DialogProps, 'open'>>;
const initialDialogPropsState: DialogPropsState = {
  fullWidth: true,
  maxWidth: 'md',
  disableRestoreFocus: true,
  sx: {
    m: 1,
  },
};
const demoDialogPropsAtom = atomWithReset(initialDialogPropsState as DialogPropsState);
demoDialogPropsAtom.debugPrivate = true;

const publicDemoDialogOpenAtom = atom(
  (get) => get(demoDialogOpenAtom),
  (_get, set, state: OpenState | ((prev: OpenState) => OpenState)) => {
    let timeout = 0;
    if (state) {
      timeout = 100;
    }
    setTimeout(() => {
      set(demoDialogOpenAtom, (oldState) => {
        const value = typeof state === 'function' ? state(oldState) : state;
        if (!value) {
          setTimeout(() => {
            set(demoDialogContentNodeAtom, RESET);
            set(demoDialogPropsAtom, RESET);
          }, 100);
        }
        return value;
      });
    }, timeout);
  },
);

export {
  demoDialogContentNodeAtom,
  publicDemoDialogOpenAtom as demoDialogOpenAtom,
  demoDialogPropsAtom,
};
