/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm, FINAL_STEP } from '../../../../context/DemoFormContext';
import FormStepBox from '../FormStepBox';
import RiskSelect from '../RiskSelect';
import { FormTitle } from '../components';
import { FormStepProps } from '../types';

// const StyledSelect = styled(Select)({ theme} => ({

// }))

export const FormStepExclusionsRoute: React.FC<FormStepProps> = ({
  step: myStep = 2,
  'data-testid': testId,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    setStep,
    rfpExclusionOptions,
    risk,
    excludedConcepts,
    setFormExcludedConcepts,
    listType,
    apiConcepts,
  } = useDemoForm();

  const handleChangeExclusions = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentIndex = (excludedConcepts || []).indexOf(event.target.id);
      if (currentIndex === -1) {
        setFormExcludedConcepts(
          (excludedConcepts || []).length > 0
            ? [...excludedConcepts!, event.target.id]
            : [event.target.id],
        );
      } else {
        const result = [...(excludedConcepts || [])];
        result.splice(currentIndex, 1);
        setFormExcludedConcepts(result);
      }
    },
    [setFormExcludedConcepts, excludedConcepts],
  );

  const exclusionApiConcepts = useMemo(() => {
    return Object.values(apiConcepts).filter((c) => {
      return c.is_bsp_concept;
    });
  }, [apiConcepts]);
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */
  if (listType !== 'exclusion') {
    return null;
  }

  /* #endregion */
  return (
    <FormStepBox
      titleElement={
        <FormTitle marginBottom={6}>{t(`growth.${APP_Demo}.step.three.title`)}</FormTitle>
      }
      step={myStep}
      isNextDisabled={!Boolean(risk)}
      nextLabel="Finish"
      onNext={() => {
        setStep(FINAL_STEP);
      }}
      data-testid={testId}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        width={'100%'}
        className={'max-width-percentage'}
      >
        <RiskSelect
          label={rfpExclusionOptions.riskToleranceLevels?.placeholder}
          options={rfpExclusionOptions.riskToleranceLevels?.options}
        />
        {Boolean(risk) && (
          <FormControl component="fieldset" className="zefr-form" sx={{ m: 3 }} variant="standard">
            <FormLabel component="legend">
              {rfpExclusionOptions.additionalExclusions?.title ||
                t('growth.${APP_Demo}.step.three.additionalExclusions.title')}
            </FormLabel>
            <FormGroup sx={{ flexWrap: 'nowrap', marginTop: '1rem' }}>
              {exclusionApiConcepts.map(({ slug, title, id, ...data }) => {
                const currentIndex = (excludedConcepts || []).indexOf(id || '');
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={currentIndex !== -1 ? true : false}
                        onChange={handleChangeExclusions}
                        name={slug}
                        id={id}
                      />
                    }
                    key={slug}
                    id={`${slug}-label`}
                    label={title}
                    {...data}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </FormStepBox>
  );
};

export default FormStepExclusionsRoute;
