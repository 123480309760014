import { Box, BoxProps, Grid, TextareaAutosize, styled } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { markdownAtom } from '../atoms/markdownAtom';

export interface MarkdownHelperProps extends Omit<BoxProps, 'children' | 'onChange'> {
  text?: string;
  onChange?: (text: string) => void;
}

const MarkdownBox = styled(Grid)(() => {
  return {
    '& > textarea': {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      boxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
    },
  };
});

const MarkdownHelper: React.FC<MarkdownHelperProps> = ({
  text = '',
  display = 'flex',
  flexDirection = 'row',
  justifyContent = 'space-evenly',
  alignItems = 'flex-start',
  onChange = () => {},
  ...props
}) => {
  const [internalState, setInternalState] = useAtom(markdownAtom);
  return (
    <Box
      component={Grid}
      container
      {...{ display, flexDirection, justifyContent, alignItems }}
      {...props}
    >
      <MarkdownBox className="markdown" item xs={6}>
        <ReactMarkdown children={internalState || ''} />
      </MarkdownBox>
      <MarkdownBox item xs={6}>
        <TextareaAutosize
          data-testid="markdown-textarea"
          minRows={16}
          onChange={(e) => {
            setInternalState(e.currentTarget.value);
          }}
          value={internalState}
        />
      </MarkdownBox>
    </Box>
  );
};

export default MarkdownHelper;
