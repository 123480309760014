import { atomWithReset } from 'jotai/utils';
import { SITE_APP_NONE } from '../../gatsby-theme-zefr/types/shared';

export const APP_ImageVerifai = 'verifai';
export type AppImageVerifai = typeof APP_ImageVerifai;
export const APP_TrendSummarization = 'trend-summarization';
export type AppTrendSummarization = typeof APP_TrendSummarization;
export const APP_CVPredictions = 'cv-predictions';
export type AppCVPredictions = typeof APP_CVPredictions;
export const APP_NLPPredictions = 'nlp-predictions';
export type AppNLPPredictions = typeof APP_NLPPredictions;
export const APP_RegexPredictions = 'regex-predictions';
export type AppRegexPredictions = typeof APP_RegexPredictions;
export const APP_GPTReview = 'gpt-review';
export type AppGPTReview = typeof APP_GPTReview;
export const APP_LabelingRequest = 'labeling';
export type AppLabelingRequest = typeof APP_LabelingRequest;
export const APP_SuitabilityLive = 'suitability-live';
export type AppSuitabilityLive = typeof APP_SuitabilityLive;
export const APP_ChannelSummary = 'channel-summary';
export type AppChannelSummary = typeof APP_ChannelSummary;
export const APP_Demo = 'demo';
export type AppDemo = typeof APP_Demo;

export const ToolAppKeys = [APP_ImageVerifai];
export type ToolApps =
  | AppImageVerifai
  | AppCVPredictions
  | AppNLPPredictions
  | AppRegexPredictions
  | AppGPTReview
  | AppLabelingRequest
  | AppSuitabilityLive
  | AppTrendSummarization
  | AppChannelSummary;

export interface ToolAppData {
  title?: string;
  href?: string;
  disabled?: boolean;
  active?: boolean;
  priority?: number;
  key?: string;
}

export const ToolTitles: Record<ToolApps, string> = {
  [APP_ImageVerifai]: 'Image VerifAI',
  [APP_TrendSummarization]: 'Trend Summarization',
  [APP_CVPredictions]: 'CV Predictions',
  [APP_NLPPredictions]: 'NLP Predictions',
  [APP_RegexPredictions]: 'Regex Predictions',
  [APP_GPTReview]: 'GPT Review',
  [APP_LabelingRequest]: 'Labeling',
  [APP_SuitabilityLive]: 'Suitability Live',
  [APP_ChannelSummary]: 'Channel Summary Tool',
};

interface makeToolAppDataForKeyParams {
  key: ToolApps;
  title?: string;
  priority?: number;
}

const makeToolAppDataForKey: (
  data: makeToolAppDataForKeyParams,
) => Partial<Record<ToolApps, ToolAppData>> = ({ key, title, priority = 0, ...rest }) => {
  return {
    [key]: {
      href: `/${key}`,
      priority: 0,
      title: title || key,
      ...rest,
    },
  };
};

const makeToolDetails = (): Partial<Record<ToolApps, ToolAppData>> => {
  const newData = (ToolAppKeys as ToolApps[]).map((key) => {
    return makeToolAppDataForKey({ key });
  });
  let originalData = {};
  newData.forEach((item) => {
    originalData = { ...originalData, ...item };
  });
  return originalData;
};

const ToolDetails: Partial<Record<ToolApps, ToolAppData>> = makeToolDetails();

type State = ToolApps | typeof SITE_APP_NONE;

const initialState: State = SITE_APP_NONE;

const toolsAtom = atomWithReset(initialState as State);
toolsAtom.debugLabel = 'Current Tool';

export { ToolDetails, toolsAtom };
