import { Box, TextareaAutosize, Typography, styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { useAtom } from 'jotai';
import React, { useCallback } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import { creativeAlignmentLocalAtom } from './atom';

export interface CreativeAlignmentProps extends Grid2Props {}

// Styled components
const CreativeAlignmentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  '& textarea:nth-of-type(1)': {
    padding: theme.spacing(1),
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  width: '100%',
}));

const StyledTextareaAutosize = styled(TextareaAutosize)({
  borderRadius: '5px',
  padding: '0.5rem',
  maxWidth: '100%',
  minWidth: '100%',
  boxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
});

const CreativeAlignment: React.FC<CreativeAlignmentProps> = (props) => {
  const { creativeAlignment: defaultValue } = useDemoForm();
  const [localValue, setLocalValue] = useAtom(creativeAlignmentLocalAtom);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLocalValue(event.target.value);
    },
    [setLocalValue],
  );

  return (
    <Grid container width={'100%'} {...props}>
      <Grid xs={12}>
        <CreativeAlignmentContainer>
          <HeaderBox>
            <Typography
              variant="subtitle1"
              color="black"
              fontWeight="bold"
              className="creative-alignment-title"
            >
              Creative Alignment
            </Typography>
          </HeaderBox>
          <StyledTextareaAutosize
            minRows={6}
            maxRows={12}
            value={typeof localValue === 'undefined' ? defaultValue : localValue || ''}
            defaultValue={defaultValue || ''}
            onChange={handleChange}
            className="textarea"
            placeholder=""
            aria-label="Creative Alignment" // Accessibility
          />
        </CreativeAlignmentContainer>
      </Grid>
    </Grid>
  );
};

export default CreativeAlignment;
