import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Box, Slide } from '@mui/material';
import { useAtomValue } from 'jotai';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { chipActiveFiltersAtom, chipFilterAtom } from '../../atoms/chipAtom';
import ChipDots from './ChipDots';
import { ChipSwapProps } from './ChipSwapper';
import { StyledSwapChip } from './components';

export interface SwapChipProps extends ChipSwapProps {
  showActive?: boolean;
}

const SwapChip: React.FC<SwapChipProps> = ({
  key,
  skipFocusWhenDisabled,
  filters: myFilters = [],
  active,
  className,
  showActive,
  id,
  ...props
}) => {
  const filters = useAtomValue(chipFilterAtom);
  const filterKeys = useAtomValue(chipActiveFiltersAtom);
  const isShown = useMemo(() => (showActive ? active : !active), [active, showActive]);
  const sortedMyFilters = useMemo(() => {
    return sortBy(myFilters);
  }, [myFilters]);
  const isInFilter = useMemo(() => {
    if (!(typeof filters === undefined || sortedMyFilters.length === 0)) {
      for (let i = 0; i < sortedMyFilters.length; i++) {
        const v = sortedMyFilters[i];
        if (filterKeys?.indexOf(v) !== -1) {
          return true;
        }
      }
      return false;
    }
    return true;
  }, [sortedMyFilters, filters, filterKeys]);

  const statusDots = useMemo(() => {
    return sortedMyFilters.map((k) => {
      const backgroundColor =
        filters && filters[k as keyof typeof filters]
          ? filters[k as keyof typeof filters].color
          : '';
      if (!backgroundColor) {
        return undefined;
      }
      return <Box key={k} id={`${id}-${k}`} sx={{ backgroundColor }}></Box>;
    });
  }, [sortedMyFilters, filters, id]);
  return (
    <Slide
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={isInFilter && isShown ? 500 : undefined}
      key={id}
      direction="up"
      in={isInFilter && isShown ? true : false}
      id={`outer-${id}`}
      data-active={active ? true : false}
      className={`swap-chip-slide swap-chip-slide-${active ? '' : 'in'}active`}
    >
      <StyledSwapChip
        id={`inner-${id || key}`}
        data-key={key}
        icon={active ? <CheckBox fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small" />}
        onDelete={() => {}}
        size="small"
        deleteIcon={<ChipDots>{statusDots}</ChipDots>}
        className={`swap-chip swap-chip-${active ? '' : 'in'}active ${
          isInFilter && isShown ? `` : `inactive`
        } ${className || ''} ${Object.keys(sortedMyFilters)
          .map((v) => `filter-${v}`)
          .join(' ')}`}
        {...props}
        {...{ skipFocusWhenDisabled, key, active }}
      />
    </Slide>
  );
};

export default SwapChip;
