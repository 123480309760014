import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { RiskLevel } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import React, { useCallback } from 'react';
import useGarmScoreDialog from '../hooks/dialog/useGarmScoreDialog';
export interface VideoTableGarmScoreCellProps {
  values: Record<string, string>;
}

const VideoTableGarmScoreCell: React.FC<VideoTableGarmScoreCellProps> = ({ values = {} }) => {
  const handleClick = useGarmScoreDialog(values as Record<string, RiskLevel>);
  const handleClickClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      handleClick();
    },
    [handleClick],
  );
  return (
    <IconButton onClick={handleClickClick}>
      <InfoOutlined />
    </IconButton>
  );
};

export default VideoTableGarmScoreCell;
