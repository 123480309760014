import { User } from '@auth0/auth0-react';
import { atom } from 'jotai';
import { adminAtom } from '../../gatsby-theme-zefr/atoms/adminAtom';

/**
 * ZefrJWT - The representation of the decoded JWT
 */
interface ZefrJWT {
  'https://zefr.com/': {
    authorization: {
      groups: string[];
      roles: string[];
    };
    email: string;
  };
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

/**
 * UserState - the Jotai atom that components can access to learn about the current user
 */
export interface UserState {
  authenticated?: boolean;
  user?: User;
  decodedAccessToken?: ZefrJWT;
  accessToken?: string;
}

const userAtom = atom<UserState | undefined>(undefined);
userAtom.debugLabel = 'Zefr User';

const isAllowedAdminAtom = atom((get) => {
  const user = get(userAtom);
  if (user?.user?.email_verified) {
    if ((user?.user?.email || '').endsWith('@zefr.com')) {
      return true;
    }
  }
  return false;
});

const isAdminAtom = atom((get) => {
  const isAllowedAdmin = get(isAllowedAdminAtom);
  if (isAllowedAdmin) {
    return get(adminAtom);
  }
  return false;
});

const isEngineerAtom = atom((get) => {
  const isAdmin = get(isAdminAtom);
  if (isAdmin) {
    const user = get(userAtom);
    if (
      (user?.decodedAccessToken?.['https://zefr.com/'].authorization.groups || []).indexOf(
        'engineers@zefr.com',
      ) !== -1
    ) {
      return true;
    }
  }
  return false;
});

isEngineerAtom.debugLabel = 'Group: Engineer';
isAdminAtom.debugLabel = 'Admin Active Status';
isAllowedAdminAtom.debugLabel = 'Admin Allowed Status';

export { isAdminAtom, isAllowedAdminAtom, isEngineerAtom, userAtom };
