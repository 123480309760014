// Environment Variable Data
const IS_DEV = process.env.NODE_ENV !== 'production';
const ENABLE_DEV_SSR = IS_DEV && process.env.GATSBY_DEV_SSR === 'true';

const BASE_APP_ROUTE = process.env.GATSBY_APPS_PATH || 'apps';

const isProdUrl = (window?: Window) => {
  if (typeof window !== 'undefined') {
    if (window.location.host) {
      if (
        window.location.host.includes('stage.zefr.com') ||
        window.location.host.includes('localhost')
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
  return false;
};

type Environment = 'production' | 'development';

interface EnvVars {
  GATSBY_AUTH0_CLIENT_ID: string;
  GATSBY_AUTH0_DOMAIN: string;
  GATSBY_AUTH0_AUDIENCE: string;
  GATSBY_AUTH0_SCOPE: string;
  GATSBY_APIGEE_API_KEY: string;
  GATSBY_APIGEE_BRAND_API_KEY: string;
  GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID: string;
  GATSBY_GROWTH_SERVER_PATH: string;
  GATSBY_BRAND_SERVER_PATH: string;
  GATSBY_DATADOG_CLIENT_ID: string;
}

const PUBLIC_ENV_VARS: Record<Environment, EnvVars> = {
  production: {
    GATSBY_APIGEE_API_KEY: 'vXPamaWePgEKsyFWJ6s8uNNBjaG1LSej',
    GATSBY_APIGEE_BRAND_API_KEY: 'sN0lk9Lb3V8quLsELbvaPmGMmEk0YemN',
    GATSBY_AUTH0_AUDIENCE: 'zefr-brand-capture',
    GATSBY_AUTH0_CLIENT_ID: 'wSePR7pteXVw4oC85f2R9OZogXOnX2wi',
    GATSBY_AUTH0_DOMAIN: 'login.zefr.com',
    GATSBY_AUTH0_SCOPE: 'openid email profile zefr-bsx',
    GATSBY_GROWTH_SERVER_PATH: 'https://api.zefr.com/growth-hacker-relay-auth0/api',
    GATSBY_BRAND_SERVER_PATH: 'https://api.zefr.com/brand-capture-auth0',
    GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID: '656f67b9f7bba40f8d1b1d98',
    GATSBY_DATADOG_CLIENT_ID: 'pubb6c8f6e21be9d2689c9da15c0fea8bb1',
  },
  development: {
    GATSBY_APIGEE_API_KEY: 'vXPamaWePgEKsyFWJ6s8uNNBjaG1LSej',
    GATSBY_APIGEE_BRAND_API_KEY: 'sN0lk9Lb3V8quLsELbvaPmGMmEk0YemN',
    GATSBY_AUTH0_AUDIENCE: 'zefr-brand-capture',
    GATSBY_AUTH0_CLIENT_ID: 'y9oNs2BnN7dwwVtdnWcwhe9KuiEDgvBV',
    GATSBY_AUTH0_DOMAIN: 'login.stage.zefr.com',
    GATSBY_AUTH0_SCOPE: 'openid email profile zefr-bsx',
    GATSBY_BRAND_SERVER_PATH: 'https://api.stage.zefr.com/brand-capture-auth0',
    GATSBY_GROWTH_SERVER_PATH: 'https://api.stage.zefr.com/growth-hacker-relay-auth0/api',
    GATSBY_LAUNCH_DARKLY_CLIENT_SIDE_ID: '656f67b9f7bba40f8d1b1d97',
    GATSBY_DATADOG_CLIENT_ID: 'pubb6c8f6e21be9d2689c9da15c0fea8bb1',
  },
};

const getEnvVars: (env?: Environment) => EnvVars = (env) => {
  return env
    ? PUBLIC_ENV_VARS[env]
    : PUBLIC_ENV_VARS[
        isProdUrl(typeof window === 'undefined' ? undefined : window) ? 'production' : 'development'
      ];
};

const getEnvVar = <T>(key: keyof EnvVars, env?: Environment, defaultValue?: T) => {
  const requestedEnvData = getEnvVars(env);
  if (typeof requestedEnvData[key] !== 'undefined') {
    return requestedEnvData[key];
  } else {
    return defaultValue;
  }
};

export { BASE_APP_ROUTE, ENABLE_DEV_SSR, IS_DEV, getEnvVar, getEnvVars, isProdUrl };
