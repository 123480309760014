import { AxiosInstance, AxiosRequestConfig } from 'axios';
import API from './axiosInstances/zefrBrandAxiosInstance';

/**
 * Generic function call to hit the api
 *
 * @param path {string} endpoint to backend service
 * @param params {Object} necessary properties needed for the endpoint
 */
export async function postApiFn(
  path = '',
  json: Record<string, any> | undefined = undefined,
  params: AxiosRequestConfig<any> = {},
  restMethod: AxiosInstance['post'] = API.client.post,
) {
  const { data } = await restMethod(path, json, params);
  return data;
}

/**
 * Generic function call to hit the api
 *
 * @param path {string} endpoint to backend service
 * @param params {Object} necessary properties needed for the endpoint
 */
export async function getApiFn(
  path = '',
  params: AxiosRequestConfig<any> = {},
  restMethod: AxiosInstance['get'] = API.client.get,
) {
  const { data } = await restMethod(path, params);
  return data;
}
