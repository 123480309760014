import { Add } from '@mui/icons-material';
import { Box, Button, Grid2Props, Typography, darken, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import useChipDialog from '../../hooks/dialog/useChipDialog';
import ProxyConceptChip from '../ChipSwapper/ProxyConceptChip';
import { SummaryItemGrid } from './components';

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  flexWrap: 'wrap',
}));

const ChipsDisplayBoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

const ChipsDisplayBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  boxSizing: 'border-box',
  overflow: 'auto',
}));

export interface ConceptsBoxProps extends Grid2Props {
  isExclusion: boolean;
}

const ConceptsBox: React.FC<ConceptsBoxProps> = ({ isExclusion, ...props }) => {
  const { excludedConcepts, includedConcepts } = useDemoForm();
  const openDialog = useChipDialog({ isExclusion });

  const myConcepts = useMemo(
    () => (isExclusion ? excludedConcepts : includedConcepts) || [],
    [isExclusion, excludedConcepts, includedConcepts],
  );

  const handleClick = useCallback(() => {
    openDialog();
  }, [openDialog]);

  return (
    <SummaryItemGrid
      flexDirection={'column'}
      id="project-performance-summary-item-excluded-concepts"
      className="project-peformance-summary-item project-performance-summary-item-excluded-concepts exclusions-box"
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      px={4}
      py={2}
      maxHeight={'200px'}
      minHeight={'100px'}
      xs={12}
      md={6}
      {...props}
    >
      <HeaderBox className="concepts-header-box" gap={1}>
        <Typography variant="h4" color="inherit" fontWeight="bold" className="concepts-title">
          {isExclusion ? 'Excluded Concepts' : 'Included Concepts'}
        </Typography>
        <Button
          variant="contained"
          color="inherit"
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: `${darken(theme.palette.common.white, 0.1)}`,
            },
            '&:active': {
              backgroundColor: `${darken(theme.palette.common.white, 0.2)}`,
            },
          })}
          onClick={handleClick}
          className="manage-concepts-button"
        >
          <Add fontSize="small" className="add-icon" /> Add More
        </Button>
      </HeaderBox>
      <ChipsDisplayBoxWrapper>
        <ChipsDisplayBox className="chips-display-box">
          {myConcepts.map((conceptData) => (
            <ProxyConceptChip
              className={`proxy-concept-chip${isExclusion ? ' exclusion' : ' inclusion'}`}
              color={isExclusion ? 'error' : 'secondary'}
              direction={isExclusion ? 'left' : 'right'}
              ignoreFilters={true}
              hideFilters={true}
              key={conceptData}
              id={conceptData}
              aria-label={`Concept: ${conceptData}`} // Accessibility
            />
          ))}
        </ChipsDisplayBox>
      </ChipsDisplayBoxWrapper>
    </SummaryItemGrid>
  );
};

export default ConceptsBox;
