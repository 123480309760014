import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import get from 'lodash/get';

class Instance {
  readonly client: AxiosInstance;
  onError?: (response: any) => void;
  requestInterceptors?: any;
  responseInterceptors?: any;
  constructor(config?: AxiosRequestConfig) {
    this.client = axios.create(config);
    if (typeof window !== 'undefined') {
      this.setupInterceptors();
    } else {
      // console.log('skipping interceptors');
    }
  }

  private isTestEnv = () => {
    return process.env.NODE_ENV === 'test';
  };

  setupInterceptors = () => {
    if (this.requestInterceptors !== undefined) {
      if (!this.isTestEnv) {
        // console.log('ejecting request interceptor');
      }
      this.client.interceptors.request.eject(this.requestInterceptors);
      this.requestInterceptors = undefined;
    }
    if (this.responseInterceptors !== undefined) {
      if (!this.isTestEnv) {
        // console.log('ejecting response interceptor');
      }
      this.client.interceptors.response.eject(this.responseInterceptors);
      this.responseInterceptors = undefined;
    }
    if (this.requestInterceptors === undefined && this.responseInterceptors === undefined) {
      if (!this.isTestEnv) {
        // console.log('adding interceptors');
      }
      this.requestInterceptors = this.client.interceptors.request.use(
        this.reqConfigHandler,
        this.reqErrorHandler,
      );
      this.responseInterceptors = this.client.interceptors.response.use(
        this.resSuccessHandler,
        this.resErrorHandler,
      );
    }
  };

  reqConfigHandler = async (
    config: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> => {
    return Promise.resolve(config);
  };

  reqErrorHandler = (err: any) => {
    if (!this.isTestEnv) {
      // console.log('req', err);
    }
    const response = get(err, 'response', {});
    // TODO :: link error msg to sentry
    return Promise.reject(response);
  };

  resSuccessHandler = (res: AxiosResponse<any>) => {
    return res;
  };

  resErrorHandler = (serverResponse: any) => {
    // The errors from the request have a config object
    if (serverResponse?.config) {
      // The errors from a request with a config have a response
      if (this.onError) {
        this.onError(serverResponse?.response);
      }
    }
    return Promise.reject(serverResponse);
  };
}

export default Instance;
