import { atom } from 'jotai';

type State = boolean | undefined;

const initialState: State = undefined;

const topNavMenuOpenAtom = atom(initialState as State);

topNavMenuOpenAtom.debugLabel = 'Top Nav Menu Open Status';

export { topNavMenuOpenAtom };
