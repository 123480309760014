import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { RouteComponentProps } from '@reach/router';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import usePageTitle from '../../../gatsby-theme-zefr/hooks/usePageTitle';
import { toolsAtom } from '../../atoms/toolsAtom';

const Home: React.FC<RouteComponentProps> = () => {
  usePageTitle('Demo');
  const toolApp = useAtomValue(toolsAtom);

  const isKnownTool = useMemo(() => {
    return toolApp !== 'NONE';
  }, [toolApp]);
  return (
    <Box p={2} data-testid="home">
      <Box py={8}>
        <Typography variant="h3">Tool not {isKnownTool ? 'implemented' : 'selected'}!</Typography>
      </Box>
    </Box>
  );
};

export default Home;
