import { Box, BoxProps, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import { formParamsAtom } from '../../atoms/encodedFormAtoms';
import { demoTargetAudienceDialogOpenAtom } from '../../atoms/targetAudienceAtom';
import ClickablePlaceholderBox from '../ClickablePlaceholderBox';
import TargetAudience from './TargetAudience';

const TargetAudienceResults: React.FC<BoxProps> = ({ ...props }) => {
  const setOpen = useSetAtom(demoTargetAudienceDialogOpenAtom);
  const targetAudience = useAtomValue(formParamsAtom)['targetAudience'];
  const hasSelectedData = useMemo(() => {
    return Object.keys(targetAudience).length > 0;
  }, [targetAudience]);
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={6}
      width={'100%'}
      height={'fit-content'}
      justifyContent={'center'}
      alignItems={'center'}
      {...props}
    >
      <Typography
        alignSelf={'flex-start'}
        variant="h6"
        fontWeight={'700'}
        sx={(theme) => ({
          color: theme.palette.secondary.main,
        })}
      >
        Target Audience
      </Typography>
      {hasSelectedData ? (
        <TargetAudience />
      ) : (
        <ClickablePlaceholderBox
          sx={() => {
            return {
              flexGrow: 1,
            };
          }}
          onClick={() => setOpen(true)}
          label={'Add Details'}
        />
      )}
    </Box>
  );
};

export default TargetAudienceResults;
