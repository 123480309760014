/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { Cancel } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  FormControl,
  FormLabel,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PerformanceOptimizationIcon } from '@zefr/style-guide/dist/ui/icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_DEMO_WIDTH, MAX_DEMO_WIDTH_XL, SOFT_BLUE } from '../../../../gatsby-theme-zefr/theme';
import { useDemoForm, KPI_STEP } from '../../../context/DemoFormContext';
import useCancelDemoDialog from '../../../hooks/dialog/useCancelDemoDialog';

const PaddedFormControl = styled(FormControl)(({ theme }) => ({
  boxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  height: '100%',
  maxWidth: MAX_DEMO_WIDTH,
  [theme.breakpoints.up('lg')]: {
    maxWidth: MAX_DEMO_WIDTH_XL,
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: `${theme.spacing(8)} ${theme.spacing(6)}`,
}));

const StyledFormWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',

  display: 'flex',
  flexDirection: 'column',
  '& .form-buttons .MuiButtonBase-root': {
    textTransform: 'capitalize',
    letterSpacing: '2px',
    padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
  },
  '& .MuiInputBase-root, & .concepts-container, & .creative-alignment-container': {
    backgroundColor: theme.palette.common.white,
  },
}));

export interface FormStepBoxProps extends BoxProps {
  titleElement?: React.ReactNode;
  childrenBoxProps?: Partial<BoxProps>;
  step?: number;
  isNextHidden?: boolean;
  isNextDisabled?: boolean;
  nextLabel?: string;
  onNext?: React.MouseEventHandler<HTMLButtonElement>;
  isPreviousDisabled?: boolean;
  onPrevious?: React.MouseEventHandler<HTMLButtonElement>;
}

// FormStepBox - Represents a step in a form, providing navigation and state management
export const FormStepBox: React.FC<FormStepBoxProps> = ({
  children,
  childrenBoxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '100%',
    sx: {
      '& > *': {
        width: '100%',
      },
    },
  },
  titleElement,
  step: myStep,
  isNextDisabled,
  onNext,
  isPreviousDisabled,
  onPrevious,
  isNextHidden,
  nextLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const { step, setStep } = useDemoForm();
  const openCancelDemoDialog = useCancelDemoDialog();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const previousStep: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (onPrevious) {
        onPrevious(e);
      } else {
        setStep(step - 1);
      }
    },
    [onPrevious, setStep, step],
  );

  const nextStep: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (onNext) {
        onNext(e);
      } else {
        setStep(step + 1);
      }
    },
    [onNext, setStep, step],
  );

  const handleCancelClick = useCallback(() => {
    openCancelDemoDialog();
  }, [openCancelDemoDialog]);

  if (typeof myStep !== 'undefined' && step !== myStep) {
    return null;
  }

  return (
    <StyledFormWrapper {...props} className="form-step-box">
      <PaddedFormControl
        sx={{ justifySelf: 'flex-start' }}
        key={'platform-form-control'}
        fullWidth
        focused
      >
        {titleElement && (
          <FormLabel id="platform-label" className="form-title-label">
            {titleElement}
          </FormLabel>
        )}
        <Box {...childrenBoxProps} className="form-step-box-children">
          {children}
        </Box>
      </PaddedFormControl>
      <Box
        position={'sticky'}
        bottom={0}
        zIndex={'50'}
        sx={{
          backgroundColor: SOFT_BLUE,
        }}
      >
        {step === KPI_STEP && (
          <Box
            boxSizing={'border-box'}
            px={2}
            mb={2}
            sx={(theme) => ({
              width: '100%',
              height: '87px',
            })}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.main,
                height: '100%',
                borderRadius: '10px',
                alignSelf: 'flex-end',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: 'calc(50% + 64px)',
                maxWidth: '100%',
              })}
            >
              <Box
                sx={{
                  color: 'white',
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  maxWidth={'100%'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  Powered By
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={'700'}
                  color="inherit"
                  maxWidth={'100%'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  Zefr Optimization Performance Engine
                </Typography>
              </Box>
              <PerformanceOptimizationIcon
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: '7rem',
                }}
              />
            </Box>
          </Box>
        )}
        <Divider />
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={2}
          alignItems={'center'}
          boxSizing={'border-box'}
          py={2}
          px={4}
          className={'form-buttons'}
        >
          <Box>
            <Button
              variant="contained"
              size="large"
              color={'info'}
              onClick={handleCancelClick}
              className="form-button-cancel"
            >
              {matches ? t('cancel') : <Cancel />}
            </Button>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            gap={2}
            alignItems={'center'}
            boxSizing={'border-box'}
            padding={2}
          >
            {!isPreviousDisabled && (
              <Button
                disabled={isPreviousDisabled}
                variant="contained"
                size="large"
                color={'info'}
                onClick={previousStep}
                className="form-button-previous"
              >
                {t('previous')}
              </Button>
            )}
            {!isNextHidden && (
              <Button
                disabled={isNextDisabled}
                variant="contained"
                size="large"
                color="primary"
                onClick={nextStep}
                className="form-button-next"
                sx={(theme) => ({
                  '&.Mui-disabled': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    opacity: 0.25,
                  },
                })}
              >
                {nextLabel || t('next')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </StyledFormWrapper>
  );
};

export default FormStepBox;
