import { Box, BoxProps } from '@mui/material';
import { styled } from '@zefr/style-guide';
import React from 'react';

const ResponsiveBox = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)} ${theme.spacing(16)}`,
    },
  };
});

export interface LayoutProps extends BoxProps {}

const Layout: React.FC<LayoutProps> = ({
  children,
  className = '',
  boxSizing = 'border-box',
  ...props
}) => {
  return (
    <ResponsiveBox
      className={`bg-decal${className ? ` ${className}` : ''}`}
      {...props}
      {...{ boxSizing }}
    >
      {children}
    </ResponsiveBox>
  );
};

export default Layout;
