import { PrefabData } from '../atoms/encodedFormAtoms';

const DISABLED_LD_FLAG = 'DISABLED';
export const DemoFilledWidthFlag = 'demoFilledWidth' + DISABLED_LD_FLAG;
export const SalesDemoTargetAudienceOptionsFlag =
  'salesDemoTargetAudienceOptions' + DISABLED_LD_FLAG;
export const SalesDemoPlatformsFlag = 'salesDemoPlatforms' + DISABLED_LD_FLAG;
export const SalesDemoListsFlag = 'salesDemoLists' + DISABLED_LD_FLAG;
export const SalesDemoRfpPrefabsFlag = 'salesDemoRFPPrefabs' + DISABLED_LD_FLAG;
export const SalesDemoRFPFlag = 'salesDemoRFP' + DISABLED_LD_FLAG;
export const AppsFlag = 'apps' + DISABLED_LD_FLAG;
export const ImageVerifaiExamplesFlag = 'imageVerifaiExamples' + DISABLED_LD_FLAG;
export const InclusionTextEnabledFlag = 'inclusionTextEnabled';
export const GeminiProPromptWrapperFlag = 'geminiProPromptWrapper';
export const GeminiProPromptDefaultsFlag = 'geminiProPromptDefaults';
export const GeminiProResponseSlugTransformationsFlag = 'geminiProResponseSlugTransformations';

export interface LaunchDarklyFlagSet {
  [InclusionTextEnabledFlag]: boolean;
  [GeminiProPromptWrapperFlag]: string;
  [GeminiProResponseSlugTransformationsFlag]: Record<string, string>;
  [GeminiProPromptDefaultsFlag]: {
    temperature: number;
    top_k: number;
    top_p: number;
    [x: string]: unknown;
  };
}
interface ImageQuiltData {
  img: string;
  title: string;
  rows?: number;
  cols?: number;
  [x: string]: any;
}

export interface LearnMoreType {
  title?: string;
  body?: string;
  icon?: string;
  closeText?: string;
  divider?: {
    color?: string;
    startColor?: string;
    endColor?: string;
  };
  [x: string]: any;
}

export interface SalesDemoPlatform {
  value?: string;
  disabled?: boolean;
  label?: string;
  labelIcon?: string;
  logoIcon?: string;
  logoLabel?: string;
  details?: string[];
  detailProps?: Record<string, any>;
  learnMore?: LearnMoreType;
  [x: string]: any;
}

export interface Option {
  value?: string;
  label?: string;
  disabled?: boolean;
  [x: string]: any;
}

export interface RiskToleranceLevels {
  placeholder?: string;
  options?: Option[];
  [x: string]: unknown;
}

export interface RfpOption extends Option {
  data?: PrefabData;
}

export interface RfpPrefabsLaunchDarkly {
  placeholder?: string;
  options?: RfpOption[];
}

export interface RfpPrefabsTransformed {
  placeholder?: string;
  options: Record<string, RfpOption>;
}

export interface AdditionalExclusions {
  title?: string;
  options?: Option[];
}

export interface SalesDemoRFP {
  riskToleranceLevels?: RiskToleranceLevels;
  additionalExclusions?: AdditionalExclusions;
  [x: string]: any;
}

export interface SalesDemoTargetAudienceOption {
  type?: 'text' | 'select' | 'multiselect' | string;
  placeholder?: string;
  defaultValue?: string | string[];
  options?: Record<string, string>;
  label?: string;
  [x: string]: any;
}

export type ImageVerifaiExamplesFlagData = Record<string, ImageQuiltData[]>;
export type AppsFlagData = Record<string, number>;
export type SalesDemoRFPData = SalesDemoRFP;
export type SalesDemoTargetAudienceOptionsData = Record<string, SalesDemoTargetAudienceOption>;
