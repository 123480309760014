import { Box } from '@mui/material';
import { TOP_NAV_HEIGHT } from '@zefr/style-guide/dist/ui/TopNav';
import React from 'react';
import { useDemoForm } from '../context/DemoFormContext';
import DemoStepsBar from './DemoComponents/DemoStepsBarV2';
import { FORM_LINEAR_GRADIENTS } from './DemoComponents/Form/components';
import MultiStepSalesForm from './MultiStepSalesForm';

const DemoForm = () => {
  const { finalStep } = useDemoForm();
  return (
    <Box
      data-cy="sales-demo"
      data-testid="sales-demo"
      display={'flex'}
      width={'100%'}
      minHeight={'100%'}
      maxWidth={'100vw'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      position={'relative'}
      sx={(theme) => {
        return {
          gap: theme.spacing(2),
          background: FORM_LINEAR_GRADIENTS.right,
          [theme.breakpoints.up('md')]: {
            gap: 0,
          },
        };
      }}
    >
      {finalStep ? undefined : (
        <Box
          sx={(theme) => ({
            background: FORM_LINEAR_GRADIENTS.top,
            position: 'sticky',
            top: TOP_NAV_HEIGHT,
            zIndex: 25,
            boxSizing: 'border-box',
            padding: finalStep ? undefined : `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
            [theme.breakpoints.up('md')]: {
              padding:
                finalStep ? undefined : `0 ${theme.spacing(32)} 0 ${theme.spacing(32)}`,
            },
          })}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
        >
          <DemoStepsBar />
        </Box>
      )}
      <Box
        height="100%"
        width={'100%'}
        boxSizing={'border-box'}
        flex={1}
        display={'flex'}
        sx={(theme) => {
          return {
            padding:
              finalStep
                ? undefined
                : `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
            [theme.breakpoints.up('md')]: {
              padding:
                finalStep
                  ? undefined
                  : `${theme.spacing(8)} ${theme.spacing(16)} 0 ${theme.spacing(16)}`,
            },
            [theme.breakpoints.up('lg')]: {
              padding:
                finalStep
                  ? undefined
                  : `${theme.spacing(16)} ${theme.spacing(16)} 0 ${theme.spacing(16)}`,
            },
          };
        }}
      >
        <MultiStepSalesForm />
      </Box>
    </Box>
  );
};

export default DemoForm;
