import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import ContentAlignment from './ContentAlignment';

const ContentAlignmentResults: React.FC<BoxProps> = (props) => {
  return (
    <Box
      className={`content-alignment-results-container`}
      display={'flex'}
      flexDirection={'column'}
      gap={4}
      width={'100%'}
      height={'fit-content'}
      {...props}
    >
      <Typography
        className={`content-alignment-results-title`}
        variant="h6"
        fontWeight={'700'}
        sx={(theme) => ({
          color: theme.palette.secondary.main,
        })}
      >
        Content Alignment
      </Typography>
      <ContentAlignment className={`content-alignment-results-result`} />
    </Box>
  );
};

export default ContentAlignmentResults;
