import { Accordion, FormControl, styled } from '@mui/material';
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import React from 'react';

export const PaddedFormControl = styled(FormControl)(({ theme }) => {
  return {
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)} ${theme.spacing(6)}`,
    },
    // backgroundColor: theme.palette.secondary.main,
    height: '100%',
    overflow: 'auto',
  };
});

export const SummaryItems = styled(Grid)(({ theme }) => {
  return {
    '& > .summary-item': {},
  };
});

export const SummaryItem = styled(Grid)(({ theme }) => {
  return {
    borderRadius: '25px',
    color: theme.palette.secondary.main,
    backgroundColor: `rgba(255,255,255,0.6)`,
  };
});

export const SummaryItemGrid: React.FC<Grid2Props> = ({
  className = '',
  display = 'flex',
  flexDirection = 'row',
  justifyContent = 'center',
  alignItems = 'center',
  gap = 1,
  boxSizing = 'border-box',
  overflow = 'auto',
  ...props
}) => {
  return (
    <SummaryItem
      id="project-performance-summary-item-platform"
      className={`summary-item${className ? ` ${className}` : ''}`}
      {...props}
      {...{ display, flexDirection, justifyContent, alignItems, gap, boxSizing, overflow }}
    />
  );
};

export const SummaryAccordion = styled(Accordion)(({ theme, color }) => {
  return {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
    },
    '&, & .MuiAccordionSummary-expandIconWrapper': {
      color: color || theme.palette.common.black,
    },
  };
});
