import { Box, Slide, SlideProps } from '@mui/material';
import { useAtomValue } from 'jotai';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { chipActiveFiltersAtom, chipFilterAtom } from '../../atoms/chipAtom';
import { useDemoForm } from '../../context/DemoFormContext';
import ChipDots from './ChipDots';
import { ChipSwapProps } from './ChipSwapper';
import { StyledSwapChip } from './components';

export interface ProxyConceptChipProps extends ChipSwapProps {
  ignoreFilters?: boolean;
  hideFilters?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  slideProps?: Partial<SlideProps>;
}

const ProxyConceptChip: React.FC<ProxyConceptChipProps> = ({
  key,
  skipFocusWhenDisabled,
  filters: _filters,
  active,
  className,
  id,
  direction = 'left',
  color = 'secondary',
  ignoreFilters = false,
  hideFilters = false,
  slideProps,
  ...props
}) => {
  const { apiConcepts } = useDemoForm();
  const filters = useAtomValue(chipFilterAtom);
  const filterKeys = useAtomValue(chipActiveFiltersAtom);

  const myData = useMemo(() => {
    if (id) {
      return apiConcepts[id];
    }
    return undefined;
  }, [apiConcepts, id]);

  const myFilters = useMemo(() => {
    if (myData) {
      const newFilters = myData.filters || [];
      return sortBy(newFilters);
    }
    return [];
  }, [myData]);
  const isInFilter = useMemo(() => {
    if (ignoreFilters || hideFilters) return true;
    if (!(typeof filters === undefined || myFilters.length === 0)) {
      for (let i = 0; i < myFilters.length; i++) {
        const v = myFilters[i];
        if (filterKeys?.indexOf(v) !== -1) {
          return true;
        }
      }
      return false;
    }
    return true;
  }, [myFilters, filters, filterKeys]);

  const statusDots = useMemo(() => {
    if (hideFilters) return [];
    return myFilters.map((k) => {
      const backgroundColor =
        filters && filters[k as keyof typeof filters]
          ? filters[k as keyof typeof filters].color
          : '';
      if (!backgroundColor) {
        return undefined;
      }
      return <Box key={k} id={`${key}-${k}`} sx={{ backgroundColor }}></Box>;
    });
  }, [myFilters, filters, key, hideFilters]);

  if (!myData) return null;

  return (
    <Slide
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={isInFilter ? 500 : undefined}
      key={key}
      direction={direction}
      in={isInFilter ? true : false}
      id={id}
      data-active={active ? true : false}
      className={`proxy-chip-slide proxy-chip-slide-${active ? '' : 'in'}active`}
      {...slideProps}
    >
      <StyledSwapChip
        sx={{ paddingLeft: 0, paddingRight: hideFilters ? 0 : undefined }}
        id={id || key}
        data-key={key}
        onDelete={() => {}}
        deleteIcon={<ChipDots>{statusDots}</ChipDots>}
        className={`proxy-chip proxy-chip-${active ? '' : 'in'}active ${
          isInFilter ? `` : `inactive`
        } ${className || ''} ${
          hideFilters
            ? Object.keys(myFilters)
                .map((v) => `filter-${v}`)
                .join(' ')
            : ''
        }`}
        size="small"
        {...props}
        {...{ skipFocusWhenDisabled, key, active, color }}
        label={myData.title}
      />
    </Slide>
  );
};

export default ProxyConceptChip;
