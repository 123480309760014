import { useMutation } from '@tanstack/react-query';
// import { GET_TOKEN_PATH } from 'config/api'
import { datadogLogs } from '@datadog/browser-logs';
import { getImageVerifaiResultsPath } from '../../../api/config/growth/v0';
import { postApiFn } from '../../../api/helper';

export const QUERY_KEY = 'image_verifai';

interface RequestParams {
  url: string;
}

interface RequestResponse {
  confidence_score?: number;
  is_fake?: boolean;
}

type Response = RequestResponse;

const useImageVerifaiMutation = () => {
  const path = getImageVerifaiResultsPath();
  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: async ({ url }: RequestParams) => {
      return await postApiFn(path, { url })
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results from Image VerifAI service', { url, res });
          return res;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results from Image VerifAI service', {
            url,
            error,
          });
          throw new Error(error);
        });
    },
  });
};

export default useImageVerifaiMutation;
