import { atom } from 'jotai';

type State = boolean | undefined;

const initialState: State = undefined;

const sidebarHiddenAtom = atom(initialState as State);

sidebarHiddenAtom.debugLabel = 'Sidebar Hidden Status';

export { sidebarHiddenAtom };
