import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { chipDataAtom, selectedChipsAtom } from '../../atoms/chipAtom';
import { demoChipDialogSaveAtom } from '../../atoms/demoChipDialogOpenAtom';
import { demoDialogContentNodeAtom, demoDialogOpenAtom } from '../../atoms/demoDialogAtom';
import ChipSwapper, { ChipSwapProps } from '../../components/ChipSwapper/ChipSwapper';
import { SwapChipProps } from '../../components/ChipSwapper/SwapChip';
import { useDemoForm } from '../../context/DemoFormContext';

const HeaderContent = () => {
  return (
    <Box
      position={'relative'}
      px={12}
      py={8}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({
          color: theme.palette.secondary.main,
        })}
        fontWeight={'800'}
        textAlign={'center'}
      >
        Content Preferences
      </Typography>
    </Box>
  );
};

const BodyContent: React.FC<{ isExclusion?: boolean }> = ({ isExclusion }) => {
  return (
    <DialogContent>
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        gap={1}
      >
        <ChipSwapper isExclusion={isExclusion} />
      </Box>
    </DialogContent>
  );
};

const FooterContent: React.FC<{ isExclusion?: boolean }> = ({ isExclusion }) => {
  const chipSaveFunc = useAtomValue(demoChipDialogSaveAtom);
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const selectedChips = useAtomValue(selectedChipsAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  const handleSave = React.useCallback(() => {
    chipSaveFunc.save(selectedChips);
    handleClose();
  }, [handleClose, chipSaveFunc, selectedChips]);
  return (
    <DialogActions sx={{ justifyContent: 'space-evenly' }}>
      <Button size={'large'} variant="contained" onClick={handleClose} color="info">
        Cancel
      </Button>
      <Button size={'large'} variant="contained" onClick={handleSave}>
        {isExclusion ? 'Exclude' : 'Include'} Concepts
      </Button>
    </DialogActions>
  );
};

const useChipDialog = (params: { isExclusion?: boolean }) => {
  const { isExclusion } = params;
  const {
    apiConcepts,
    excludedConcepts,
    includedConcepts,
    listType,
    setFormExcludedConcepts,
    setFormIncludedConcepts,
  } = useDemoForm();
  const myConcepts = useMemo(
    () => (isExclusion ? excludedConcepts : includedConcepts) || [],
    [isExclusion, excludedConcepts, includedConcepts],
  );

  const notMyConcepts = useMemo(
    () => (!isExclusion ? excludedConcepts : includedConcepts) || [],
    [isExclusion, excludedConcepts, includedConcepts],
  );
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const setChips = useSetAtom(chipDataAtom);
  const setChipDialogSave = useSetAtom(demoChipDialogSaveAtom);

  const mySave = useCallback(
    (newChips: SwapChipProps[] | ChipSwapProps[]) => {
      const saveFunction = isExclusion ? setFormExcludedConcepts : setFormIncludedConcepts;
      saveFunction(
        newChips.map((data) => {
          return data.key;
        }),
      );
    },
    [isExclusion, setFormExcludedConcepts, setFormIncludedConcepts],
  );
  const handleClick = useCallback(() => {
    setChips(
      Object.values(apiConcepts)
        .filter((d) => {
          const isMyConcept = notMyConcepts.indexOf(d.id) === -1;
          if (listType === 'exclusion') {
            if (isMyConcept) {
              return d.is_bsp_concept === true;
            }
          }
          return isMyConcept;
        })
        .map(({ filters, ...data }) => ({
          key: data.id,
          label: data.title,
          active: myConcepts.indexOf(data.id) !== -1,
          color: isExclusion ? 'error' : 'secondary',
          filters: [],
          ...data,
        })),
    );
    setChipDialogSave({ save: mySave });
    setDemoDialogContent(
      <>
        <HeaderContent />
        <BodyContent isExclusion={isExclusion} />
        <FooterContent isExclusion={isExclusion} />
      </>,
    );
    setDemoDialog(true);
  }, [
    setDemoDialog,
    setDemoDialogContent,
    setChips,
    apiConcepts,
    notMyConcepts,
    listType,
    isExclusion,
    setChipDialogSave,
    mySave,
  ]);
  return handleClick;
};

export default useChipDialog;
