import { SuitabilityIcon } from '@zefr/style-guide/dist/ui/icons/internalApps';
import { ExploreIcon } from '@zefr/style-guide/dist/ui/icons/marketing';
import React from 'react';
import { getIfUserHasPermissionsFunc } from '../../../src/helpers/pluginHandling/helpers';
import { SITE_APP_DEMO, SITE_APP_GROWTH, TopNavTab } from '../../gatsby-theme-zefr/types/shared';
import { APP_ImageVerifai } from '../atoms/toolsAtom';
import { BASE_URL, DEMO_BASE_URL } from '../routes/routes';

export const topNavTab: TopNavTab = {
  target: '_self',
  icon: <ExploreIcon />,
  label: 'growth.title', // Label can be a key in the common namespace for the resource bundle as well
  link: `${BASE_URL}/${APP_ImageVerifai}`,
  value: SITE_APP_GROWTH,
};

export const getGrowthTab: getIfUserHasPermissionsFunc<TopNavTab> = (user) => {
  if (user?.authenticated) {
    return topNavTab;
  }
  return undefined;
};

export const demoTab: TopNavTab = {
  target: '_self',
  icon: <SuitabilityIcon />,
  label: 'growth.demo.title', // Label can be a key in the common namespace for the resource bundle as well
  link: `${DEMO_BASE_URL}`,
  value: SITE_APP_DEMO,
};

export const getDemoTab: getIfUserHasPermissionsFunc<TopNavTab> = (user) => {
  if (user?.authenticated) {
    return demoTab;
  }
  return undefined;
};
