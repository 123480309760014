import { atom } from 'jotai';
import { ChipSwapProps } from '../components/ChipSwapper/ChipSwapper';

const initialSaveState = {
  save: (selectedChips: ChipSwapProps[]) => {
    console.log('Save');
  },
};

const demoChipDialogSaveAtom = atom(initialSaveState);

demoChipDialogSaveAtom.debugLabel = 'Demo Chip Dialog Save Func';
demoChipDialogSaveAtom.debugPrivate = true;

export { demoChipDialogSaveAtom };
