import { Theme } from '@mui/material';
import { MAX_DEMO_WIDTH, MAX_DEMO_WIDTH_XL } from '../theme';

export const getMaxWidthPercentage = (theme: Theme) => {
  return {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  };
};

export const getMaxWidthPixels = (theme: Theme) => {
  return {
    maxWidth: MAX_DEMO_WIDTH,
    [theme.breakpoints.up('lg')]: {
      maxWidth: MAX_DEMO_WIDTH_XL,
    },
  };
};
