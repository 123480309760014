import {
  topNavTab as GrowthTab,
  getDemoTab,
  getGrowthTab,
} from '../../../plugins/gatsby-plugin-zefr-growth/helpers/mainSite';
import { type TopNavTab } from '../../../plugins/gatsby-theme-zefr/types/shared';
import { BASE_APP_ROUTE } from '../../helpers';
import { PermissionedData, getIfUserHasPermissionsFunc } from './helpers';

export const AuthenticatedTabs: PermissionedData<TopNavTab>[] = [
  { ...GrowthTab, link: `/${BASE_APP_ROUTE}/${GrowthTab.link}` },
];

export const UnAuthenticatedTabs: TopNavTab[] = [];

export const getTabs: getIfUserHasPermissionsFunc<TopNavTab[]> = (user) => {
  const tabsToModifyLinks: PermissionedData<TopNavTab>[] = [getDemoTab, getGrowthTab];
  const modifiedTabs: TopNavTab[] = [];
  tabsToModifyLinks.forEach((tabValueOrFunc) => {
    let newTab: TopNavTab | undefined = undefined;
    if (typeof tabValueOrFunc === 'function') {
      newTab = tabValueOrFunc(user);
    } else {
      newTab = tabValueOrFunc;
    }
    // for each link in the tab, nest it under our current route.
    if (newTab?.link) {
      newTab = { ...newTab, link: `/${BASE_APP_ROUTE}/${newTab.link}` };
    }
    if (typeof newTab !== 'undefined') {
      modifiedTabs.push(newTab);
    }
  });
  return [...modifiedTabs];
};
