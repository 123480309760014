import { useAuth0 } from '@auth0/auth0-react';
import { AdminPanelSettings, Person } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@zefr/style-guide';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isAllowedAdminAtom,
  isEngineerAtom,
  userAtom,
} from '../../../gatsby-plugin-zefr-auth0/atoms/userAtom';
import { adminAtom } from '../../atoms/adminAtom';
import { markdownDialogOpenAtom } from '../../atoms/markdownDialogOpenAtom';
import { topNavMenuOpenAtom } from '../../atoms/topNavMenuOpenAtom';

const TopNavMenu = () => {
  const [open, setOpen] = useAtom(topNavMenuOpenAtom);
  const [isAdmin, setAdmin] = useAtom(adminAtom);
  const isAdminAllowed = useAtomValue(isAllowedAdminAtom);
  const isEngineer = useAtomValue(isEngineerAtom);
  const { isLoading, logout } = useAuth0();
  const user = useAtomValue(userAtom);
  const setOpenMarkdownDialog = useSetAtom(markdownDialogOpenAtom);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const ref = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpen(true);
    },
    [setOpen],
  );
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    handleClose();
  }, [handleClose, logout]);

  const handleToggleAdmin = useCallback(() => {
    setAdmin((old) => !old);
  }, [setAdmin]);

  const handleCopyJwt = useCallback(async () => {
    const previousValue = await navigator.clipboard.readText();
    await navigator.clipboard.writeText(user?.accessToken || previousValue);
    handleClose();
  }, [handleClose, user?.accessToken]);

  useEffect(() => {
    if (ref.current != anchorEl) {
      setAnchorEl(ref.current);
    }
  }, [setAnchorEl, ref, anchorEl]);

  return (
    <Box color="white" height={'100%'}>
      <Button
        sx={{ height: '100%' }}
        id="basic-button"
        color={'inherit'}
        disabled={isLoading}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        ref={ref}
      >
        {isAdmin ? <AdminPanelSettings /> : <Person />}
        {user?.user?.name || t('loading')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open || false}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            width: '175px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isAdminAllowed ? (
          <MenuItem onClick={handleToggleAdmin}>
            {isAdmin ? 'Disable ' : 'Enable '} Admin Mode
          </MenuItem>
        ) : undefined}
        {isEngineer ? <MenuItem onClick={handleCopyJwt}>{t('copy_jwt')}</MenuItem> : undefined}
        {isEngineer ? (
          <MenuItem
            onClick={() => {
              setOpenMarkdownDialog(true);
            }}
          >
            {t('markdown_helper')}
          </MenuItem>
        ) : undefined}
        <MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
      </Menu>
    </Box>
  );
};

export default TopNavMenu;
