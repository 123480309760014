/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { Divider, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm, FINAL_STEP } from '../../../../context/DemoFormContext';
import ContentAlignmentResults from '../../../ContentAlignment/ContentAlignmentResults';
import TargetAudienceResults from '../../../TargetAudience/TargetAudienceResults';
import CreatePrefabButton from '../CreatePrefabButton';
import FormStepBox from '../FormStepBox';
import SetPrefabSelect from '../SetPrefabSelect';
import { FormTitle } from '../components';
import { FormStepProps } from '../types';

export const FormStepInclusionCustomForm: React.FC<FormStepProps> = ({
  step: myStep = 3,
  'data-testid': testId,
  ...props
}) => {
  const { t } = useTranslation();
  const { setStep, riskLevel } = useDemoForm();
  /* #region 🐙 States */
  const hasNoRisk = useMemo(() => {
    return typeof riskLevel === 'undefined' ? false : riskLevel < 0;
  }, [riskLevel]);

  const handleNext = useCallback(() => {
    setStep(FINAL_STEP);
  }, [setStep]);
  /* #endregion */
  /* #region 🎃 Events Handlers */
  /* #endregion */
  return (
    <FormStepBox
      titleElement={
        <FormTitle marginBottom={6}>{t(`growth.${APP_Demo}.step.three.title`)}</FormTitle>
      }
      step={myStep}
      nextLabel="Finish"
      isNextDisabled={hasNoRisk}
      onNext={handleNext}
      data-testid={testId}
    >
      <Stack alignSelf={'flex-start'} gap={4} divider={<Divider />}>
        <CreatePrefabButton />
        <SetPrefabSelect sx={{ width: '100%' }} />
        <ContentAlignmentResults />
        <TargetAudienceResults />
      </Stack>
    </FormStepBox>
  );
};

export default FormStepInclusionCustomForm;
