import { ChevronRight } from '@mui/icons-material';
import { Box, Button, TextareaAutosize, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQueryClient } from '@tanstack/react-query';
import { NO_RISK } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import DemoRfpDialog from '../../DemoRfpDialog';
import { QUERY_KEY } from '../../../../hooks/api/v0/useGeminiProModelQuery';
import useGeminiProModelQuery from '../../../../hooks/api/v0/useGeminiProModelQuery';
import { LANGUAGE_CODE_TO_NAME_MAP } from '../../../../hooks/api/v0/languageCodes';
import { ProxyGeminiProModelResponse } from '../../../../types/proxyApi';
import FormStepBox from '../FormStepBox';
import { FormTitle } from '../components';
import { FormStepProps } from '../types';
import { INCLUSION_LIST_PREFAB } from '../../../../constants/listTypes';

function parseRfpResponse(
  apiConceptsBySlug: Record<string, any>,
  res: ProxyGeminiProModelResponse | null
) {
  const inclusionIds = res?.include?.map(slug => apiConceptsBySlug[slug].id) || [];
  const exclusionIds = res?.exclude?.map(slug => apiConceptsBySlug[slug].id) || [];
  const languages = [LANGUAGE_CODE_TO_NAME_MAP[res?.language || "en"]];
  return { inclusionIds, exclusionIds, languages };
}

export const FormStepInclusionFormFreeText: React.FC<FormStepProps> = ({
  step: myStep = 4,
  'data-testid': testId
}) => {
  const { t } = useTranslation();

  const [enabled, setEnabled] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [rfpText, setRfpText] = React.useState('');
  const [rfpResponse, setRfpResponse] = React.useState(null as ProxyGeminiProModelResponse | null);
  const [validResponse, setValidResponse] = React.useState(false);

  const queryClient = useQueryClient();

  const { data, isFetching } = useGeminiProModelQuery({ rfp: rfpText, enabled });

  const {
    apiConceptsBySlug,
    setFormExcludedConcepts,
    setFormIncludedConcepts,
    setFormInclusionListType,
    setFormLanguages,
    setFormRisk,
    setStep,
    step,
  } = useDemoForm();

  useEffect(() => {
    if (!isFetching) {
        if (data?.summary?.length) {
          setValidResponse(true);
          setRfpResponse(data);
        } else {
          setValidResponse(false);
          setRfpResponse(null);
        }
    }
  }, [
      data,
      isFetching,
      setRfpResponse,
      setValidResponse
  ]);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setEnabled(false);
    setValidResponse(false);
    setRfpResponse(null);
  }, [setOpenDialog, setEnabled, setValidResponse, setRfpResponse]);

  const handleContinue = useCallback(() => {
    const {
      inclusionIds,
      exclusionIds,
      languages
    } = parseRfpResponse(apiConceptsBySlug, rfpResponse);
    setRfpResponse(rfpResponse)
    setFormRisk(NO_RISK);
    setFormLanguages(languages);
    setFormIncludedConcepts(inclusionIds);
    setFormExcludedConcepts(exclusionIds);
    setStep(step + 1);
    handleClose();
  }, [
    apiConceptsBySlug,
    rfpResponse,
    step,
    handleClose,
    setFormExcludedConcepts,
    setFormIncludedConcepts,
    setFormLanguages,
    setFormRisk,
    setStep,
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setRfpText(event.target.value);
    },
    [setRfpText],
  );

  const handleSubmit = useCallback(() => {
    if (rfpText) {
      setEnabled(true);
      setOpenDialog(true);
    }
  }, [setEnabled, setOpenDialog, rfpText]);

  const handleCancel = useCallback(() => {
    queryClient.cancelQueries({
      queryKey: [QUERY_KEY, rfpText],
    });
    setEnabled(false);
  }, [queryClient, setEnabled, rfpText]);

  if (step !== myStep) {
    return null;
  }

  return (
    <>
      <FormStepBox
        data-testid={testId}
        isNextDisabled={!Boolean(rfpText?.length)}
        onNext={enabled ? handleCancel : handleSubmit}
        nextLabel={enabled ? 'Cancel' : 'Submit'}
        titleElement={
          <Grid width={'100%'} container pb={2}>
            <Grid xs={2} sx={{ opacity: 0 }}>
              <Button variant="text" disabled sx={{ opacity: 0 }}>
                Hidden
              </Button>
            </Grid>
            <Grid
              xs={8}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <FormTitle sx={{ marginBottom: 0 }} marginBottom={6}>
                {t(`growth.${APP_Demo}.step.three.title`)}
              </FormTitle>
            </Grid>
            <Grid xs={2}>
              <Button
                fullWidth
                sx={{ height: '100%' }}
                variant="text"
                onClick={() => {
                  setFormInclusionListType(INCLUSION_LIST_PREFAB);
                }}
              >
                Use Manual Form <ChevronRight />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Box display={'flex'} flexDirection={'column'} overflow={'auto'} gap={1}>
          <Typography color="black" variant="h6" fontWeight={'bold'}>
            Text Form
          </Typography>
          <Typography>
            Complete the text field with any information regarding audience demographics, content
            preferences and risk tolerance, and any other information you would like us to know in
            order to generate content recommendations.
          </Typography>
          <TextareaAutosize
            style={{
              borderRadius: '5px',
              padding: '0.5rem',
              width: '100%',
              minWidth: '100%',
              maxWidth: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
            }}
            minRows={16}
            value={rfpText || ''}
            defaultValue={''}
            onChange={handleChange}
            placeholder="We want to advertise to..."
          />
        </Box>
      </FormStepBox>
      <DemoRfpDialog
        data={data || null}
        loading={isFetching}
        open={openDialog}
        onClose={handleClose}
        onContinue={handleContinue}
        validResponse={validResponse}
      />
    </>
  );
};

export default FormStepInclusionFormFreeText;
