import { NO_RISK } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { RISK_LEVEL_OPTIONS } from "../atoms/contentAlignmentAtom";

export interface RfpExclusionOption {
    value: string;
    label: string;
}

export interface RfpExclusionOptions {
    riskToleranceLevels: {
        defaultValue: string;
        label: string;
        placeholder: string;
        options: RfpExclusionOption[];
    };
    additionalExclusions: {
        title: string;
        options: RfpExclusionOption[];
    };
}

export interface RfpInclusionOption {
    value: string;
    label: string;
	data: {
		languages: string[];
		targetAudience: {
			genders: string[];
			ageRange: string[];
			interests: string;
			regions?: string[];
		}
		contentAlignment: {
			brandRiskToleranceLevel?: string;
			vertical: string;
		}
		includedConcepts: string[];
		excludedConcepts: string[];
		creativeAlignment: string;
		risk: string;
	}
}

export interface RfpInclusionOptions {
    placeholder: string;
	options: Record<string, RfpInclusionOption>;
}



export const RFP_EXCLUSION_OPTIONS: RfpExclusionOptions = {
    riskToleranceLevels: {
        defaultValue: NO_RISK,
        label: 'Brand Risk Tolerance Level',
        placeholder: 'Choose Risk Tolerance Level',
        options: RISK_LEVEL_OPTIONS
    },
    additionalExclusions: {
        title: 'Choose additional exclusions',
        options: [
            {
                value: 'Alcohol',
                label: 'Alcohol',
            },
            {
                value: 'Arms & Ammunition',
                label: 'Arms & Ammunition',
            },
            {
                value: "Children's Entertainment",
                label: "Children's Entertainment",
            },
            {
                value: 'Gaming',
                label: 'Gaming',
            },
            {
                value: 'Mature Music',
                label: 'Mature Music',
            },
            {
                value: 'Fighting & Combat Sports',
                label: 'Fighting & Combat Sports',
            },
            {
                value: 'Religion',
                label: 'Religion',
            },
            {
                value: 'News & Politics',
                label: 'News & Politics',
            },
        ],
    },
};

export const RFP_INCLUSION_OPTIONS: RfpInclusionOptions = {
    placeholder: 'Choose an RFP',
    options: {
		business_services: {
			value: 'business_services',
			label: 'Business Services',
			data: {
				languages: ['English'],
				targetAudience: {
					genders: ['male', 'female', 'unknown'],
					ageRange: ['45 - 54', '55 - 64', '65+', 'unknown', '35 - 44'],
					interests: 'business, tech, travel, luxury, outdoor adventures, real estate & home improvement, fitness, sports, social good',
					regions: ['us', 'canada'],
				},
				contentAlignment: { vertical: 'financial_services' },
				includedConcepts: [
					'79984b21-0348-41cd-8868-826ad8c06a4a',
					'154ab8f9-2d18-4efc-887b-b71e7341f812',
					'ae7b530f-e4de-4aaf-b72e-c6fb2d0e15c7',
					'5478168a-2cb8-4efa-8d6b-eb89cc9fe71c',
					'72f6551d-2e22-4742-b868-9b03c2db03c2',
					'80fd3e97-460c-408c-998c-f7fc2afaef54',
					'956dd821-9500-49be-a3a1-814b3cc8ea8f',
					'a710b18c-dedc-4ddd-a7a0-a024d882f7c6',
					'1bee3c75-fbce-4e16-87c8-f78507f26cda',
					'e5672145-19ec-48c5-bcab-d1244adf277d',
					'74788461-cbbc-4972-a095-561d752e005d',
					'6a674c26-7713-48ea-b17d-30c8687b3a29',
					'487f7fc9-b345-4429-ae57-90ee52767fbb',
					'b5abdc3b-be60-4d22-8daf-6ea5a686ede1',
					'2d406806-447e-4cf3-86b8-60c4c82bebf1',
					'4b5a2d64-ebec-40a1-85cc-044358be5636',
					'ee467af0-c290-4da7-92e4-82ff7622cf7f',
				],
				excludedConcepts: [
					'e672e8fa-0cc2-47fb-8835-3f29c6d993b3',
					'4431651c-cbb6-46c6-a7e5-5919cf48c3d5',
					'88485951-0ba1-4b91-a1aa-8d92d17bd139',
					'92265146-e5d0-49a6-8749-033f8a8007e1',
					'19b927d8-0e7f-44c5-a6a3-56a7aa9c64f2',
					'fcf2f45a-7397-4174-b996-83fe15a1a4c1',
					'78e42116-bad7-44f8-ae71-c96749703324',
					'f4673472-24d6-4b44-9e07-41f61a699366',
					'013d0814-d844-4fcf-af45-d2792e064f46',
				],
				creativeAlignment: '',
				risk: 'low',
			},
		},
		entertainment: {
			value: 'entertainment',
			label: 'Entertainment',
			data: {
				targetAudience: {
					genders: ['male', 'female'],
					ageRange: ['18 - 24', '25 - 34'],
					interests: 'Tech, social media, fashion, entertainment, gaming, pop culture, music events',
				},
				languages: ['English'],
				contentAlignment: { vertical: 'entertainment' },
				risk: 'high',
				includedConcepts: [
					'56c7c78c-cd05-448b-8170-11aa05d5ff0e',
					'e737ca52-46b7-4512-afc8-7b0e00395b72',
					'a0ca1c8e-118a-460e-a292-d582e06a8099',
					'8fd8b125-e99f-4c51-8fbe-88250132974e',
					'400da3c4-65c5-4438-a286-9146236aa7ed',
					'460dfd97-8669-40fe-a83f-ef633a661ca0',
					'53df2df1-15ed-413e-b5a2-7bdaa8572781',
					'2d406806-447e-4cf3-86b8-60c4c82bebf1',
					'4b5a2d64-ebec-40a1-85cc-044358be5636',
					'6a4bb825-3b20-420f-b027-832e440da8e3',
				],
				excludedConcepts: [
					'4431651c-cbb6-46c6-a7e5-5919cf48c3d5',
					'88485951-0ba1-4b91-a1aa-8d92d17bd139',
					'92265146-e5d0-49a6-8749-033f8a8007e1',
					'f4673472-24d6-4b44-9e07-41f61a699366',
				],
				creativeAlignment: '',
			},
		},
		pharmaceuticals_and_healthcare: {
			value: 'pharmaceuticals_and_healthcare',
			label: 'Pharmaceuticals & Healthcare',
			data: {
				languages: ['English'],
				targetAudience: {
					genders: ['male'],
					ageRange: ['45 - 54', '55 - 64', '65+', 'unknown', '35 - 44', '18 - 24', '25 - 34'],
					interests: 'social activities, entertainment, sports, gaming, movies, TV, technology, business, music, health, personal care',
					regions: ['us', 'canada'],
				},
				contentAlignment: { vertical: 'healthcare_and_pharmaceuticals' },
				includedConcepts: [
					'e672e8fa-0cc2-47fb-8835-3f29c6d993b3',
					'79984b21-0348-41cd-8868-826ad8c06a4a',
					'4179c3ae-6cf8-43f1-9d6b-160d44d560a0',
					'37e7fc3f-0a07-4c39-ab43-cf75af3aa5b4',
					'154ab8f9-2d18-4efc-887b-b71e7341f812',
					'c879450b-0f6b-43fd-9c1b-8b387e037e8d',
					'a0ca1c8e-118a-460e-a292-d582e06a8099',
					'1bee3c75-fbce-4e16-87c8-f78507f26cda',
					'8fd8b125-e99f-4c51-8fbe-88250132974e',
					'460dfd97-8669-40fe-a83f-ef633a661ca0',
					'2d406806-447e-4cf3-86b8-60c4c82bebf1',
					'6a4bb825-3b20-420f-b027-832e440da8e3',
				],
				excludedConcepts: [
					'4431651c-cbb6-46c6-a7e5-5919cf48c3d5',
					'88485951-0ba1-4b91-a1aa-8d92d17bd139',
					'92265146-e5d0-49a6-8749-033f8a8007e1',
					'78e42116-bad7-44f8-ae71-c96749703324',
					'f4673472-24d6-4b44-9e07-41f61a699366',
				],
				creativeAlignment: '',
				risk: 'medium',
			},
		},
		retail: {
			value: 'retail',
			label: 'Retail',
			data: {
				targetAudience: {
					genders: ['female'],
					ageRange: ['25 - 34', 'unknown', '35 - 44', '45 - 54'],
					regions: ['us'],
					interests: 'Healthy diet and lifestyle, fitness & exercise, family routines, family entertainment, family travel and activities',
				},
				languages: ['English'],
				contentAlignment: {
					brandRiskToleranceLevel: 'low',
					vertical: 'cpg_food_and_beverage'
				},
				includedConcepts: [
					'ef1ad1eb-ed12-4b5a-a8cf-b26ced3ba3f6',
					'37e7fc3f-0a07-4c39-ab43-cf75af3aa5b4',
					'a710b18c-dedc-4ddd-a7a0-a024d882f7c6',
					'c879450b-0f6b-43fd-9c1b-8b387e037e8d',
					'8f832436-7eb7-4d36-bccb-7b00d2932f5d',
					'7a0da06b-a48b-464e-84d2-ebdce9842bc3',
					'3491aebd-42d6-44ce-a0da-c8b5ed2a0cfc',
					'58cbb2c4-c893-4e00-b35c-506b1a1eab85',
					'316fc423-a30e-4fa4-9562-07e1079feb24',
					'ab113466-3139-4223-ba61-264be9559a30',
					'ee467af0-c290-4da7-92e4-82ff7622cf7f',
				],
				excludedConcepts: [
					'e672e8fa-0cc2-47fb-8835-3f29c6d993b3',
					'f4673472-24d6-4b44-9e07-41f61a699366',
					'3a40a878-226e-4c06-8fb2-030e1f9d5b0a',
				],
				creativeAlignment: '',
				risk: 'low',
			},
		},
	},
};
