import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { sidebarHiddenAtom } from '../atoms/sidebarHiddenAtom';

const useHideSidebar = () => {
  const [hide, setHide] = useAtom(sidebarHiddenAtom);
  useEffect(() => {
    // When mounted, if our app is not selected switch to it
    if (hide !== true) {
      setHide(true);
    }
    return () => {
      // When unmounted, if our app is still selected switch to NONE
      if (hide === true) {
        setHide(false);
      }
    };
  }, [setHide, hide]);

  return useMemo(() => {
    return {
      hide,
      setHide,
    };
  }, [hide, setHide]);
};

export default useHideSidebar;
