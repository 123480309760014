import { NO_RISK } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { atom, Getter, Setter } from 'jotai';
import { atomWithHash } from 'jotai-location';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import {
  INCLUSION_LIST_FORM,
  INCLUSION_LIST_PREFAB,
} from '../constants/listTypes';
import contentAlignmentOptionsFormAtom, {
  contentAlignmentOptionsAtom,
} from './contentAlignmentAtom';
import targetAudienceOptionsFormAtom, { targetAudienceOptionsAtom } from './targetAudienceAtom';

// Populate this with the data that can be filled in by picking a prefab
export interface PrefabData {
  targetAudience: Record<string, unknown>;
  contentAlignment: Record<string, unknown>;
  includedConcepts: string[];
  excludedConcepts: string[];
  creativeAlignment: string;
  risk: string;
  languages: string[];
  threshold?: number;
}

const defaultPrefabData: PrefabData = {
  targetAudience: {},
  contentAlignment: {},
  includedConcepts: [],
  excludedConcepts: [],
  creativeAlignment: '',
  risk: NO_RISK,
  languages: [],
  threshold: undefined,
};

export interface KnownFormData extends Partial<PrefabData> {
  platform?: string | null;
  type?: string | null;
  exclusions?: string[];
  step?: number;
  concepts?: string[];
  kpiKeys?: string[];
  inclusionListType?: string;
  prefab?: string;
}

interface FormData extends KnownFormData {
  [x: string]: unknown;
}
const FORM_STATE_KEY = 'state';

const hashAtom = atomWithHash(FORM_STATE_KEY, {} as FormData, {
  serialize: (form) => {
    return compressToEncodedURIComponent(JSON.stringify(form));
  },
  deserialize: (currentEncodedString) => {
    const decodedJsonBlobAsString = decompressFromEncodedURIComponent(currentEncodedString || '');
    let decodedJsonData: FormData = {};
    try {
      if (decodedJsonBlobAsString) {
        decodedJsonData = JSON.parse(decodedJsonBlobAsString);
      }
    } catch (e) {
      console.error('Malformed state', e, decodedJsonBlobAsString);
    }
    return decodedJsonData;
  },
});

const formDataAtom = atom((get) => {
  return get(hashAtom);
});

formDataAtom.debugLabel = 'Form Window State Decoded';

const formParamsAtom = atom((get) => {
  const formData = get(formDataAtom);
  let step = Number.isNaN(formData.step) ? 1 : formData.step || 1;
  if (step < 1) {
    step = 1;
  }
  let nextStep = 1;
  if (formData.platform) {
    nextStep = 2;
    if (formData.kpiKeys) {
      nextStep = 3;
      if (formData.type) {
        nextStep = 4;
        if (formData.type === 'inclusion') {
          nextStep = 5;
          if (formData.inclusionListType) {
            if (formData.prefab || formData.risk) {
              nextStep = 6;
            }
          }
        } else if (formData.type === 'exclusion') {
          nextStep = 5;
          if (formData.risk) {
            nextStep = 6;
          }
        }
      }
    }
  }
  // If we don't have a parameter, force the form to go to the active step
  if (Number.isNaN(formData.step) && nextStep !== 1) {
    step = nextStep - 1;
  }
  if (step > nextStep) {
    step = Math.min(nextStep, step);
  }

  return {
    nextStep,
    targetAudience: formData.targetAudience || {},
    contentAlignment: formData.contentAlignment || {},
    ...formData,
    step,
  };
});

formParamsAtom.debugLabel = 'Form params';

const setEncodedLocation = (set: Setter, value: FormData) => {
  set(hashAtom, value);
};

const setFormKeyValue: <T>(get: Getter, set: Setter, key: string, value: T) => void = (
  get,
  set,
  key,
  value,
) => {
  if (value) {
    if ((key as keyof KnownFormData) === 'contentAlignment') {
      const options = get(contentAlignmentOptionsAtom);
      Object.keys(options).forEach((optKey) => {
        const data = options[optKey];
        const currentValue = (value as Record<string, unknown>)[optKey];
        if (!currentValue) {
          if (data.defaultValue) {
            (value as Record<string, unknown>)[optKey] = data.defaultValue;
          }
        }
      });
      set(contentAlignmentOptionsFormAtom, value as any);
    } else if ((key as keyof KnownFormData) === 'targetAudience') {
      const options = get(targetAudienceOptionsAtom);
      Object.keys(options).forEach((optKey) => {
        const data = options[optKey];
        const currentValue = (value as Record<string, unknown>)[optKey];
        if (!currentValue) {
          if (data.defaultValue) {
            (value as Record<string, unknown>)[optKey] = data.defaultValue;
          }
        }
      });
      set(targetAudienceOptionsFormAtom, value as any);
    }
  }
  const previousData = get(formDataAtom);
  if (value) {
    previousData[key] = value;
  } else {
    delete previousData[key];
  }
  setEncodedLocation(set, previousData);
};

const setFormConceptsAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'concepts';
  setFormKeyValue(get, set, key, value);
});

const setFormPlatformAtom = atom(null, (get, set, value: string) => {
  const key: keyof KnownFormData = 'platform';
  setFormKeyValue(get, set, key, value);
});

const setFormListAtom = atom(null, (get, set, value: string) => {
  const key: keyof KnownFormData = 'type';
  setFormKeyValue(get, set, key, value);
  const keysToClear: (keyof KnownFormData)[] = [
    'includedConcepts',
    'excludedConcepts',
    'inclusionListType',
    'contentAlignment',
    'creativeAlignment',
    'prefab',
    'risk',
    'targetAudience',
    'exclusions',
    'languages',
    'threshold',
  ];
  keysToClear.forEach((k) => {
    setFormKeyValue(get, set, k, undefined);
  });
});

const setFormRiskAtom = atom(null, (get, set, value: string) => {
  const key: keyof KnownFormData = 'risk';
  setFormKeyValue(get, set, key, value);
});

const setFormThresholdAtom = atom(null, (get, set, value?: number) => {
  const key: keyof KnownFormData = 'threshold';
  setFormKeyValue(get, set, key, value);
});

const setFormLanguagesAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'languages';
  setFormKeyValue(get, set, key, value);
});

const setFormPrefabAtom = atom(
  null,
  (get, set, value: string, data: PrefabData = defaultPrefabData) => {
    const key: keyof KnownFormData = 'prefab';
    const previousData = get(formDataAtom);

    if (data) {
      Object.keys(data).forEach((key) => {
        const formKey = key as keyof KnownFormData;
        setFormKeyValue(get, set, formKey, data[key as keyof PrefabData]);
      });
    }
    if (previousData.inclusionListType === INCLUSION_LIST_PREFAB && value) {
      const inclusionListTypeKey: keyof KnownFormData = 'inclusionListType';
      setFormKeyValue(get, set, inclusionListTypeKey, INCLUSION_LIST_FORM);
    }
    setFormKeyValue(get, set, key, value);
  },
);

const setFormIncludedConceptsAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'includedConcepts';
  setFormKeyValue(get, set, key, value);
});

const setFormExcludedConceptsAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'excludedConcepts';
  setFormKeyValue(get, set, key, value);
});

const setFormExclusionsAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'exclusions';
  setFormKeyValue(get, set, key, value);
});

const setFormInclusionListTypeAtom = atom(null, (get, set, value: string) => {
  const key: keyof KnownFormData = 'inclusionListType';
  if (value === INCLUSION_LIST_PREFAB) {
    const prefabkey: keyof KnownFormData = 'prefab';
    setFormKeyValue(get, set, prefabkey, undefined);
  }
  setFormKeyValue(get, set, key, value);
});

const setFormStepOverrideAtom = atom(null, (get, set, value: number) => {
  const key: keyof KnownFormData = 'step';
  setFormKeyValue(get, set, key, value);
});

const setFormKpiKeysAtom = atom(null, (get, set, value: string[]) => {
  const key: keyof KnownFormData = 'kpiKeys';
  setFormKeyValue(get, set, key, value);
});

const setFormCreativeAlignmentAtom = atom(null, (get, set, value: string) => {
  const key: keyof KnownFormData = 'creativeAlignment';
  setFormKeyValue(get, set, key, value);
});

const setFormTargetAudienceAtom = atom(null, (get, set, value: Record<string, any>) => {
  const key: keyof KnownFormData = 'targetAudience';
  setFormKeyValue(get, set, key, value);
});

const setFormContentAlignmentAtom = atom(null, (get, set, value: Record<string, any>) => {
  const key: keyof KnownFormData = 'contentAlignment';
  // const options = get(contentAlignmentOptionsAtom);
  // Object.keys(options).forEach((optKey) => {
  //   const data = options[optKey];
  //   const currentValue = value[key];
  //   if (!currentValue) {
  //     if (data.defaultValue) {
  //       value[key] = data.defaultValue;
  //     }
  //   }
  // });
  setFormKeyValue(get, set, key, value);
});

const privateAtoms = [
  setFormPlatformAtom,
  setFormStepOverrideAtom,
  setFormListAtom,
  setFormExclusionsAtom,
  setFormRiskAtom,
  setFormConceptsAtom,
  setFormKpiKeysAtom,
  setFormTargetAudienceAtom,
  setFormContentAlignmentAtom,
  setFormIncludedConceptsAtom,
  setFormExcludedConceptsAtom,
  setFormCreativeAlignmentAtom,
  setFormPrefabAtom,
  setFormInclusionListTypeAtom,
  setFormLanguagesAtom,
  setFormThresholdAtom,
];

privateAtoms.forEach((a) => {
  a.debugPrivate = true;
});

export {
  formParamsAtom,
  hashAtom,
  setFormConceptsAtom,
  setFormContentAlignmentAtom,
  setFormCreativeAlignmentAtom,
  setFormExcludedConceptsAtom,
  setFormExclusionsAtom,
  setFormIncludedConceptsAtom,
  setFormInclusionListTypeAtom,
  setFormKpiKeysAtom,
  setFormLanguagesAtom,
  setFormListAtom,
  setFormPlatformAtom,
  setFormPrefabAtom,
  setFormRiskAtom,
  setFormStepOverrideAtom,
  setFormTargetAudienceAtom,
  setFormThresholdAtom,
};
