import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { getConceptsList } from '../../../api/config/growth/v0';
import { getApiFn } from '../../../api/helper';
import { ProxyConcept } from '../../../types/proxyApi';

export const QUERY_KEY = 'concepts';

interface RequestParams {}

interface RequestResponse {
  concepts: ProxyConcept[];
}

type Response = RequestResponse;

const useConceptsList = () => {
  const path = getConceptsList();
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await getApiFn(path)
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results for the concepts list', { res });
          const conceptRecord: Record<string, ProxyConcept> = {};
          res.concepts.forEach((concept) => {
            conceptRecord[concept.id] = concept;
            conceptRecord[concept.id].filters = concept.category ? [concept.category] : [];
          });
          return conceptRecord;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results for the concepts list', {
            error,
          });
          throw new Error(error);
        });
    },
    // Only make the request once, and keep the results for the rest of our form creation
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export default useConceptsList;
