import { Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';
import { TEXT_GREY } from '../../../../gatsby-theme-zefr/theme';

export interface InformationalDescriptionProps extends TypographyProps {}

const Description = styled(Typography)(({ theme }) => {
  return {
    fontSize: theme.typography.h6.fontSize,
    color: TEXT_GREY,
    fontWeight: '400',
    lineHeight: theme.spacing(6),
  };
});

const InformationalDescription: React.FC<InformationalDescriptionProps> = ({ ...props }) => {
  return <Description {...props} />;
};

export default InformationalDescription;
