import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePageTitle from '../../../gatsby-theme-zefr/hooks/usePageTitle';
import DemoForm from '../../components/DemoForm';
import { DemoFormContextProvider } from '../../context/DemoFormContext';

interface DemoRouteProps extends RouteComponentProps {}

// This component holds all locations for our app that uses authenticated client-only routes
export const DemoRoute: React.FC<DemoRouteProps> = ({ ...props }) => {
  const { t } = useTranslation();
  usePageTitle(t(`growth.demo.title`));
  return (
    <DemoFormContextProvider {...props}>
      <DemoForm />
    </DemoFormContextProvider>
  );
};

export default DemoRoute;
