import { Add } from '@mui/icons-material';
import { ButtonBase, ButtonBaseProps, Chip, ChipProps, darken, styled } from '@mui/material';
import React from 'react';
import { BG_COLOR } from '../../gatsby-theme-zefr/theme';

const Wrapper = styled(ButtonBase)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.common.black}`,
    borderRadius: '10px',
    width: '100%',
    padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    backgroundColor: BG_COLOR,
    '& .MuiTouchRipple-root': {
      color: theme.palette.primary.main,
    },
    '&:hover, &.Mui-focusVisible': {
      cursor: 'pointer',
      backgroundColor: darken(BG_COLOR, 0.05),
      '& .MuiChip-root': {
        backgroundColor: darken(theme.palette.common.white, 0.02),
      },
    },
    '& .MuiChip-root': {
      backgroundColor: theme.palette.common.white,
      fontWeight: 'bold',
      '&, & *': {
        color: theme.palette.primary.main,
      },
    },
  };
});

export interface ClickablePlaceholderBoxProps extends Omit<ButtonBaseProps, 'children'> {
  label?: React.ReactNode;
  chipProps?: Partial<Omit<ChipProps, 'label'>>;
}

const ClickablePlaceholderBox: React.FC<ClickablePlaceholderBoxProps> = ({
  label = 'Add Items',
  focusRipple = true,
  chipProps,
  className = '',
  ...props
}) => {
  return (
    <Wrapper
      className={`placeholder-box${className ? ` ${className}` : ''}`}
      {...props}
      {...{ focusRipple }}
    >
      <Chip icon={<Add />} {...chipProps} label={label} />
    </Wrapper>
  );
};

export default ClickablePlaceholderBox;
