import { atom } from 'jotai';
import { cloneDeep } from 'lodash';
import { BRAND_BLUE, BRAND_GREEN, BRAND_PURPLE } from '../../gatsby-theme-zefr/theme';
import { ChipSwapProps } from '../components/ChipSwapper/ChipSwapper';

const chipDataState: ChipSwapProps[] = [
  { key: 'test', label: 'Test', filters: ['contextual'] },
  {
    key: 'test1',
    label: 'A Super Long Title',
    filters: ['efficiency', 'contextual', 'scale'],
  },
  { key: 'test2', label: 'A title', filters: ['scale'] },
  {
    key: 'test3',
    label: 'A really long title with onebigwordthatislong',
    filters: ['efficiency', 'contextual'],
  },
  {
    key: 'test5',
    filters: ['contextual'],
    label: 'A really long title with not just onebigwordthatislong but twobigwordsthatarelong',
  },
  { key: 'test4', label: 'Test5', filters: ['efficiency'] },
];

const sortAlphabeticalAsc = 'a-z';
const sortAlphabeticalDesc = 'z-a';

export type ChipSort = typeof sortAlphabeticalAsc | typeof sortAlphabeticalDesc;
type ChipSortMethod = (a: ChipSwapProps, b: ChipSwapProps) => number;
export const SortMethods: Record<string, ChipSortMethod | undefined> = {
  [sortAlphabeticalAsc]: (a, b) => {
    return (a.label?.toString() || '').localeCompare(b.label?.toString() || '');
  },
  [sortAlphabeticalDesc]: (a, b) => {
    return (b.label?.toString() || '').localeCompare(a.label?.toString() || '');
  },
};

export const SortTitles: Record<string, string> = {
  [sortAlphabeticalAsc]: 'A-Z',
  [sortAlphabeticalDesc]: 'Z-A',
};

const chipSortState: string = sortAlphabeticalAsc;
export interface ChipFilter {
  title: string;
  description: string;
  active?: boolean;
  color?: string;
}
const chipFilterState: Record<string, ChipFilter> = {
  Scale: {
    title: 'Scale',
    description:
      'Channels or videos with a high overall volume of videos or views included to ensure broad audience reach',
    color: BRAND_BLUE,
    active: true,
  },
  Efficiency: {
    title: 'Efficiency',
    description:
      'Channels or videos that provide strong performance with high view-through and click-through rates and drive down overall cost metrics',
    color: BRAND_PURPLE,
    active: true,
  },
  Contextual: {
    title: 'Contextual',
    description:
      'Channels or videos of a specific topic or theme that provide a brand suitable content alignment',
    color: BRAND_GREEN,
    active: true,
  },
};

const chipFilterAtom = atom(chipFilterState);
chipFilterAtom.debugLabel = 'Chip Filters';

const chipActiveFiltersAtom = atom((get) => {
  const newLocal = get(chipFilterAtom);
  return Object.keys(newLocal).filter((value) => {
    const data = newLocal[value];
    return data.active === true;
  });
});
chipActiveFiltersAtom.debugLabel = 'Active Chip Filters';

const chipSortAtom = atom(chipSortState);
chipSortAtom.debugLabel = 'Chip Sort';

const chipDataAtom = atom(chipDataState);
chipDataAtom.debugLabel = 'Chip Data';

const chipStatusColorsAtom = atom((get) => {
  const newLocal = get(chipFilterAtom);
  const colors: Record<string, string> = {};
  Object.keys(newLocal).forEach((v) => {
    const data = newLocal[v as keyof typeof newLocal];
    if (data.color) {
      colors[v] = data.color;
    }
  });
  return colors;
});
chipStatusColorsAtom.debugLabel = 'Chip Colors';

const sortedChipDataAtom = atom((get) => {
  const currentChipData = get(chipDataAtom);
  const sortMethodKey = get(chipSortAtom);
  const sortMethodFunc = SortMethods[sortMethodKey];
  const cloneData = cloneDeep(currentChipData);
  if (sortMethodFunc) {
    cloneData.sort(sortMethodFunc);
  }
  return cloneData;
});

const selectedChipCountAtom = atom((get) => {
  return get(chipDataAtom).filter((data) => data.active).length;
});
const filterableChipCountAtom = atom((get) => {
  return get(chipDataAtom).filter((data) => (data.filters || []).length > 0).length;
});

const selectedChipsAtom = atom((get) => {
  return get(chipDataAtom).filter((data) => data.active);
});

const clearSelectedChipsAtom = atom(null, (get, set) => {
  set(chipDataAtom, (oldData) => {
    const newData = cloneDeep(oldData);
    oldData.forEach((item, index) => {
      newData[index].active = false;
    });
    return newData;
  });
});
clearSelectedChipsAtom.debugPrivate = true;

const addAllChipsAtom = atom(null, (get, set) => {
  set(chipDataAtom, (oldData) => {
    const newData = cloneDeep(oldData);
    oldData.forEach((item, index) => {
      newData[index].active = true;
    });
    return newData;
  });
});
addAllChipsAtom.debugPrivate = true;

export {
  addAllChipsAtom,
  chipActiveFiltersAtom,
  chipDataAtom,
  chipFilterAtom,
  chipSortAtom,
  chipStatusColorsAtom,
  clearSelectedChipsAtom,
  filterableChipCountAtom,
  selectedChipCountAtom,
  selectedChipsAtom,
  sortedChipDataAtom,
};
