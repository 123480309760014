import { Grid2Props } from '@mui/material';
import getViewsAbbreviated from '@zefr/style-guide/dist/ui/utils/strings/getViewsAbbreviated';
import React from 'react';
import TextGridItem from './TextGridItem';

export interface AverageDailyViewsGridItemProps extends Grid2Props {
  value?: number | React.ReactNode;
}

const AverageDailyViewsGridItem: React.FC<AverageDailyViewsGridItemProps> = ({
  value,
  ...props
}) => {
  return (
    <TextGridItem
      {...props}
      value={
        typeof value === 'number' || typeof value === 'undefined'
          ? `${getViewsAbbreviated(value, true)}`
          : value
      }
      label={'Total Avg. Daily Views'}
      tooltipTitle={`Total views per day available, estimated based on selected and excluded concepts and campaign details`}
    />
  );
};

export default AverageDailyViewsGridItem;
