import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    LinearProgress,
    Typography
} from '@mui/material';
import React, { SyntheticEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import { ProxyGeminiProModelResponse } from '../../types/proxyApi';

export interface DemoRfpDialogProps extends DialogProps {
    data: ProxyGeminiProModelResponse | null;
    loading: boolean;
    onClose: (event: SyntheticEvent) => void;
    onContinue: (event: SyntheticEvent) => void;
    validResponse: boolean;
}

const DemoRfpDialog: React.FC<DemoRfpDialogProps> = (props) => {
    const {
        data,
        loading,
        onClose,
        onContinue,
        open,
        validResponse,
    } = props;

    return (
        <Dialog
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth="md"
            disableAutoFocus
            sx={{m: 1}}
        >
            {/* Header */}
            <Box
                position="relative"
                px={12}
                py={8}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                gap={8}
            >
                <Typography variant="h5" color="black" fontWeight="800" textAlign="center">
                    Peformance Summary Preview
                </Typography>
                <Typography
                    variant="h6"
                    sx={(theme) => ({ color: theme.palette.secondary.main })}
                    fontWeight="600"
                    textAlign="center"
                >
                    {(validResponse && !loading)
                    ? 'Below is a summary of your performance based on your input. Do you want to continue?'
                    : loading
                        ? 'Your summary preview is loading. This could take a moment...'
                        : 'We were unable to make a summary based on your input. Please edit your prompt and try again.'}
                </Typography>
            </Box>
            {/* Body */}
            <DialogContent
                sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    borderRadius: '15px',
                    border: '1px solid black',
                    margin: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
                })}
                >
                {loading ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box width="100%">
                            <LinearProgress variant="indeterminate" />
                        </Box>
                    </Box>
                ) : (
                    <>{data && <ReactMarkdown children={data.summary} />}</>
                )}
            </DialogContent>
            {/* Footer */}
            <DialogActions
                sx={(theme) => ({
                    justifyContent: 'space-evenly',
                    '& > .MuiButton-root': {
                    minWidth: '125px',
                    letterSpacing: '0px',
                    fontWeight: '700',
                    [theme.breakpoints.up('md')]: {
                        minWidth: '150px',
                    },
                    },
                })}
                >
                <Button
                    size="large"
                    variant="contained"
                    onClick={onClose}
                    color="info"
                    className="form-button-cancel"
                >
                    No, Refine
                </Button>
                <Button
                    disabled={loading || !validResponse}
                    size="large"
                    variant="contained"
                    onClick={onContinue}
                    color="primary"
                >
                    Yes, Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DemoRfpDialog;
