import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { getGeminiProModelPath } from '../../../api/config/growth/v0';
import { postApiFn } from '../../../api/helper';
import { ProxyGeminiProModelRequest, ProxyGeminiProModelResponse } from '../../../types/proxyApi';

export const QUERY_KEY = 'gemini-pro-model';

interface RequestParams extends Partial<ProxyGeminiProModelRequest> {
  rfp: string;
  enabled?: boolean;
  [x: string]: unknown;
}

type Response = ProxyGeminiProModelResponse;

const useGeminiProModelQuery = ({ rfp, enabled }: RequestParams) => {
  const path = getGeminiProModelPath();
  return useQuery({
    enabled: Boolean(rfp) && enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    queryKey: [QUERY_KEY, rfp],
    queryFn: async ({ signal }) => {
      const params: ProxyGeminiProModelRequest = { rfp };
      return await postApiFn(path, params, { signal })
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results from Gemini Pro Model service', {
            params,
            res,
          });
          return res;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results from Gemini Pro Model service', {
            params,
            error,
          });

          throw new Error(error);
        });
    },
  });
};

export default useGeminiProModelQuery;
