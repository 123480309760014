import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { TEXT_GREY } from '../../../gatsby-theme-zefr/theme';
import {
  demoDialogContentNodeAtom,
  demoDialogOpenAtom,
  demoDialogPropsAtom,
} from '../../atoms/demoDialogAtom';
import InternalIcon from '../../components/InternalIcon';
import { LearnMoreType } from '../../types/launchDarkly';

interface DialogContentProps {
  details?: LearnMoreType;
}

const HeaderContent: React.FC<DialogContentProps> = ({ details }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Stack
      position={'relative'}
      display={'flex'}
      gap={2}
      sx={(theme) => ({
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing(4)} ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
        [theme.breakpoints.up('md')]: {
          padding: `${theme.spacing(8)} ${theme.spacing(12)}`,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        },
        '& .MuiSvgIcon-root': { height: 'unset', fontSize: '5.5rem' },
      })}
      divider={
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          width={matches ? undefined : '100%'}
          boxSizing={'border-box'}
        >
          <Divider
            sx={(theme) => ({
              borderColor: theme.palette.secondary.main,
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: 'unset',
              },
            })}
            orientation={matches ? 'vertical' : 'horizontal'}
          />
        </Box>
      }
    >
      <InternalIcon iconName={details?.icon} />
      {/* <IconButton sx={{ left: '25px', position: 'absolute' }} onClick={handleClose}>
      <Close />
    </IconButton> */}
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        boxSizing={'border-box'}
      >
        <Typography
          variant="h4"
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
          fontWeight={'700'}
          textAlign={'center'}
        >
          {details?.title ? details.title : '...'}
        </Typography>
      </Box>
    </Stack>
  );
};

const BodyContent: React.FC<DialogContentProps> = ({ details }) => {
  return (
    <DialogContent>
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        gap={1}
        sx={(theme) => ({
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(2)} ${theme.spacing(12)}`,
          },
          '& *': {
            color: TEXT_GREY,
          },
        })}
        className="markdown"
      >
        {details?.body ? <ReactMarkdown>{details.body}</ReactMarkdown> : <CircularProgress />}
      </Box>
    </DialogContent>
  );
};

const FooterContent = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  return (
    <DialogActions sx={{ justifyContent: 'space-evenly' }}>
      <Button
        sx={{ width: '250px' }}
        size={'large'}
        variant="contained"
        onClick={handleClose}
        color="primary"
      >
        Got It!
      </Button>
    </DialogActions>
  );
};

const usePlatformLearnMoreDialog = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const setDemoDialogProps = useSetAtom(demoDialogPropsAtom);
  const handleClick = useCallback(
    ({ details }: DialogContentProps) => {
      setDemoDialogContent(
        <>
          <HeaderContent details={details} />
          <BodyContent details={details} />
          <FooterContent />
        </>,
      );
      setDemoDialog(true);
    },
    [setDemoDialog, setDemoDialogContent, setDemoDialogProps],
  );
  return handleClick;
};

export default usePlatformLearnMoreDialog;
