import { Box, BoxProps } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import TargetAudienceMultiSelectForm from './TargetAudienceMultiSelectForm';
import TargetAudienceSelectForm from './TargetAudienceSelectForm';
import TargetAudienceTextForm from './TargetAudienceTextForm';
import { filteredTargetAudienceOptionsAtom } from './atom';

export interface TargetAudienceFormProps extends BoxProps {}

const TargetAudienceForm: React.FC<TargetAudienceFormProps> = ({
  display = 'flex',
  flexDirection = 'column',
  width = '100%',
  gap = 1,
  ...props
}) => {
  const targetAudienceOptions = useAtomValue(filteredTargetAudienceOptionsAtom);

  const multiSelectElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['multiselect'] || {}).map((key) => {
      return <TargetAudienceMultiSelectForm id={key} key={key} />;
    });
  }, [targetAudienceOptions]);

  const textElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['text'] || {}).map((key) => {
      return <TargetAudienceTextForm key={key} id={key} />;
    });
  }, [targetAudienceOptions]);

  const selectElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['select'] || {}).map((key) => {
      return <TargetAudienceSelectForm key={key} id={key} />;
    });
  }, [targetAudienceOptions]);

  return (
    <Box {...props} {...{ width, display, flexDirection, gap }}>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} gap={1}>
        {multiSelectElements}
      </Box>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} gap={1}>
        {selectElements}
      </Box>
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {textElements}
      </Box>
    </Box>
  );
};

export default TargetAudienceForm;
