import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

// Required interface to allow passing path property
const PageNotFound: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    navigate('/404', { replace: true });
  }, []);
  return null;
};

export default PageNotFound;
