import { LinearProgress, LinearProgressProps } from '@mui/material';
import React from 'react';

export interface FakeLinearProgressBarProps {
  linearProgressProps?: Partial<LinearProgressProps>;
}

const FakeLinearProgressBar: React.FC<FakeLinearProgressBarProps> = ({
  linearProgressProps = {},
  ...props
}) => {
  const { sx: linearProgressSx = {} } = linearProgressProps;
  return (
    <>
      <span style={{ opacity: 0 }}>...</span>
      <LinearProgress
        sx={{
          position: 'absolute',
          bottom: '7px',
          width: '100%',
          ...linearProgressSx,
        }}
        {...linearProgressProps}
      />
    </>
  );
};

export default FakeLinearProgressBar;
