import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import { DIVIDER_COLORS } from '../../components/DemoComponents/Form/components';
import InternalIcon from '../../components/InternalIcon';
import { SalesDemoPlatform } from '../../types/launchDarkly';

export interface LearnMoreDialogProps {
  data: SalesDemoPlatform;
}

const LearnMoreDialog: React.FC<LearnMoreDialogProps> = ({ data }) => {
  const { learnMore: details, logoIcon, labelIcon } = data;
  const dividerStartColor = useMemo(() => {
    const requestedColor = details?.divider?.color;
    if (requestedColor && DIVIDER_COLORS[requestedColor]) {
      return DIVIDER_COLORS[requestedColor].startColor;
    }
    const requestedStartColor = details?.divider?.startColor;
    if (requestedStartColor) {
      return requestedStartColor;
    }
    return undefined;
  }, [details?.divider]);

  const dividerEndColor = useMemo(() => {
    const requestedColor = details?.divider?.color;
    if (requestedColor && DIVIDER_COLORS[requestedColor]) {
      return DIVIDER_COLORS[requestedColor].endColor;
    }
    const requestedEndColor = details?.divider?.endColor;
    if (requestedEndColor) {
      return requestedEndColor;
    }
    return undefined;
  }, [details?.divider]);

  return (
    <Box flex={1}>
      <Stack
        position={'relative'}
        px={2}
        py={4}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        flexDirection={'row'}
        gap={2}
        sx={{ '& .MuiSvgIcon-root': { height: 'unset', fontSize: '3.5rem' } }}
        divider={
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            boxSizing={'border-box'}
          >
            <Divider
              sx={(theme) => ({
                borderColor: theme.palette.secondary.main,
              })}
              orientation={'vertical'}
            />
          </Box>
        }
      >
        <InternalIcon iconName={logoIcon || labelIcon} />
        {/* <IconButton sx={{ left: '25px', position: 'absolute' }} onClick={handleClose}>
      <Close />
    </IconButton> */}
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          boxSizing={'border-box'}
        >
          <Typography
            variant="h4"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
            fontWeight={'700'}
            textAlign={'center'}
          >
            {details?.title ? details.title : '...'}
          </Typography>
        </Box>
      </Stack>
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        gap={1}
        px={2}
        className="markdown"
      >
        {details?.body ? <Markdown>{details.body}</Markdown> : <CircularProgress />}
      </Box>
    </Box>
  );
};

export default LearnMoreDialog;
