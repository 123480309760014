import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import useApp from '../../gatsby-theme-zefr/hooks/useApp';
import useHideSidebar from '../../gatsby-theme-zefr/hooks/useHideSidebar';
import { SITE_APP_DEMO } from '../../gatsby-theme-zefr/types/shared';
import DemoPageRouter from './DemoPage/router';

// This component holds all locations for our app that uses authenticated client-only routes
export const DemoApp: React.FC<RouteComponentProps> = ({ ...props }) => {
  useApp(SITE_APP_DEMO);
  useHideSidebar();

  return (
    <Router className="h-full" {...props}>
      <DemoPageRouter path="/*" />
    </Router>
  );
};

export default DemoApp;
