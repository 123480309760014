import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import React from 'react';
import { getEnvVar } from '../../../src/helpers';
import { LOGIN } from '../constants/routes';

interface AuthProviderProps {
  children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name,react/prop-types
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const CLIENT_ID = getEnvVar(
    'GATSBY_AUTH0_CLIENT_ID',
    undefined,
    process.env.GATSBY_AUTH0_CLIENT_ID,
  ) as string;
  const DOMAIN = getEnvVar(
    'GATSBY_AUTH0_DOMAIN',
    undefined,
    process.env.GATSBY_AUTH0_DOMAIN,
  ) as string;
  const AUDIENCE = getEnvVar(
    'GATSBY_AUTH0_AUDIENCE',
    undefined,
    process.env.GATSBY_AUTH0_AUDIENCE,
  ) as string;
  const SCOPE = getEnvVar(
    'GATSBY_AUTH0_SCOPE',
    undefined,
    process.env.GATSBY_AUTH0_SCOPE,
  ) as string;
  const redirectUri =
    typeof window !== 'undefined' ? window.location.origin : 'http://localhost:8000';
  return (
    <Auth0Provider
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      domain={DOMAIN}
      clientId={CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${redirectUri}${LOGIN}`,
        audience: AUDIENCE,
        scope: SCOPE,
      }}
      onRedirectCallback={(appState) => {
        if (appState?.returnTo) {
          navigate(appState.returnTo!, { replace: true });
        }
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
