import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { getExclusionChannelsList } from '../../../api/config/growth/v0';
import { postApiFn } from '../../../api/helper';
import { ProxyChannelsListResponse } from '../../../types/proxyApi';

export const QUERY_KEY = 'get-exclusion-channels';

interface RequestParams {
  bsp_concepts?: string[];
  limit?: number;
  page?: number;
  maximum_risk_level?: number;
  sort_field?: string;
  sort_dir?: 'asc' | 'desc';
  enabled?: boolean;
}

type Response = ProxyChannelsListResponse;

const useExclusionChannelsList = ({
  page = 1,
  limit = 1000, // 10k is server default if no value provided
  bsp_concepts = [],
  sort_field,
  sort_dir,
  enabled = false,
  maximum_risk_level = 3,
  ...params
}: RequestParams) => {
  const path = getExclusionChannelsList();
  const safePage = Math.max(0, page);
  const offset = limit * safePage;
  return useQuery({
    queryKey: [
      QUERY_KEY,
      `limit=${limit}`,
      `offset=${offset}`,
      `bsp_concepts=${bsp_concepts.join('')}`,
      `sort_dir=${sort_dir}`,
      `sort_field=${sort_field}`,
      `risk=${maximum_risk_level}`,
    ],
    queryFn: async () => {
      return await postApiFn(path, {
        ...params,
        bsp_concepts,
        sort_field,
        sort_dir,
        limit,
        offset,
        maximum_risk_level: Math.min(Math.max(0, maximum_risk_level), 3),
      })
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results for the exclusion channels list', { res });
          return res;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results for the exclusion channels list', {
            error,
          });
          throw new Error(error);
        });
    },
    // Only make the request once, and keep the results for the rest of our form creation
    retry: 0,
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export default useExclusionChannelsList;
