import { getEnvVar } from '../../../../../src/helpers';

export const API_PATH =
  process.env.GATSBY_GROWTH_SERVER_PATH || 'https://growth-hacker-relay.stage.zefr.com/api';

export const BRAND_API_PATH =
  process.env.GATSBY_BRAND_SERVER_PATH || 'https://api.stage.zefr.com/brand-capture-auth0';

export const MODELS_API_PATH =
  process.env.GATSBY_MODELS_SERVER_PATH || 'https://models.stage.zefr.com';
export const ISTIO_API_PATH =
  process.env.GATSBY_ISTIO_SERVER_PATH || 'https://istio.stage.zefr.com';

const GET_API_PATH = () => {
  return getEnvVar(
    'GATSBY_GROWTH_SERVER_PATH',
    undefined,
    process.env.GATSBY_GROWTH_SERVER_PATH,
  ) as string;
};

const GET_BRAND_API_PATH = () => {
  return getEnvVar(
    'GATSBY_BRAND_SERVER_PATH',
    undefined,
    'https://api.stage.zefr.com/brand-capture-auth0',
  ) as string;
};

export const getLanguagesList = () => {
  const path = `${GET_API_PATH()}/v1/languages/list`;
  return path;
};

export const getConceptsList = () => {
  const path = `${GET_API_PATH()}/v1/concepts/list`;
  return path;
};

export const getChannelsList = () => {
  const path = `${GET_API_PATH()}/v1/channels/list`;
  return path;
};

export const getChannelIdsImages = () => {
  const path = `${GET_BRAND_API_PATH()}/youtube/metadata/channels`;
  return path;
};

export const getExclusionChannelsList = () => {
  const path = `${GET_API_PATH()}/v1/channels/exclusion/list`;
  return path;
};

export const getInclusionChannelsList = () => {
  const path = `${GET_API_PATH()}/v1/channels/inclusion/list`;
  return path;
};

export const getImageVerifaiResultsPath = () => {
  const path = `${GET_API_PATH()}/v1/data-science/image-verifai`;
  return path;
};

export const getGeminiProModelPath = () => {
  const path = `${GET_API_PATH()}/v1/rfp-prompt`;
  return path;
};

export const getTrendSummarizationResultsPath = () => {
  const path = `${GET_API_PATH()}/v1/trends/generate`;
  return path;
};

export const getCVModelPredictionResultsPath = () => {
  const path = `${MODELS_API_PATH}/seldon/models/seldon-onnx-combined/api/v1.0/predictions`;
  return path;
};

export const getRegexPredictionsResultsPath = () => {
  const path = `${MODELS_API_PATH}/seldon/models/seldon-regexdi-service/api/v1.0/predictions`;
  return path;
};

export const getNLPPredictionsResultsPath = (concept: string) => {
  const path = `${ISTIO_API_PATH}/apr-nodes/roberta-fast-apr-node-${concept}-no-proxy/resolve/predictions`;
  return path;
};

export const getMisinformationResultsPath = (fakerank_model: string) => {
  const path = `${MODELS_API_PATH}/seldon/models/${fakerank_model}/api/v1.0/predictions`;
  return path;
};

export const getSuitabilityLivePath = (concept: string) => {
  const path = `${ISTIO_API_PATH}/apr-nodes/roberta-fast-apr-node-${concept}-no-proxy/resolve/predictions`;
  return path;
};
