import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  ChipProps,
  Divider,
  Grid,
  GridProps,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import {
  SortTitles,
  addAllChipsAtom,
  chipActiveFiltersAtom,
  chipDataAtom,
  chipFilterAtom,
  chipSortAtom,
  chipStatusColorsAtom,
  clearSelectedChipsAtom,
  filterableChipCountAtom,
  selectedChipCountAtom,
  sortedChipDataAtom,
} from '../../atoms/chipAtom';
import ChipDotsOverview from './ChipDotsOverview';
import SwapChip from './SwapChip';
import {
  FilterFormControlLabel,
  ScrollablePaper,
  SelectFormControl,
  TITLE_HEIGHT,
} from './components';

export interface ChipSwapProps extends Omit<ChipProps, 'key' | 'icon' | 'deleteIcon' | 'onDelete'> {
  key: string;
  active?: boolean;
  filters?: string[];
}

type OnChipClickFunc = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

export interface ChipSwapperProps extends GridProps {
  leftGridProps?: Partial<GridProps>;
  rightGridProps?: Partial<GridProps>;
  leftBoxProps?: Partial<BoxProps>;
  rightBoxProps?: Partial<BoxProps>;
  leftTitleChild?: React.ReactNode;
  rightTitleChild?: React.ReactNode;
  onChipClick?: OnChipClickFunc;
  isExclusion?: boolean;
}

const makeChips = (
  chips: ChipSwapProps[],
  onChipClick: OnChipClickFunc = () => {},
  showActive: boolean = false,
) => {
  return chips.map(({ key, ...item }) => {
    return (
      <SwapChip
        {...item}
        key={key}
        id={`${showActive ? 'active' : 'inactive'}-${key}`}
        data-id={key}
        showActive={showActive}
        onClick={onChipClick}
      />
    );
  });
};

const ADDITIONAL_OFFSET = 5;
const ChipSwapper: React.FC<ChipSwapperProps> = ({
  display = 'flex',
  flexDirection = 'row',
  flexWrap = 'wrap',
  spacing = 2,
  boxSizing = 'border-box',
  rightGridProps,
  leftGridProps,
  leftBoxProps,
  rightBoxProps,
  leftTitleChild,
  rightTitleChild,
  className = '',
  onChipClick = () => {},
  isExclusion,
  ...props
}) => {
  const setChips = useSetAtom(chipDataAtom);
  const sortedChips = useAtomValue(sortedChipDataAtom);
  const selectedChipCount = useAtomValue(selectedChipCountAtom);
  const [chipSort, setChipSort] = useAtom(chipSortAtom);
  const [chipFilter, setChipFilter] = useAtom(chipFilterAtom);
  const chipActiveFilters = useAtomValue(chipActiveFiltersAtom);
  const chipColors = useAtomValue(chipStatusColorsAtom);
  const clearSelectedChips = useSetAtom(clearSelectedChipsAtom);
  const addAllSelectedChips = useSetAtom(addAllChipsAtom);

  const filteredChipCount = useAtomValue(filterableChipCountAtom);

  const handleChangeSort = useCallback(
    (event: SelectChangeEvent) => {
      setChipSort(event.target.value);
    },
    [setChipSort],
  );

  const handleChipClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const key = event.currentTarget.getAttribute('data-id');
      const active = event.currentTarget.getAttribute('data-active') === 'true';
      setChips((oldChips) => {
        const existingChipIndex = oldChips.findIndex((chip) => {
          return chip.key === key;
        });
        const newChips = [...oldChips];
        newChips[existingChipIndex].active = !active;
        return newChips;
      });
      setTimeout(() => {
        const parentElement = document.getElementById(`${active ? 'left' : 'right'}-scroll-box`);
        const activeElement = document.getElementById(
          `outer-${active ? 'inactive' : 'active'}-${key}`,
        );
        if (activeElement && parentElement) {
          parentElement.scroll({
            behavior: 'smooth',
            top: activeElement.offsetTop - parentElement.offsetTop - ADDITIONAL_OFFSET,
          });
        }
      }, 250);
    },
    [setChips],
  );

  const leftChips = useMemo(() => {
    return makeChips(sortedChips, handleChipClick, false);
  }, [sortedChips, handleChipClick]);
  const rightChips = useMemo(() => {
    return makeChips(sortedChips, handleChipClick, true);
  }, [sortedChips, handleChipClick]);

  const chipFilterComponents = useMemo(() => {
    return Object.keys(chipFilter).map((value) => {
      const data = chipFilter[value];
      return (
        <FilterFormControlLabel
          color={data.color}
          key={value}
          control={
            <Checkbox
              onClick={(e) => {
                setChipFilter((oldState) => {
                  oldState[value].active = !Boolean(oldState[value].active);
                  return { ...oldState };
                });
              }}
              checked={Boolean(data.active)}
            />
          }
          label={data.title}
        />
      );
    });
  }, [chipFilter, setChipFilter, chipActiveFilters]);
  return (
    <Box
      className="chip-swapper-container"
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      width={'100%'}
    >
      {filteredChipCount > 0 ? (
        <>
          <Box className="chip-swapper-filter-container chip-swapper-filter">
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              width={'100%'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              className="chip-swapper-filter-header chip-swapper-filter"
            >
              <ChipDotsOverview className="chip-swapper-filter-dot-overview chip-swapper-filter">
                {Object.keys(chipColors).map((k) => {
                  const backgroundColor = chipColors[k];
                  return (
                    <Box
                      className="chip-swapper-filter-dot chip-swapper-filter"
                      key={k}
                      sx={{ backgroundColor }}
                    ></Box>
                  );
                })}
              </ChipDotsOverview>
              <Typography
                className="chip-swapper-filter-header-text chip-swapper-filter"
                variant="h6"
                fontWeight={'bold'}
                color="black"
              >
                Filter Concepts
              </Typography>
            </Box>
            <Box className="chip-swapper-filter-body chip-swapper-filter">
              {chipFilterComponents}
            </Box>
          </Box>
          <Divider className="chip-swapper-filter-divider" />
        </>
      ) : null}
      <Grid
        container
        className={`chip-swapper ${className ? ` ${className}` : ''}`}
        {...{ display, flexDirection, spacing, flexWrap, boxSizing }}
        {...props}
      >
        <Grid
          className="chip-swapper-left-container chip-swapper-container"
          alignSelf={'flex-end'}
          {...leftGridProps}
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Box
            className="chip-swapper-left-container-wrapper chip-swapper-container"
            {...leftBoxProps}
            display="flex"
            flexDirection={'column'}
            gap={1}
          >
            {leftTitleChild || (
              <Box display="flex" className="chip-swapper-left-container-title-bar">
                <Box
                  className="chip-swapper-left-container-title"
                  position={'relative'}
                  width={'100%'}
                  height={TITLE_HEIGHT}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Typography
                    className="chip-swapper-left-container-title-text"
                    color="black"
                    variant="h6"
                    fontWeight={'bold'}
                  >
                    Sort By:
                  </Typography>
                  <SelectFormControl className="chip-swapper-left-container-select-form-control">
                    <Select
                      className="chip-swapper-left-container-select"
                      value={chipSort || ''}
                      onChange={handleChangeSort}
                    >
                      {Object.keys(SortTitles).map((value) => {
                        return (
                          <MenuItem
                            className="chip-swapper-left-container-select-item select-item"
                            key={value}
                            value={value}
                          >
                            {SortTitles[value]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </SelectFormControl>
                </Box>
                <Box marginLeft={'auto'}>
                  <Button
                    size="large"
                    disabled={selectedChipCount === sortedChips.length}
                    variant="text"
                    onClick={() => {
                      addAllSelectedChips();
                    }}
                    sx={{ textWrap: 'nowrap' }}
                  >
                    Add All
                  </Button>
                </Box>
              </Box>
            )}
            <ScrollablePaper
              className="chip-swapper-left-container-scrollable scrollable chip-swapper-container"
              variant="outlined"
              id="left-scroll-box"
            >
              {leftChips}
            </ScrollablePaper>
          </Box>
        </Grid>
        <Grid
          className="chip-swapper-right-container chip-swapper-container"
          alignSelf={'flex-end'}
          {...rightGridProps}
          item
          xs={12}
          sm={6}
          md={6}
        >
          <Box {...rightBoxProps} display="flex" flexDirection={'column'} gap={1} width={'100%'}>
            {rightTitleChild || (
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                height={TITLE_HEIGHT}
                width={'100%'}
              >
                <Typography color="black" variant="h6" fontWeight={'bold'} noWrap={true}>
                  {isExclusion ? 'Add Concepts for Exclusion' : 'Add Concepts for Inclusion'}
                </Typography>
                <Box marginLeft={'auto'}>
                  <Button
                    size="large"
                    disabled={selectedChipCount === 0}
                    variant="text"
                    onClick={() => {
                      clearSelectedChips();
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            )}
            <ScrollablePaper
              className="chip-swapper-right-container-scrollable scrollable chip-swapper-container"
              variant="outlined"
              id="right-scroll-box"
            >
              {rightChips}
            </ScrollablePaper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChipSwapper;
