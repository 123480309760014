import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import React from 'react';

const ZefrTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    backgroundColor: theme.palette.info.contrastText,
    boxShadow: `0px 4px 30px 0px rgba(1, 162, 255, 0.50)`,
    fontFamiy: theme.typography.fontFamily,
  },
}));

export default ZefrTooltip;
