import { Button, ButtonProps, Typography } from '@mui/material';
import { NO_RISK } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { isEngineerAtom } from '../../../../gatsby-plugin-zefr-auth0/atoms/userAtom';
import Link from '../../../../gatsby-theme-zefr/components/Link';
import { PrefabData } from '../../../atoms/encodedFormAtoms';
import { useDemoForm } from '../../../context/DemoFormContext';

export interface CreatePrefabButtonProps extends ButtonProps {}

const CreatePrefabButton: React.FC<CreatePrefabButtonProps> = ({ children, ...props }) => {
  const {
    targetAudience,
    contentAlignment,
    includedConcepts,
    excludedConcepts,
    creativeAlignment,
    risk,
    languages,
  } = useDemoForm();
  const isEngineer = useAtomValue(isEngineerAtom);
  const PREFAB_DATA: PrefabData = {
    targetAudience: targetAudience || {},
    contentAlignment: contentAlignment || {},
    includedConcepts: includedConcepts || [],
    excludedConcepts: excludedConcepts || [],
    creativeAlignment: creativeAlignment || '',
    languages: languages || [],
    risk: risk || NO_RISK,
  };

  const toUrl = useMemo(() => {
    return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(PREFAB_DATA))}`;
  }, [PREFAB_DATA]);
  if (!isEngineer) {
    return null;
  }
  return (
    <Button LinkComponent={Link} {...{ download: 'prefab.json', to: toUrl }} {...props}>
      {children || <Typography>Save as Prefab JSON</Typography>}
    </Button>
  );
};

export default CreatePrefabButton;
