import React from 'react';
import useSyncStateWithAuth0 from '../hooks/useSyncStateWithAuth0';

interface SyncStateComponentProps {
  children?: React.ReactNode;
}

// This is just a wrapper for a react hook that does the work
const SyncStateComponent: React.FC<SyncStateComponentProps> = ({ children }) => {
  useSyncStateWithAuth0();
  return children;
};

export default SyncStateComponent;
