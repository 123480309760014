import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import React, { useCallback } from 'react';
import { RISK_LEVEL_OPTIONS } from '../../atoms/contentAlignmentAtom';
import { demoDialogContentNodeAtom, demoDialogOpenAtom } from '../../atoms/demoDialogAtom';
import { useDemoForm } from '../../context/DemoFormContext';

export const localRiskAtom = atomWithReset(undefined as string | undefined);
localRiskAtom.debugPrivate = true;

const HeaderContent = () => {
  return (
    <Box
      position={'relative'}
      px={12}
      py={8}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
        Risk Level
      </Typography>
    </Box>
  );
};

const BodyContent = () => {
  const { risk: value } = useDemoForm();
  const [localValue, setLocalValue] = useAtom(localRiskAtom);
  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setLocalValue(event.target.value as string);
    },
    [setLocalValue],
  );
  return (
    <DialogContent>
      <FormControl className="zefr-form" fullWidth size={'medium'}>
        <InputLabel id="demo-risk-label">Risk Tolerance Level</InputLabel>
        <Select
          value={localValue || value}
          onChange={handleChange}
          labelId="demo-risk-label"
          MenuProps={{
            MenuListProps: {
              className: 'zefr-form',
            },
          }}
          label={'Risk Tolerance Level'}
        >
          {RISK_LEVEL_OPTIONS.map(({ label, ...data }) => {
            return <MenuItem {...data}>{label}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </DialogContent>
  );
};

const FooterContent = () => {
  const { setFormRisk: setValue } = useDemoForm();

  const [localValue, setLocalValue] = useAtom(localRiskAtom);
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  const handleSave = React.useCallback(() => {
    setValue(localValue || '');
    setLocalValue(RESET);
    handleClose();
  }, [handleClose, setValue, localValue, setLocalValue]);
  return (
    <DialogActions sx={{ justifyContent: 'space-evenly' }}>
      <Button
        fullWidth={true}
        size={'large'}
        variant="contained"
        onClick={handleClose}
        color="info"
      >
        Close
      </Button>
      <Button fullWidth size={'large'} variant="contained" onClick={handleSave}>
        Save
      </Button>
    </DialogActions>
  );
};

const useRiskFormDialog = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const handleClick = useCallback(() => {
    setDemoDialogContent(
      <>
        <HeaderContent />
        <BodyContent />
        <FooterContent />
      </>,
    );
    setDemoDialog(true);
  }, [setDemoDialog, setDemoDialogContent]);
  return handleClick;
};

export default useRiskFormDialog;
