import { useFlags } from 'gatsby-plugin-launchdarkly';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { RISK_LEVEL_MAP } from '../atoms/contentAlignmentAtom';
import { KpiData, KpiWrapperType, demoKpiDataAtom } from '../atoms/demoKpiDialogOpenAtom';
import {
  KnownFormData,
  PrefabData,
  formParamsAtom,
  setFormExcludedConceptsAtom,
  setFormIncludedConceptsAtom,
  setFormInclusionListTypeAtom,
  setFormLanguagesAtom,
  setFormListAtom,
  setFormPlatformAtom,
  setFormPrefabAtom,
  setFormRiskAtom,
  setFormStepOverrideAtom,
  setFormThresholdAtom,
} from '../atoms/encodedFormAtoms';
import {
  filteredTargetAudienceOptionsAtom,
  targetAudienceOptionsAtom,
  writeTargetAudienceOptionFormAtom,
} from '../atoms/targetAudienceAtom';
import { paginationModelAtom, tableSortAtom } from '../atoms/videoTableAtom';
import DemoCreativeAlignmentDialog from '../components/DemoComponents/DemoCreativeAlignmentDialog';
import DemoDialog from '../components/DemoComponents/DemoDialog';
import DemoTargetAudienceDialog from '../components/DemoComponents/DemoTargetAudienceDialog';
import {
  INCLUSION_LIST_OPTIONS,
  LIST_TYPE_OPTIONS,
  ListTypeOption
} from '../constants/listTypes';
import { PLATFORM_OPTIONS, PlatformOption } from '../constants/platforms';
import {
  RFP_EXCLUSION_OPTIONS,
  RFP_INCLUSION_OPTIONS,
  RfpExclusionOptions,
  RfpInclusionOptions,
} from '../constants/rfp';
import useConceptsList from '../hooks/api/v0/useConceptsList';
import useExclusionChannelsList from '../hooks/api/v0/useExclusionChannelsList';
import useExclusionChannelsListMutation from '../hooks/api/v0/useExclusionChannelsListMutation';
import useInclusionChannelsList from '../hooks/api/v0/useInclusionChannelsList';
import useInclusionChannelsListMutation from '../hooks/api/v0/useInclusionChannelsListMutation';
import useLanguagesList from '../hooks/api/v0/useLanguagesList';
import {
  InclusionTextEnabledFlag,
  LaunchDarklyFlagSet,
  SalesDemoTargetAudienceOptionsData,
} from '../types/launchDarkly';
import {
  ProxyChannelsListResponse,
  ProxyConcept,
  ProxyLanguage,
} from '../types/proxyApi';

interface DemoFormContextType extends KnownFormData {
  step: number;
  nextStep: number;
  listType?: string | null;
  exclusions: string[];
  isInclusionResultSuccess?: boolean;
  isInclusionResultLoading?: boolean;
  isInclusionResultFetched?: boolean;
  isInclusionResultFetching?: boolean;
  isExclusionResultSuccess?: boolean;
  isExclusionResultLoading?: boolean;
  isExclusionResultFetched?: boolean;
  isExclusionResultFetching?: boolean;
  apiConcepts: Record<string, ProxyConcept>;
  apiConceptsBySlug: Record<string, ProxyConcept>;
  setStep: (step: number) => void;
  setPlatform: (platform: string) => void;
  setFormInclusionListType: (listType: string) => void;
  setListType: (listType: string) => void;
  setFormRisk: (risk: string) => void;
  setFormThreshold: (threshold?: number) => void;
  setFormLanguages: (languages: string[]) => void;
  setFormPrefab: (prefab: string, data?: PrefabData) => void;
  setFormExcludedConcepts: (conceptIds: string[]) => void;
  setFormIncludedConcepts: (conceptIds: string[]) => void;
  setTargetAudienceFormOption: (data: { key: string; value: string | string[] | number }) => void;
  setKpiData: (data: KpiWrapperType) => void;
  kpiData: Record<string, KpiData>;
  platformOptions: PlatformOption[];
  selectedPlatform?: PlatformOption;
  listTypeOptions: ListTypeOption[];
  rfpExclusionOptions: RfpExclusionOptions;
  rfpInclusionOptions: RfpInclusionOptions;
  getStepIsDisabled: (step: number) => boolean;
  targetAudienceOptions: SalesDemoTargetAudienceOptionsData;
  filteredTargetAudienceOptions: Record<string, SalesDemoTargetAudienceOptionsData>;
  finalStep: boolean;
  inclusionFormTypeData: ListTypeOption[];
  inclusionResults?: ProxyChannelsListResponse;
  exclusionResults?: ProxyChannelsListResponse;
  riskLevel?: number;
  isConceptsLoading?: boolean;
  isConceptsFetched?: boolean;
  apiLanguages?: Record<string, ProxyLanguage>;
  downloadSampleList: () => Promise<{ channelId: string }[]>;
}

const DemoFormContext = createContext<DemoFormContextType | null>(null);

export const FINAL_STEP = 6;
export const KPI_STEP = 2;

const useDemoForm = () => {
  const myContext = useContext(DemoFormContext);

  if (!myContext) {
    throw new Error('useDemoForm has to be used within <DemoFormContext.Provider>');
  }

  return myContext;
};

const DemoFormContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const flags = useFlags<LaunchDarklyFlagSet>();
  const languagesQuery = useLanguagesList();
  const conceptsQuery = useConceptsList();
  const [kpiData, setKpiData] = useAtom(demoKpiDataAtom);
  const formParams = useAtomValue(formParamsAtom);
  const targetAudienceOptions = useAtomValue(targetAudienceOptionsAtom) || {};
  const filteredTargetAudienceOptions = useAtomValue(filteredTargetAudienceOptionsAtom) || {};
  const tableSort = useAtomValue(tableSortAtom);
  const setFormPlatform = useSetAtom(setFormPlatformAtom);
  const setStepOverride = useSetAtom(setFormStepOverrideAtom);
  const setFormList = useSetAtom(setFormListAtom);
  const setFormRisk = useSetAtom(setFormRiskAtom);
  const setFormPrefab = useSetAtom(setFormPrefabAtom);
  const setFormInclusionListType = useSetAtom(setFormInclusionListTypeAtom);
  const setFormExcludedConcepts = useSetAtom(setFormExcludedConceptsAtom);
  const setFormIncludedConcepts = useSetAtom(setFormIncludedConceptsAtom);
  const setFormThreshold = useSetAtom(setFormThresholdAtom);
  const setFormLanguages = useSetAtom(setFormLanguagesAtom);
  const setTargetAudienceFormOption = useSetAtom(writeTargetAudienceOptionFormAtom);
  const paginationModel = useAtomValue(paginationModelAtom);

  const finalStep = useMemo(() => {
    return formParams.step === FINAL_STEP;
  }, [formParams.step]);

  const proxyLanguages = useMemo(() => {
    return languagesQuery.data || {};
  }, [languagesQuery.data]);

  const apiConcepts = useMemo(() => {
    return conceptsQuery.data || {};
  }, [conceptsQuery.data]);

  const apiConceptsBySlug = useMemo(() => {
    const newData: Record<string, ProxyConcept> = {};
    Object.values(apiConcepts).forEach((v) => {
      newData[v.slug] = v;
    });
    return newData;
  }, [apiConcepts]);

  const includedConceptSlugs = useMemo(() => {
    return (formParams.includedConcepts || [])
      .map((id) => {
        return apiConcepts[id] ? apiConcepts[id].slug : '';
      })
      .filter((i) => Boolean(i));
  }, [formParams.includedConcepts, apiConcepts]);

  const excludedConceptSlugs = useMemo(() => {
    return (formParams.excludedConcepts || [])
      .map((id) => {
        return apiConcepts[id] ? apiConcepts[id].slug : '';
      })
      .filter((i) => Boolean(i));
  }, [formParams.excludedConcepts, apiConcepts]);

  const riskLevel = useMemo(() => {
    const keys = Object.keys(RISK_LEVEL_MAP);
    return keys.indexOf(formParams.risk || '');
  }, [formParams.risk]);

  const inclusionPayload = useMemo(
    () => ({
      enabled: finalStep && formParams.type === 'inclusion',
      include_concepts: includedConceptSlugs,
      exclude_concepts: excludedConceptSlugs,
      maximum_risk_level: riskLevel,
      languages: formParams.languages,
      limit: paginationModel.pageSize,
      page: paginationModel.page,
      threshold: formParams.threshold,
      sort_field: tableSort?.field,
      sort_dir: tableSort?.sort || undefined,
    }),
    [
      finalStep,
      formParams.type,
      includedConceptSlugs,
      excludedConceptSlugs,
      riskLevel,
      formParams.languages,
      paginationModel.page,
      paginationModel.pageSize,
      formParams.threshold,
      tableSort,
    ],
  );

  const {
    data: inclusionResultData,
    isLoading: isInclusionResultLoading,
    isFetched: isInclusionResultFetched,
    isSuccess: inclusionResultSuccess,
    isFetching: isInclusionResultFetching,
  } = useInclusionChannelsList(inclusionPayload);

  const exclusionPayload = useMemo(
    () => ({
      enabled: finalStep && formParams.type === 'exclusion',
      bsp_concepts: excludedConceptSlugs,
      maximum_risk_level: riskLevel,
      limit: paginationModel.pageSize,
      page: paginationModel.page,
      threshold: formParams.threshold,
      sort_field: tableSort?.field,
      sort_dir: tableSort?.sort || undefined,
    }),
    [
      finalStep,
      formParams.type,
      excludedConceptSlugs,
      riskLevel,
      paginationModel.page,
      paginationModel.pageSize,
      formParams.threshold,
      tableSort,
    ],
  );

  const {
    data: exclusionResultData,
    isLoading: isExclusionResultLoading,
    isFetched: isExclusionResultFetched,
    isSuccess: isExclusionResultSuccess,
    isFetching: isExclusionResultFetching,
  } = useExclusionChannelsList(exclusionPayload);

  const { mutateAsync: downloadInclusionSample } = useInclusionChannelsListMutation({
    ...inclusionPayload,
    limit: 200,
  });

  const { mutateAsync: downloadExclusionSample } = useExclusionChannelsListMutation({
    ...exclusionPayload,
    limit: 200,
  });

  const inclusionResults: ProxyChannelsListResponse | undefined = useMemo(() => {
    if (inclusionResultSuccess) {
      return inclusionResultData;
    }
    return undefined;
  }, [inclusionResultData, inclusionResultSuccess]);

  const exclusionResults: ProxyChannelsListResponse | undefined = useMemo(() => {
    if (isExclusionResultSuccess) {
      return exclusionResultData;
    }
    return undefined;
  }, [exclusionResultData, isExclusionResultSuccess]);

  const inclusionFormTypeData: ListTypeOption[] = useMemo(() => {
    if (flags[InclusionTextEnabledFlag]) {
      return INCLUSION_LIST_OPTIONS;
    }
    return [INCLUSION_LIST_OPTIONS[1]];
  }, [flags[InclusionTextEnabledFlag]]);

  const handleSetStepOverride = useCallback(
    (step: number) => {
      setStepOverride(step);
    },
    [setStepOverride],
  );

  const handleSetPlatform = useCallback(
    (platform: string) => {
      setFormPlatform(platform);
    },
    [setFormPlatform],
  );

  const handleSetListType = useCallback(
    (listType: string) => {
      setFormList(listType);
    },
    [setFormList],
  );

  const handleSetRisk = useCallback(
    (risk: string) => {
      setFormRisk(risk);
    },
    [setFormRisk],
  );

  const selectedPlatform: PlatformOption | undefined = useMemo(() => {
    if (formParams.platform) {
      return PLATFORM_OPTIONS.find((i) => i.value === formParams.platform);
    }
    return undefined;
  }, [formParams.platform]);

  const downloadSampleList = useCallback(async () => {
    let func: typeof downloadExclusionSample | typeof downloadInclusionSample =
      (async () => {}) as any;
    if (formParams.type === 'inclusion') {
      func = downloadInclusionSample;
    } else if (formParams.type === 'exclusion') {
      func = downloadExclusionSample;
    }
    if (func) {
      return func().then((e) => {
        return e.channel_results.map((c) => {
          return {
            channelId: c.channel_id,
          };
        });
      });
    } else {
      return Promise.resolve([]);
    }
  }, [downloadExclusionSample, downloadInclusionSample, formParams.type]);

  const getStepIsDisabled = useCallback(
    (currentStep: number) => {
      if (currentStep > formParams.nextStep) {
        return true;
      }
      return false;
    },
    [formParams.step, formParams.nextStep],
  );

  const memoizedContext: DemoFormContextType = useMemo(() => {
    return {
      platform: formParams.platform,
      listType: formParams.type,
      exclusions: formParams.exclusions || [],
      ...formParams,
      listTypeOptions: LIST_TYPE_OPTIONS,
      platformOptions: PLATFORM_OPTIONS,
      selectedPlatform,
      rfpExclusionOptions: RFP_EXCLUSION_OPTIONS,
      getStepIsDisabled,
      setStep: handleSetStepOverride,
      setPlatform: handleSetPlatform,
      setListType: handleSetListType,
      setFormRisk: handleSetRisk,
      filteredTargetAudienceOptions,
      targetAudienceOptions,
      setTargetAudienceFormOption,
      kpiData,
      setKpiData,
      apiConcepts,
      setFormExcludedConcepts,
      setFormIncludedConcepts,
      finalStep,
      inclusionFormTypeData,
      rfpInclusionOptions: RFP_INCLUSION_OPTIONS,
      setFormPrefab,
      setFormInclusionListType,
      exclusionResults,
      inclusionResults,
      isInclusionResultFetched,
      isInclusionResultFetching,
      isInclusionResultLoading,
      isInclusionResultSuccess: inclusionResultSuccess,
      isExclusionResultFetched,
      isExclusionResultFetching,
      isExclusionResultLoading,
      isExclusionResultSuccess,
      riskLevel,
      apiConceptsBySlug,
      isConceptsLoading: conceptsQuery.isLoading,
      isConceptsFetched: conceptsQuery.isFetched,
      apiLanguages: proxyLanguages,
      setFormLanguages,
      downloadSampleList,
      setFormThreshold,
    };
  }, [
    proxyLanguages,
    setFormLanguages,
    conceptsQuery.isLoading,
    conceptsQuery.isFetched,
    riskLevel,
    isExclusionResultFetched,
    isExclusionResultFetching,
    isExclusionResultLoading,
    isExclusionResultSuccess,
    exclusionResults,
    isInclusionResultFetched,
    isInclusionResultLoading,
    isInclusionResultFetching,
    inclusionResultSuccess,
    inclusionResults,
    formParams,
    handleSetStepOverride,
    handleSetListType,
    handleSetPlatform,
    handleSetRisk,
    filteredTargetAudienceOptions,
    targetAudienceOptions,
    setTargetAudienceFormOption,
    kpiData,
    setKpiData,
    setFormExcludedConcepts,
    setFormIncludedConcepts,
    apiConcepts,
    selectedPlatform,
    inclusionFormTypeData,
    setFormPrefab,
    setFormInclusionListType,
    apiConceptsBySlug,
    downloadSampleList,
    setFormThreshold,
    finalStep,
  ]);
  return (
    <DemoFormContext.Provider value={memoizedContext} {...props}>
      <DemoTargetAudienceDialog />
      <DemoCreativeAlignmentDialog />
      <DemoDialog />
      {children}
    </DemoFormContext.Provider>
  );
};

export { DemoFormContext, DemoFormContextProvider, useDemoForm };
export type { DemoFormContextType };
