import { Grid2Props } from '@mui/material';
import React from 'react';
import DonutChart from '../DemoComponents/DonutChart';
import { SummaryItemGrid } from './components';

export interface DonutGraphicProps extends Grid2Props {}

const DonutGraphic: React.FC<DonutGraphicProps> = ({ ...props }) => {
  return (
    <SummaryItemGrid
      id="project-performance-summary-item-donut"
      className="project-performance-summary-item-donut"
      gap={1}
      boxSizing={'border-box'}
      px={2}
      py={4}
      md={3}
      xs={12}
      minHeight={'350px'}
      minWidth={'250px'}
      overflow={'visible'}
      {...props}
    >
      <DonutChart sx={{ width: '15px' }} />
    </SummaryItemGrid>
  );
};

export default DonutGraphic;
