export interface ListTypeOption {
    details: string[];
    label: string;
    learnMore: {
        body?: string;
        title: string;
        divider?: {
            color: string;
        };
    };
    logoIcon: string;
    value: string;
    disabled?: boolean;
}

export const LIST_TYPE_OPTIONS: ListTypeOption[] = [
    {
        details: [
            'Good for strict content control.',
            'Good for regulated industries like Pharma or Kids.',
            'Can be more limited in reach compared to exclusions.',
        ],
        label: 'Inclusion List',
        learnMore: {
            body: '**When To Use**\n\n When you need content control and brand safety, and don’t want kids content in your campaigns. Good for highly sensitive clients in regulated industries such as Pharma or Kids.\n\n**Common Campaign KPIs**\n - Strict Brand Safety Policies & Governance\n - Higher CPM / CPV Benchmarks\n - Tentpole, Seasonal, Sponsorship Alignment\n - VTR / CTR / VCR Focused KPIs\n\n**How Zefr Helps You**\n\n Zefr leverages AI technology to determine the suitability of channels for each brand.  We then use our forecasting algorithm to include the most scalable and relevant channels for campaign delivery.\n\n**Refreshes & Optimizations**\n - DV360 Limits 15K - 20K channels depending on settings\n - Exclusion Lists are Ad Hoc based on brand preferences.  Updated / revised lists will be sent for manual upload based on preferences\n',
            title: 'Using Inclusion Lists',
        },
        logoIcon: 'inclusionList',
        value: 'inclusion',
    },
    {
        details: [
            'Use when you want mass scale and constant suitability optimization.',
            'Zefr helps you maintain sensitive block lists.',
        ],
        label: 'Exclusion List',
        learnMore: {
            body: '**When To Use**\n\n When you want mass scale and constant suitability optimization.  Exclusions are great for brands with concerns about brand safety and suitability or avoidance of certain types of content/sensitivities, but who don’t want to use inclusion lists due to scale or pricing concerns. \n\n**Common Campaign KPIs**\n - Cost Per Unique Reach\n - Action Campaigns and/or 1PD Audience Overlays\n - Scale/Reach/Frequency Focused Activations\n\n**How Zefr Helps You**\n\n Zefr creates an advertiser specific blocklist spanning sensitive content  related to kids, news and politics, gaming, and more.  Zefr then constantly monitors delivery via BSRP measurement to add content to the block list as campaigns scale.\n\n**List Refreshes / Optimizations & Limitations**\n - DV360 Exclusion Limits 20K - 65K in size based on settings and reviewed content\n - Exclusion Lists: Updated/revised lists will be sent for manual upload based on preference\n',
            divider: {
            color: 'red',
            },
            title: 'Using Exclusion Lists',
        },
        logoIcon: 'exclusionList',
        value: 'exclusion',
    },
];

export const INCLUSION_LIST_FORM = 'inclusion-form';
export const INCLUSION_LIST_PREFAB = 'inclusion-type';
export const INCLUSION_LIST_PROMPT = 'inclusion-text';

export const INCLUSION_LIST_OPTIONS: ListTypeOption[] = [
    {
        details: [
            'Type your brief inputs or copy and paste, Zefr will handle the rest.'
        ],
        label: 'Free Form Entry',
        learnMore: {
            title: 'Use Demo Text',
        },
        logoIcon: 'FreeFormIcon',
        value: INCLUSION_LIST_PROMPT,
        disabled: false,

    },
    {
        details: [
            'Use our pre-built form to enter your brief inputs and build your campaign'
        ],
        label: 'Pre-Built Entry Form',
        learnMore: {
            title: 'Use Demo Form',
        },
        logoIcon: 'inclusionList',
        value: INCLUSION_LIST_PREFAB,
        disabled: false,
    },
];
