import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React from 'react';
import {
  demoTargetAudienceDialogOpenAtom,
  writeTargetAudienceFormAtom,
} from '../../atoms/targetAudienceAtom';
import TargetAudienceForm from '../TargetAudience/TargetAudienceForm';
import targetAudienceOptionsFormAtom from '../TargetAudience/atom';
import { ColoredDivider } from './Form/components';

export interface DemoTargetAudienceDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {}

const DemoTargetAudienceDialog: React.FC<DemoTargetAudienceDialogProps> = ({ ...props }) => {
  const writeTargetAudienceForm = useSetAtom(writeTargetAudienceFormAtom);
  const [openDialog, setOpenDialog] = useAtom(demoTargetAudienceDialogOpenAtom);
  const [targetAudienceOptions, setTargetAudienceOptions] = useAtom(targetAudienceOptionsFormAtom);
  const handleClose = React.useCallback(() => {
    setOpenDialog(undefined);
    setTargetAudienceOptions(RESET);
  }, [setOpenDialog]);

  const handleSave = React.useCallback(() => {
    writeTargetAudienceForm(targetAudienceOptions);
    handleClose();
  }, [handleClose, writeTargetAudienceForm, targetAudienceOptions]);

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      // disableRestoreFocus prevents the page from jumping on dialog close
      disableRestoreFocus={true}
      open={Boolean(openDialog)}
      maxWidth={'md'}
      sx={{ m: 1 }}
      {...props}
    >
      <Box
        position={'relative'}
        px={12}
        py={8}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
          Target Audience
        </Typography>
        <ColoredDivider sx={{ bottom: '15px', position: 'absolute' }} />
      </Box>
      <DialogContent>
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          gap={1}
        >
          <TargetAudienceForm />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-evenly' }}>
        <Button
          fullWidth={true}
          size={'large'}
          variant="contained"
          onClick={handleClose}
          color="info"
        >
          Close
        </Button>
        <Button fullWidth size={'large'} variant="contained" onClick={handleSave}>
          Add Target Audience
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoTargetAudienceDialog;
