import { commonNS } from '@zefr/style-guide/dist/core/i18next';
import { LOCALE, Resources } from '../../gatsby-theme-zefr/locale/resources';
import { APP_ChannelSummary, APP_Demo, APP_ImageVerifai, ToolTitles } from '../atoms/toolsAtom';

const DemoResources: Record<string, unknown> = {
  title: 'Demo',
  learn_more: 'Learn More',
  step: {
    one: {
      title: 'How do you want to activate?',
    },
    two: {
      title: 'How do you want to activate?',
    },
    three: {
      title: 'Complete Campaign Details',
      additionalExclusions: {
        title: 'Choose additional exclusions',
        subtitle: '(Optional)',
      },
    },
    four: {
      title: 'Final',
    },
  },
};

export const resources: Resources = {
  [LOCALE]: {
    [commonNS]: {
      growth: {
        title: 'Explore',
        tools: { ...ToolTitles },
        score: 'Score',
        error: 'Error',
        result: 'Result',
        [APP_ImageVerifai]: {
          examples: 'Examples',
          confident: 'confident',
          create_example: 'Create example with ChatGPT4',
          choose_example: 'Choose an example below to see image score.',
        },
        [APP_ChannelSummary]: {
          placeholder: 'Snowflake Channel or ID',
        },
        [APP_Demo]: DemoResources,
      },
    },
  },
};
