import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { default as Grid, default as Grid2 } from '@mui/material/Unstable_Grid2';
import { useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { setFormKpiKeysAtom } from '../../../../atoms/encodedFormAtoms';
import { useDemoForm } from '../../../../context/DemoFormContext';
import InternalIcon from '../../../InternalIcon';
import { ClickablePaper } from '../components';

export interface KpiPreferencesProps {}

const KpiPreferences: React.FC<KpiPreferencesProps> = () => {
  const { kpiData, kpiKeys } = useDemoForm();
  const setFormKpiKeys = useSetAtom(setFormKpiKeysAtom);
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const knownKeys = useMemo(() => {
    return kpiKeys || [];
  }, [kpiKeys]);

  const handleClick = useCallback(
    (key: string) => {
      const existingKeyIndex = knownKeys.indexOf(key);
      // If we have the key, set to exclude the key
      if (existingKeyIndex !== -1) {
        const copyKeys = cloneDeep(knownKeys);
        copyKeys.splice(existingKeyIndex, 1);
        setFormKpiKeys(copyKeys);
      } else {
        // If we lack the key, set it to be the whole answer
        setFormKpiKeys([key]);
      }
    },
    [setFormKpiKeys, knownKeys],
  );
  return (
    <Grid container flexGrow={1} alignItems={'center'}>
      <Grid xs={12}>
        <Box display="flex" flexDirection={'column'} gap={1} width={'100%'}>
          <Grid2
            container
            spacing={4}
            flexWrap={'wrap'}
            justifyContent={isXl ? 'space-evenly' : 'flex-start'}
            alignItems={'center'}
          >
            {Object.keys(kpiData).map((key) => {
              const data = kpiData[key];
              return (
                <Grid2
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={2}
                  key={key}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <ClickablePaper
                    {...data}
                    active={knownKeys.indexOf(key) !== -1}
                    onClick={() => {
                      handleClick(key);
                    }}
                    variant="outlined"
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}
                      gap={2}
                    >
                      <InternalIcon
                        iconName={data.icon}
                        sx={{
                          fontSize: '7.5rem',
                        }}
                      />
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.secondary.main,
                        })}
                        variant="h4"
                        textAlign={'center'}
                      >
                        {data.title}
                      </Typography>
                    </Box>
                  </ClickablePaper>
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
      </Grid>
    </Grid>
  );
};

export default KpiPreferences;
