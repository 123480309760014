export const LANGUAGE_NAME_TO_CODE_MAP: Record<string,string> = {
    Afrikaans: "af",
    Arabic: "ar",
    Belarusian: "be",
    Bengali: "bn",
    Bulgarian: "bg",
    Burmese: "my",
    Catalan: "ca",
    Chinese: "zh",
    Croatian: "hr",
    Czech: "cs",
    Danish: "da",
    Dutch: "nl",
    English: "en",
    Finnish: "fi",
    French: "fr",
    German: "de",
    Greek: "el",
    Gujarati: "gu",
    Hindi: "hi",
    Hungarian: "hu",
    Indonesian: "id",
    Italian: "it",
    Japanese: "ja",
    Javanese: "jv",
    Kannada: "kn",
    Korean: "ko",
    Kurdish: "ku",
    Malay: "ms",
    Malayalam: "ml",
    Marathi: "mr",
    Nepali: "ne",
    Norwegian: "no",
    Pedi: "nso",
    Persian: "fa",
    Polish: "pl",
    Portuguese: "pt",
    Punjabi: "pa",
    Romanian: "ro",
    Russian: "ru",
    Serbian: "sr",
    Sinhalese: "si",
    Slovak: "sk",
    Slovenian: "sl",
    Somali: "so",
    Sotho: "st",
    Spanish: "es",
    Sundanese: "su",
    Swahili: "sw",
    Swedish: "sv",
    Tagalog: "tl",
    Tamil: "ta",
    Telugu: "te",
    Thai: "th",
    Turkish: "tr",
    Ukrainian: "uk",
    Urdu: "ur",
    Uzbek: "uz",
    Vietnamese: "vi"
};

export const LANGUAGE_CODE_TO_NAME_MAP: Record<string,string> = Object
    .keys(LANGUAGE_NAME_TO_CODE_MAP)
    .reduce((prev, name) => {
        return {
            ...prev,
            [LANGUAGE_NAME_TO_CODE_MAP[name]]: name
        };
    }, {});
