import { BASE_APP_ROUTE } from '../helpers';

export const BASE_URL = BASE_APP_ROUTE;

type routeMap = {
  root: string;
};
export const routes: routeMap = {
  root: BASE_URL,
};
