import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useDemoForm } from '../../../context/DemoFormContext';

export interface SetPrefabSelectProps extends FormControlProps {
  inputLabelProps?: Partial<InputLabelProps>;
  selectProps?: Partial<Omit<SelectProps, 'value' | 'onChange' | 'labelId' | 'label'>>;
}

const SetPrefabSelect: React.FC<SetPrefabSelectProps> = ({
  fullWidth = true,
  inputLabelProps = {},
  selectProps = {},
  className,
  ...props
}) => {
  const { rfpInclusionOptions, prefab: value, setFormPrefab: setValue } = useDemoForm();

  const handleChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      const key = event.target.value as string;
      setValue(key, rfpInclusionOptions.options[key as keyof typeof rfpInclusionOptions].data);
    },
    [setValue, rfpInclusionOptions],
  );
  return (
    <FormControl
      className={`zefr-form ${className ? ` ${className}` : ''}`}
      {...{ fullWidth, ...props }}
    >
      <InputLabel {...inputLabelProps} id="demo-prefab-label">
        {rfpInclusionOptions?.placeholder}
      </InputLabel>
      <Select
        {...selectProps}
        value={value || ''}
        onChange={handleChange}
        labelId="demo-prefab-label"
        label={rfpInclusionOptions?.placeholder}
        MenuProps={{
          MenuListProps: {
            className: 'zefr-form',
          },
        }}
      >
        {Object.values(rfpInclusionOptions.options).map(({ label, ...data }) => {
          return <MenuItem {...data}>{label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default SetPrefabSelect;
