import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import PageNotFound from '../../../../src/components/PageNotFound';
import { DEMO_FORM_URL } from '../routes';
import DemoRoute from './DemoRoute';
import Home from './Home';

interface DemoPageRouterProps extends RouteComponentProps {}

// This component holds all locations for our app that uses authenticated client-only routes
export const DemoPageRouter: React.FC<DemoPageRouterProps> = ({ path, ...props }) => {
  return (
    <Router className="h-full">
      <DemoRoute path={DEMO_FORM_URL} />
      <Home path={'/'} />
      <PageNotFound default />
    </Router>
  );
};

export default DemoPageRouter;
