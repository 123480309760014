import { atom } from 'jotai';

type State = boolean | undefined;

const initialState: State = undefined;

const markdownDialogOpenAtom = atom(initialState as State);

markdownDialogOpenAtom.debugLabel = 'Markdown Helper Dialog Open';

export { markdownDialogOpenAtom };
