import Grid2, { Grid2Props } from '@mui/material/Unstable_Grid2';
import React, { useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import TargetAudienceLanguageSelect from './TargetAudienceLanguageSelect';
import TargetAudienceMultiSelect from './TargetAudienceMultiSelect';
import TargetAudienceSelect from './TargetAudienceSelect';
import TargetAudienceText from './TargetAudienceText';

export interface TargetAudienceProps extends Grid2Props {}

const TargetAudience: React.FC<TargetAudienceProps> = ({ width = '100%', ...props }) => {
  const { filteredTargetAudienceOptions: targetAudienceOptions } = useDemoForm();

  const multiSelectElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['multiselect'] || {}).map((key) => {
      return <TargetAudienceMultiSelect id={key} key={key} />;
    });
  }, [targetAudienceOptions]);

  const textElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['text'] || {}).map((key) => {
      return <TargetAudienceText key={key} id={key} />;
    });
  }, [targetAudienceOptions]);

  const selectElements = useMemo(() => {
    return Object.keys(targetAudienceOptions['select'] || {}).map((key) => {
      return <TargetAudienceSelect key={key} id={key} />;
    });
  }, [targetAudienceOptions]);

  return (
    <Grid2 container {...props} {...{ width }} spacing={5}>
      {multiSelectElements}
      <TargetAudienceLanguageSelect />
      {selectElements}
      {textElements}
    </Grid2>
  );
};

export default TargetAudience;
