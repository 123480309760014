import {
  AdvancedPerformanceOptimizationIcon,
  AverageDailyViewsIcon,
  ClicksPerViewIcon,
  DV360Icon,
  DV360TextIcon,
  EngagementIcon,
  ExclusionListIcon,
  FacebookIcon,
  FreeFormIcon,
  GoogleAdsIcon,
  GoogleAdsTextIcon,
  InclusionListIcon,
  InstagramIcon,
  ListIcon,
  MetaIcon,
  MoneyIcon,
  PerformanceIcon,
  PerformanceOptimizationIcon,
  PlayBoxIcon,
  ReachIcon,
  ScaledReachIcon,
  SimpleOnboardingIcon,
  TikTokDarkIcon,
  TotalSpendIcon,
  ViewThroughRateIcon,
  YouTubeIcon,
  YouTubeTextIcon,
} from '@zefr/style-guide/dist/ui/icons';
import React from 'react';

import * as MuiIcons from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

const Money: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <MoneyIcon color="gradient_blue" {...props} />;
};

const totalSpend: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <TotalSpendIcon color="gradient_blue" {...props} />;
};

const viewThroughRate: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <ViewThroughRateIcon color="gradient_blue" {...props} />;
};

const clicksPerView: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <ClicksPerViewIcon color="gradient_blue" {...props} />;
};

const averageDailyViews: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <AverageDailyViewsIcon color="gradient_blue" {...props} />;
};

const freeText: React.FC<Omit<SvgIconProps, 'color'>> = (props) => {
  return <ListIcon color="gradient_blue" {...props} />;
};

export const valuesToComponents: Record<string, React.FC<any>> = {
  dv360: DV360Icon,
  'google-ads': GoogleAdsIcon,
  youtube: YouTubeIcon,
  tiktok: TikTokDarkIcon,
  meta: MetaIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  inclusionList: InclusionListIcon,
  exclusionList: ExclusionListIcon,
  totalSpend,
  averageDailyViews,
  clicksPerView,
  viewThroughRate,
  money: Money,
  freeText,
  PerformanceOptimizationIcon,
  ReachIcon,
  PlayBoxIcon,
  DV360TextIcon,
  YouTubeTextIcon,
  GoogleAdsTextIcon,
  FreeFormIcon,
  EngagementIcon,
  PerformanceIcon,
  ScaledReachIcon,
  AdvancedPerformanceOptimizationIcon,
  SimpleOnboardingIcon,
  ...MuiIcons,
};
