import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import {
  contentAlignmentOptionsAtom,
  writeContentAlignmentOptionFormAtom,
} from '../../atoms/contentAlignmentAtom';
import { formParamsAtom } from '../../atoms/encodedFormAtoms';

export interface ContentAlignmentSelectProps extends FormControlProps {}

const ContentAlignmentSelect: React.FC<ContentAlignmentSelectProps> = ({
  fullWidth = true,
  id = '',
  className = '',
  ...props
}) => {
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const items = useAtomValue(contentAlignmentOptionsAtom);
  const activeItems = useAtomValue(formParamsAtom);
  const setFormOption = useSetAtom(writeContentAlignmentOptionFormAtom);
  const handleSelect = useCallback(
    (event: SelectChangeEvent<string>, key: string) => {
      const {
        target: { value },
      } = event;
      setFormOption({
        key,
        value: value,
      });
      forceUpdate();
    },
    [setFormOption, forceUpdate],
  );
  const data = useMemo(() => {
    return id ? items[id] : undefined;
  }, [id, items]);

  return (
    <FormControl
      className={`zefr-form content-alignment-select-form-control${
        className ? ` ${className}` : ''
      }`}
      {...props}
      {...{ fullWidth }}
      fullWidth
    >
      <InputLabel className={`content-alignment-select-label`} id={`${id}-label`}>
        {data?.label}
      </InputLabel>
      <Select
        // defaultValue={(activeItems['contentAlignment'][id] as string) || data?.defaultValue || ''}
        value={(activeItems['contentAlignment'][id] as string) || ''}
        onChange={(e) => handleSelect(e, id)}
        labelId={`${id}-label`}
        label={data?.label}
        className={`content-alignment-select`}
        aria-label={data?.label || 'Content Alignment Select'} // accessibility label
        MenuProps={{
          MenuListProps: {
            className: 'zefr-form',
          },
        }}
      >
        {Object.entries(data?.options || {}).map(([optionKey, label]) => (
          <MenuItem className={`content-alignment-select-item`} value={optionKey} key={optionKey}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContentAlignmentSelect;
