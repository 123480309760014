import { Box, Divider, Stack, StackProps, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export interface ChannelResultsHeaderProps extends StackProps {
  iconFontSize?: string;
}

const ChannelResultsHeader: React.FC<ChannelResultsHeaderProps> = ({
  iconFontSize = '9.5rem',
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Stack
      position={'relative'}
      display={'flex'}
      gap={2}
      sx={(theme) => ({
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        },
        '& .MuiSvgIcon-root': { height: 'unset', fontSize: iconFontSize },
      })}
      divider={
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          width={matches ? undefined : '100%'}
          boxSizing={'border-box'}
        >
          <Divider
            sx={(theme) => ({
              borderColor: theme.palette.secondary.main,
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: 'unset',
              },
            })}
            orientation={matches ? 'vertical' : 'horizontal'}
          />
        </Box>
      }
      {...props}
    />
  );
};

export default ChannelResultsHeader;
