import { Chip, FormControl, FormControlLabel, Paper, darken, lighten, styled } from '@mui/material';

export const SelectFormControl = styled(FormControl)(({ theme }) => {
  return {
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      '&.MuiOutlinedInput-input': {
        padding: theme.spacing(1),
      },
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      '&:hover': {
        color: darken(theme.palette.primary.main, 0.1),
      },
      '&:active': {
        color: lighten(theme.palette.primary.main, 0.1),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };
});

export const TITLE_HEIGHT = '40px';

export const ScrollablePaper = styled(Paper)(({ theme }) => {
  return {
    overflowY: 'auto',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    minHeight: '10rem',
    height: '50vh',
    maxHeight: '50vh',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    '& .MuiChip-root': {
      position: 'relative',
      '& .MuiChip-icon': {},
    },
  };
});

export const StyledSwapChip = styled(Chip)(({ theme, color, onClick }) => {
  return {
    minHeight: '24px',
    paddingLeft: theme.spacing(1),
    // padding: theme.spacing(2),
    position: 'relative',
    textWrap: 'wrap',
    fontSize: '10.82px',
    '& .MuiChip-label': {
      whiteSpace: 'break-spaces',
      textWrap: 'wrap',
      textOverflow: 'unset',
    },
    '& .MuiChip-icon': {
      margin: 0,
    },
    '& .MuiChip-deleteIcon': {
      lineHeight: '8px',
      margin: 0,
    },
  };
});

export const FilterFormControlLabel = styled(FormControlLabel)(({ color, theme }) => {
  return {
    '& .MuiTypography-root': {
      color,
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
    },
  };
});
