import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

const creativeAlignmentLocalAtom = atomWithReset(undefined as string | undefined);
creativeAlignmentLocalAtom.debugLabel = 'Creative Alignment Local Value';

type DialogState = boolean | undefined;

const initialState: DialogState = false;

const demoCreativeAlignmentDialogOpenAtom = atom(initialState as DialogState);

demoCreativeAlignmentDialogOpenAtom.debugLabel = 'Demo Creative Alignment Dialog Open';

export { creativeAlignmentLocalAtom, demoCreativeAlignmentDialogOpenAtom };
