import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { Category, RiskLevel } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { useSetAtom } from 'jotai';
import React, { useCallback } from 'react';
import { demoDialogContentNodeAtom, demoDialogOpenAtom } from '../../atoms/demoDialogAtom';
import GarmScoreCard, { ScoreData } from './GarmScoreCard';

const HeaderContent = () => {
  return (
    <Box
      position={'relative'}
      px={12}
      py={8}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
        GARM Score Data
      </Typography>
    </Box>
  );
};

interface BodyContentProps {
  scoreData?: Partial<Record<Category, ScoreData>>;
}
const BodyContent: React.FC<BodyContentProps> = ({ scoreData }) => {
  return (
    <DialogContent
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <GarmScoreCard scoreData={scoreData} />
    </DialogContent>
  );
};

const FooterContent = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  return (
    <DialogActions sx={{ justifyContent: 'space-evenly' }}>
      <Button
        fullWidth={true}
        size={'large'}
        variant="contained"
        onClick={handleClose}
        color="info"
      >
        Close
      </Button>
    </DialogActions>
  );
};

interface BodyContentProps {
  scoreData?: Partial<Record<Category, ScoreData>>;
}

const useGarmScoreDialog = (params: Record<string, RiskLevel>) => {
  const transformedParams: Record<string, ScoreData> = {};
  Object.keys(params).forEach((p) => {
    const newData: ScoreData = {
      riskLevel: params[p],
    };
    transformedParams[p] = newData;
  });
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const handleClick = useCallback(() => {
    setDemoDialogContent(
      <>
        <HeaderContent />
        <BodyContent scoreData={transformedParams} />
        <FooterContent />
      </>,
    );
    setDemoDialog(true);
  }, [setDemoDialog, setDemoDialogContent]);
  return handleClick;
};

export default useGarmScoreDialog;
