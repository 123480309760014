import { CircularProgress, Dialog, DialogProps } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import {
  demoDialogContentNodeAtom,
  demoDialogOpenAtom,
  demoDialogPropsAtom,
} from '../../atoms/demoDialogAtom';

export interface DemoDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {}

const DemoDialog: React.FC<DemoDialogProps> = ({ ...props }) => {
  const [openDialog, setOpenDialog] = useAtom(demoDialogOpenAtom);
  const content = useAtomValue(demoDialogContentNodeAtom);
  const atomProps = useAtomValue(demoDialogPropsAtom);
  const { onClose } = atomProps;
  const handleClose = React.useCallback(
    (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (onClose) {
        onClose(event, reason);
      }
      setOpenDialog(undefined);
    },
    [setOpenDialog],
  );

  return (
    <Dialog onClose={handleClose} open={Boolean(openDialog)} {...props} {...atomProps}>
      {content || <CircularProgress />}
    </Dialog>
  );
};

export default DemoDialog;
