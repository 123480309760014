import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { pageTitleAtom } from '../atoms/pageTitleAtom';

const usePageTitle = (title: string) => {
  const [pageTitle, setPageTitle] = useAtom(pageTitleAtom);
  useEffect(() => {
    // When mounted, if our app is not selected switch to it
    if (pageTitle !== title) {
      setPageTitle(title);
    }
    return () => {
      // When unmounted, if our app is still selected switch to NONE
      if (pageTitle === title) {
        setPageTitle(undefined);
      }
    };
  }, [setPageTitle, pageTitle]);

  return useMemo(() => {
    return {
      pageTitle,
      setPageTitle,
    };
  }, [pageTitle, setPageTitle]);
};

export default usePageTitle;
