/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import usePlatformLearnMoreDialog from '../../../../hooks/dialog/usePlatformLearnMoreDialog';
import { SalesDemoPlatform } from '../../../../types/launchDarkly';
import FormStepDemoPlatformCards from '../FormStepDemoPlatformCards/FormStepDemoPlatformCards';
import { FormStepProps } from '../types';

export const FormStepPlatformChoice: React.FC<FormStepProps> = ({ step: myStep = 1, ...props }) => {
  const { t } = useTranslation();
  const { platformOptions, platform, setPlatform } = useDemoForm();
  const openLearnMoreDialog = usePlatformLearnMoreDialog();
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */
  const handleClick = useCallback(
    (data: SalesDemoPlatform) => {
      openLearnMoreDialog({
        details: { ...data.learnMore, icon: data.logoIcon || data.labelIcon },
      });
    },
    [openLearnMoreDialog],
  );

  /* #endregion */
  return (
    <FormStepDemoPlatformCards
      titleLabel={t(`growth.${APP_Demo}.step.two.title`)}
      options={platformOptions}
      selectedOption={platform}
      setValue={setPlatform}
      ctaClick={handleClick}
      ctaLabel="Learn More"
      floatingIcon={false}
      step={myStep}
      {...props}
    />
  );
};

export default FormStepPlatformChoice;
