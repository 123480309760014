import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useAtom } from 'jotai';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import zefrBrandAxiosInstance from '../../gatsby-plugin-zefr-growth/api/axiosInstances/zefrBrandAxiosInstance';
import zefrGrowthAxiosInstance from '../../gatsby-plugin-zefr-growth/api/axiosInstances/zefrGrowthAxiosInstance';
import { userAtom } from '../atoms/userAtom';
import { parseJwt } from './helpers';

const useSyncStateWithAuth0 = () => {
  const ldClient = useLDClient();
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [userState, setUserState] = useAtom(userAtom);
  // If atom state does not match auth0 state, update to match auth0
  useEffect(() => {
    if (ldClient) {
      ldClient
        .waitForInitialization()
        .then(() => {
          if (userState?.authenticated) {
            ldClient?.identify({
              kind: 'user',
              key: userState.user?.email,
              email: userState.user?.email,
              ...(userState.decodedAccessToken?.['https://zefr.com/'].authorization || {
                groups: [],
                roles: [],
              }),
            });
          } else {
            ldClient.identify({ kind: 'user', anonymous: true });
          }
        })
        .catch((reason: Error | undefined) => {
          datadogLogs.logger.error(
            'User failed to identify.',
            {
              userState,
            },
            reason,
          );
        });
    }
  }, [userState, ldClient]);

  useEffect(() => {
    if (isAuthenticated === false && userState?.authenticated === true) {
      // If auth0 says we are logged out, but we think we are logged in
      datadogLogs.logger.info('User Signed Out. Previous state before sign out attached.', {
        userState,
      });
      // TODO: Fix removing the LD Client identiy on logout
      datadogRum.clearUser();
      setUserState({ authenticated: false });
      zefrGrowthAxiosInstance.updateJWT(undefined);
      zefrBrandAxiosInstance.updateJWT(undefined);
    } else if (isAuthenticated && !userState?.authenticated) {
      // If auth0 says we are logged in, but we think we are logged out
      // console.log('Fetching token');
      getAccessTokenSilently()
        .then((newToken) => {
          const decodedToken = parseJwt(newToken);
          setUserState({
            authenticated: true,
            user,
            accessToken: newToken,
            decodedAccessToken: decodedToken,
          });
          zefrGrowthAxiosInstance.updateJWT(newToken);
          zefrBrandAxiosInstance.updateJWT(newToken);
          const zefrAuth = decodedToken['https://zefr.com/']['authorization'] || {};
          ldClient?.identify({
            kind: 'user',
            key: user?.email,
            email: user?.email,
            groups: zefrAuth.groups,
            roles: zefrAuth.roles,
          });
          datadogRum.setUser({
            id: user?.email,
            name: user?.name,
            email: user?.email,
          });
          datadogLogs.logger.info('User Signed In', { user, zefrAuth });
        })
        .catch((e) => {
          datadogLogs.logger.error(
            'User failed to login.',
            {
              userState,
            },
            e,
          );
          logout({ logoutParams: { returnTo: window.location.origin } });
        });
    }
  }, [userState?.authenticated, isAuthenticated, setUserState]);
  return null;
};

export default useSyncStateWithAuth0;
