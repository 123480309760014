import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import {
  demoDialogContentNodeAtom,
  demoDialogOpenAtom,
  demoDialogPropsAtom,
} from '../../atoms/demoDialogAtom';
import { useDemoForm } from '../../context/DemoFormContext';
import LearnMoreDialog from './LearnMoreDialog';

const BodyContent = () => {
  const { listTypeOptions } = useDemoForm();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const boxes = useMemo(() => {
    const nodes: React.ReactNode[] = [];
    listTypeOptions.forEach((d, i) => {
      nodes.push(<LearnMoreDialog data={d} key={d.value} />);
    });
    return nodes;
  }, [listTypeOptions]);
  return (
    <DialogContent>
      <Stack
        direction={matches ? 'row' : 'column'}
        spacing={4}
        divider={
          <Box>
            <Divider orientation={matches ? 'vertical' : undefined} />
          </Box>
        }
      >
        {boxes}
      </Stack>
    </DialogContent>
  );
};

const FooterContent = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  return (
    <DialogActions sx={{ justifyContent: 'space-evenly' }}>
      <Button
        sx={{ width: '400px' }}
        size={'large'}
        variant="contained"
        onClick={handleClose}
        color="primary"
      >
        Got It!
      </Button>
    </DialogActions>
  );
};

const useTypeLearnMoreDialog = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const setDemoDialogProps = useSetAtom(demoDialogPropsAtom);
  const handleClick = useCallback(() => {
    setDemoDialogProps({
      fullWidth: true,
      maxWidth: 'xl',
      disableRestoreFocus: true,
      sx: {
        m: 1,
      },
    });
    setDemoDialogContent(
      <>
        <BodyContent />
        <FooterContent />
      </>,
    );
    setDemoDialog(true);
  }, [setDemoDialog, setDemoDialogContent, setDemoDialogProps]);
  return handleClick;
};

export default useTypeLearnMoreDialog;
