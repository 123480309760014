import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { pageSize } from '~src/helpers/style';
import MaxLogo from '~src/images/svg/max-logo.svg';
import ZefrLogo from '~src/images/svg/zefr-logo.svg';
import Link from '../../../gatsby-theme-zefr/components/Link';
import usePageTitle from '../../../gatsby-theme-zefr/hooks/usePageTitle';
import HomeContainerTwo from '../../components/DemoComponents/HomePage/HomeContainerTwo';

const Home: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  usePageTitle(t(`growth.demo.title`));
  return (
    <Grid2
      id="growth-home-container"
      display={'flex'}
      alignItems={'stretch'}
      flexDirection={'column'}
      sx={{
        maxHeight: pageSize,
        overflowY: 'auto',
        position: 'relative',
        scrollBehavior: 'smooth',
        '& > .MuiGrid2-root': {
          minHeight: pageSize,
        },
      }}
    >
      <Grid2
        xs={12}
        zIndex={1}
        id="growth-home-container-1"
        sx={{
          scrollSnapAlign: 'center',
          backgroundColor: `#E3F1FF`,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'nowrap'}
          minHeight={'100%'}
          sx={{
            '& > .MuiBox-root': {
              minHeight: pageSize,
            },
          }}
          boxSizing={'border-box'}
          px={undefined}
        >
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            overflow="auto"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              px={5}
              gap={6}
              sx={{
                width: '550px',
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gap={8}
                p={matches ? undefined : 2}
                divider={
                  <Divider
                    sx={() => {
                      return {
                        width: '4px',
                        height: '100%',
                        borderColor: '#00d2ff',
                        borderRightWidth: '3px',
                      };
                    }}
                    orientation="vertical"
                  />
                }
                sx={(theme) => {
                  return {
                    height: '105px',
                    '& > *, & > .MuiTypography-root': {
                      color: theme.palette.secondary.main,
                      fill: theme.palette.secondary.main,
                    },
                  };
                }}
              >
                <ZefrLogo />
                <MaxLogo />
              </Stack>
              <Box p={matches ? undefined : 2}>
                <Typography
                  sx={{ fontSize: '2.25rem', fontWeight: 700, color: '#111111' }}
                  variant="h1"
                >
                  Optimizing Brand Suitability and&nbsp;Performance
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            minWidth={'40%'}
            display={matches ? undefined : 'none'}
            sx={{
              backgroundImage: 'url("/images/zefr-marketing.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              maxHeight: pageSize,
              '& > img': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <img src={'/images/zefr-onboarding.webp'} />
          </Box>
        </Box>
        <Box
          position={'absolute'}
          bottom={'25px'}
          width={'100%'}
          height={'50px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={(theme) => ({
            '&  a': {
              color: theme.palette.primary.main,
            },
          })}
        >
          <IconButton
            color={'primary'}
            LinkComponent={Link}
            {...{ to: '#growth-home-container-2' }}
          >
            <ExpandCircleDownRoundedIcon
              sx={{
                fontSize: '5rem',
              }}
            />
          </IconButton>
        </Box>
      </Grid2>
      <HomeContainerTwo />
    </Grid2>
  );
};

export default Home;
