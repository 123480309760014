import { atom } from 'jotai';

type State = boolean | undefined;

const initialState: State = undefined;

const sidebarOpenAtom = atom(initialState as State);

sidebarOpenAtom.debugLabel = 'Sidebar Open Status';

export { sidebarOpenAtom };
