import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import React from 'react';
import { LOGIN } from '../constants/routes';

export interface PrivateRouteProps {
  component: React.FC<any>;
  location: Location;
  [x: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (!isAuthenticated && location.pathname !== LOGIN) {
    loginWithRedirect({
      openUrl: () => {
        navigate(location.pathname, { replace: true });
      },
      appState: {
        returnTo: location.href,
      },
    });
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
