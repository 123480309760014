import { Paper, PaperProps, darken, styled } from '@mui/material';
import React from 'react';

export interface ClickablePaperProps extends PaperProps {
  active?: boolean;
  disabled?: boolean;
}

export const ZefrClickablePaper = styled(Paper)<ClickablePaperProps>(({
  theme,
  active,
  disabled,
}) => {
  const borderColor = active ? theme.palette.primary.main : undefined;
  const borderWidth = active ? '1px' : 0;
  return {
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    minWidth: '225px',
    maxWidth: '670px',
    minHeight: '300px',
    width: '100%',
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .has-active-state .MuiTypography-root': {
      fontWeight: active ? 'bold' : undefined,
    },
    '& .has-responsive-state .MuiSvgIcon-root': {
      fontSize: '2.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      '&.big': {
        fontSize: '4rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '5rem',
        },
      },
    },
    '& .has-disabled-state .MuiSvgIcon-root, & .follows-disabled-state': {
      opacity: disabled ? 0.25 : undefined,
    },
    borderColor,
    borderWidth,
    backgroundColor: disabled ? theme.palette.background.default : undefined,
    boxShadow: active ? `0px 4px 30px 0px rgba(1, 162, 255, 0.50)` : undefined,
    transition: theme.transitions.create(
      ['background-color', 'color', 'border-color', 'box-shadow'],
      {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      },
    ),
    '& .MuiTypography-root, & .MuiSvgIcon-root, & hr': {
      userSelect: 'none',
      transition: theme.transitions.create(
        ['transform', 'font-weight', 'border-width', 'border-color', 'box-shadow'],
        {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        },
      ),
    },
    '&:hover': {
      '& .MuiTypography-root:not(li), & .MuiSvgIcon-root, & hr': {
        transform: 'scale(1.1)',
      },
      cursor: disabled ? 'not-allowed' : 'pointer',
      borderColor: active ? theme.palette.primary.main : 'transparent',
      borderWidth: active ? '1.5px' : undefined,
      boxShadow: `0px 4px 40px 0px rgba(0, 0, 0, 0.2)`,
    },
    '&:active': {
      backgroundColor: darken(
        disabled ? theme.palette.background.default : theme.palette.background.paper,
        0.05,
      ),
    },
  };
});

const ClickablePaper: React.FC<ClickablePaperProps> = ({ active, disabled, ...props }) => {
  return <ZefrClickablePaper active={active} disabled={disabled} variant="outlined" {...props} />;
};

export default ClickablePaper;
