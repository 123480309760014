import { SvgIconProps } from '@mui/material';
import React, { useMemo } from 'react';
import { valuesToComponents } from '../helpers/icon';

export interface InternalIconProps extends SvgIconProps {
  iconName?: string;
}

const InternalIcon: React.FC<InternalIconProps> = ({ iconName = '', ...props }) => {
  const MyIcon = useMemo(() => {
    const newIcon = valuesToComponents[iconName];
    if (newIcon) {
      return newIcon;
    }
    return undefined;
  }, [iconName]);
  if (!MyIcon) {
    return null;
  }
  return <MyIcon {...props} />;
};

export default InternalIcon;
