import { RouteComponentProps, Router } from '@reach/router';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React, { useEffect } from 'react';
import PageNotFound from '../../../../src/components/PageNotFound';
import { APP_ImageVerifai, ToolAppKeys, ToolApps, toolsAtom } from '../../atoms/toolsAtom';
import Home from './Home';
import ImageVerifaiRoute from './ImageVerifaiRoute';

interface SinglePageToolsProps extends RouteComponentProps {}

// This component holds all locations for our app that uses authenticated client-only routes
export const SinglePageTools: React.FC<SinglePageToolsProps> = ({ path, ...props }) => {
  const params = (props as any)['*'];
  const [toolApp, setToolApp] = useAtom(toolsAtom);
  useEffect(() => {
    if (!params || params === '') {
      setToolApp(RESET);
    } else {
      if (params !== toolApp) {
        if (ToolAppKeys.indexOf(params) !== -1) {
          setToolApp(params as ToolApps);
        } else if (toolApp !== 'NONE') {
          setToolApp(RESET);
        }
      }
    }
  }, [params, toolApp, setToolApp]);
  return (
    <Router className="h-full">
      <ImageVerifaiRoute path={`${APP_ImageVerifai}`} />
      {/* <TrendSummarizationRoute path={`${APP_TrendSummarization}`} />
      <ChannelSummaryRoute path={APP_ChannelSummary} /> */}
      <Home path="/" />
      <PageNotFound default />
    </Router>
  );
};

export default SinglePageTools;
