import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePageTitle from '../../../gatsby-theme-zefr/hooks/usePageTitle';
import { APP_ImageVerifai } from '../../atoms/toolsAtom';
import ImageVerifai from '../../components/ImageVerifai';
import Layout from '../../components/Layout';

interface ImageVerifaiRouteProps extends RouteComponentProps {}

// This component holds all locations for our app that uses authenticated client-only routes
export const ImageVerifaiRoute: React.FC<ImageVerifaiRouteProps> = ({ ...props }) => {
  const { t } = useTranslation();
  usePageTitle(t(`growth.tools.${APP_ImageVerifai}`));
  return (
    <Layout display={'flex'} {...props}>
      <ImageVerifai />
    </Layout>
  );
};

export default ImageVerifaiRoute;
