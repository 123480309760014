/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import React, { useCallback, useEffect, useRef } from 'react';

import { Box, Button } from '@mui/material';
import { InputAdornment, InputAdornmentProps } from '@zefr/style-guide/dist/core/Input';
import TextField, { TextFieldProps } from '@zefr/style-guide/dist/core/TextField';
import { SearchIcon } from '@zefr/style-guide/dist/core/icons';
import { colors } from '@zefr/style-guide/dist/core/system';
import { darken, styled } from '@zefr/style-guide/dist/ui/styles';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  backgroundColor: colors.white,
  borderRadius: '8px',
  paddingRight: 0,
  transition: 'all 500ms ease-in-out',
  width: '100%',
  border: 'none',
  '& .MuiInputBase-input': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StartAdornment = styled(InputAdornment)<InputAdornmentProps>(() => ({
  marginLeft: '4px',
  svg: {
    fill: colors.blue,
  },
}));

interface CustomElements extends HTMLFormControlsCollection {
  value: HTMLInputElement;
}

interface CustomForm extends HTMLFormElement {
  readonly elements: CustomElements;
}

export interface EndAdornmentProps extends InputAdornmentProps {
  disabled?: boolean;
}

const EndAdornment: React.FC<EndAdornmentProps> = (props) => <InputAdornment {...props} />;

const StyledEndAdornment = styled(EndAdornment)<EndAdornmentProps>(({ disabled }) => {
  const backgroundColor = disabled ? colors.lightGrey : colors.blue;
  const hoverBackgroundColor = !disabled && darken(colors.blue, 0.05);
  const color = disabled ? colors.grey : colors.white;
  const cursor = disabled ? 'not-allowed' : 'pointer';

  return {
    backgroundColor,
    borderRadius: '8px',
    cursor,
    marginRight: '1px',
    padding: '19px 21px',
    '&:hover': {
      backgroundColor: hoverBackgroundColor,
    },
    p: {
      color,
    },
  };
});

export interface TextInputBarProps extends Omit<TextFieldProps, 'onChange'> {
  onSubmit?: <T>(inputValue: string, event: React.FormEvent<T>) => void;
  onChange?: (
    searchTerm: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => string | undefined | void;
  endAdornment?: JSX.Element | null;
  startAdornment?: JSX.Element | null;
  endAdornmentTypography?: React.ReactNode;
}

export const TextInputBar: React.FC<TextInputBarProps> = ({
  onSubmit = (searchTerm) => {
    console.log('Submitted', searchTerm);
  },
  onChange = () => {},
  disabled = false,
  value,
  startAdornment: startAdornmentProp,
  endAdornment: endAdornmentProp,
  endAdornmentTypography,
  ...props
}) => {
  const { t } = useTranslation();
  /* #region 🐙 States */
  const [internalValue, setInternalValue] = React.useState('');
  const [isSearchDisabled, setIsSearchDisabled] = React.useState(false);
  const formRef = useRef<HTMLButtonElement>(null);
  /* #endregion */

  /* #region 🎃 Events Handlers */
  const internalOnChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    React.useCallback(
      (e) => {
        const newValue = e.target.value;
        const modifiedValue = onChange(newValue, e);
        const finalValue = modifiedValue || newValue;
        setInternalValue(finalValue);
      },
      [onChange, setInternalValue],
    );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e: React.FormEvent<CustomForm>) => {
      e.preventDefault();
      const target = e.currentTarget.elements;
      const data = {
        value: target.value.value,
      };
      onSubmit(data.value, e);
    },
    [onSubmit],
  );
  /* #endregion */

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  useEffect(() => {
    setIsSearchDisabled(disabled);
  }, [disabled, setIsSearchDisabled]);

  return (
    <Box component={'form'} width={'100%'} onSubmit={handleSubmit}>
      <StyledTextField
        disabled={disabled}
        fullWidth
        name="value"
        size="large"
        InputProps={{
          startAdornment:
            typeof startAdornmentProp === 'undefined' ? (
              <StartAdornment position={'end'}>
                <SearchIcon size="20px" />
              </StartAdornment>
            ) : startAdornmentProp === null ? undefined : (
              startAdornmentProp
            ),
          endAdornment:
            typeof endAdornmentProp === 'undefined' ? (
              <StyledEndAdornment
                disabled={isSearchDisabled}
                onClick={() => {
                  formRef.current?.click();
                }}
                position="end"
              >
                {endAdornmentTypography || t('submit')}
              </StyledEndAdornment>
            ) : endAdornmentProp === null ? undefined : (
              endAdornmentProp
            ),
        }}
        onChange={internalOnChange}
        value={internalValue}
        {...props}
      />
      <Button sx={{ display: 'none' }} type="submit" ref={formRef} />
    </Box>
  );
};

export default TextInputBar;
