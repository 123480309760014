import { Box } from '@mui/material';
import ScoreCard, { ScoreCardProps } from '@zefr/style-guide/dist/ui/ScoreCard';
import ScoreCardTableRow, {
  makeSuitabilityRow,
} from '@zefr/style-guide/dist/ui/ScoreCard/ScoreCardTableRow';
import { GARM } from '@zefr/style-guide/dist/ui/types';
import {
  HIGH,
  LOW,
  MEDIUM,
  NO_RISK,
  RISK_LEVEL_MAP,
} from '@zefr/style-guide/dist/ui/types/atrium/garm';
import React, { useMemo } from 'react';

const makeRiskLevelMap = ({
  title,
  noRisk,
  low,
  medium,
  high,
}: {
  title?: string;
  noRisk: string;
  low: string;
  medium: string;
  high: string;
}) => ({
  title,
  [NO_RISK]: noRisk,
  [LOW]: low,
  [MEDIUM]: medium,
  [HIGH]: high,
});

const WEAPONS = 'weapons';
const CRIME = 'crime';
const DEATH = 'death_injury';
const DRUGS = 'drugs';
const HATE = 'hate_speech';
const MISINFORMATION = 'misinformation';
const PROFANITY = 'profanity';
const PIRACY = 'online_piracy';
const ADULT = 'adult_content';
const SOCIAL = 'social_issues';
const SPAM = 'spam';
const TERRORISM = 'terrorism';

export const CATEGORIES_DESCRIPTIONS_MAP: Partial<Record<string, RISK_LEVEL_MAP>> = {
  [WEAPONS]: makeRiskLevelMap({
    title: 'Arms & Ammunition',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of Arms use, possession or illegal sale. News feature stories on the subject.',
    medium:
      'Dramatic depiction of weapons use presented in the context of entertainment. Breaking News or Op–Ed coverage of arms and ammunition.',
    high: 'Glamorization/Gratuitous depiction of illegal sale or possession of Arms. Depictions of sale/use/distribution of illegal arms for inappropriate uses/harmful acts.',
  }),
  [CRIME]: makeRiskLevelMap({
    title: 'Crime & Harmful Acts To Individuals & Society, Human Rights Violations',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of crime or criminal acts or human rights violations. News feature stories on the subject.',
    medium:
      'Dramatic depiction of criminal activity or human rights violations presented in the context of entertainment. Breaking News or Op–Ed coverage of criminal activity or human rights violations.',
    high: 'Depictions of criminal/harmful acts or violation of human rights.',
  }),
  [DEATH]: makeRiskLevelMap({
    title: 'Death, Injury or Military Conflict',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of Arms use, possession or illegal sale. News feature stories on the subject.',
    medium:
      'Dramatic depiction of death, injury, or military conflict presented in the context of entertainment. Breaking News or Op–Ed coverage of death, injury or military conflict.',
    high: 'Depiction of death or Injury. Insensitive and irresponsible treatment of military conflict, genocide, war crimes, or harm resulting in Death or Injury. Depictions of military actions that glamorize harmful acts to others or society.',
  }),
  [DRUGS]: makeRiskLevelMap({
    title: 'Illegal Drugs, Tobacco, E-cigarettes, Vaping & Alcohol',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of illegal drug use/prescription abuse, tobacco, vaping or alcohol. News feature stories on the subject.',
    medium:
      'Dramatic depiction of illegal drug use/prescription abuse, tobacco, vaping or alcohol use presented in the context of entertainment. Breaking News or Op–Ed coverage of illegal drug use/prescription abuse, tobacco, vaping or alcohol use.',
    high: 'Glamorization/Gratuitous depictions of illegal Vaping/Alcohol drugs/abuse of prescription drugs. Insensitive and irresponsible content/treatment that encourages minors to use tobacco and vaping products & Alcohol.',
  }),
  [HATE]: makeRiskLevelMap({
    title: 'Hate Speech & Acts of Aggression',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of Hate Speech. News features on the subject.',
    medium:
      'Dramatic depiction of hate speech/acts presented in the context of entertainment. Breaking News or Op–Ed coverage of hate speech/acts.',
    high: 'Insensitive and irresponsible treatment or depiction of harm or insult from hateful speech/acts.',
  }),
  [MISINFORMATION]: makeRiskLevelMap({
    title: 'Misinformation',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of misinformation. News features describing various misinformation or disinformation campaigns as such.',
    medium:
      'Dramatic depiction of misinformation presented in the context of Entertainment, Breaking News or Op–Ed coverage of disinformation.',
    high: 'Glamorization/Gratuitous depiction of misinformation. (High and Floor are similarly defined and therefore this selection is unavailable).',
  }),
  [PROFANITY]: makeRiskLevelMap({
    title:
      'Obscenity & Profanity (Including language, gestures, and explicitly gory, graphic or repulsive content intended to shock and disgust)',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational or Informative, treatment of Obscenity or Profanity. News feature stories on the subject.',
    medium:
      'Dramatic depiction of profanity and obscenities presented in the context of entertainment by genre. Breaking News or Op–Ed coverage of profanity and obscenities. Genre based use of profanity, gestures, and other actions that may be strong, but might be expected as generally accepted language and behavior.',
    high: 'Glamorization/Gratuitous depiction of profanity and obscenity.',
  }),
  [PIRACY]: makeRiskLevelMap({
    title: 'Online Piracy',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of Online Piracy. News feature stories on the subject.',
    medium:
      'Dramatic depiction of Online Piracy presented in the context of entertainment. Breaking News or Op–Ed coverage of Online Piracy.',
    high: 'Glamorization/Gratuitous depiction of Online Piracy.',
  }),
  [ADULT]: makeRiskLevelMap({
    title: 'Adult & Explicit Sexual Content',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of sexual subjects or sexual relationships or sexuality.',
    medium:
      'Dramatic depiction of sexual acts or Sexuality issues presented in the context of entertainment. Artistic Nudity.',
    high: 'Suggestive sexual situations requiring adult supervision/approval or warnings. Full or liberal Nudity.',
  }),
  [SOCIAL]: makeRiskLevelMap({
    title: 'Debated Sensitive Social Issues',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of debated sensitive social issues and related acts. News feature stories on the subject.',
    medium:
      'Dramatic depiction of debated social issues presented in the context of entertainment. Breaking News or Op–Ed coverage of partisan advocacy of a position on debated sensitive social issues.',
    high: 'Depiction or discussion of debated social issues and related acts in negative or partisan context.',
  }),
  [SPAM]: makeRiskLevelMap({
    title: 'Spam',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of Spam or Malware News feature stories on the subject.',
    medium:
      'Dramatic depiction of Spam or Malware presented in the context of entertainment. Breaking News or Op–Ed coverage of Spam or Malware.',
    high: 'Glamorization/Gratuitous depiction of Spam or Malware.',
  }),
  [TERRORISM]: makeRiskLevelMap({
    title: 'Terrorism',
    noRisk: 'No risk means content does not expressly include reference to this category.',
    low: 'Educational, Informative, Scientific treatment of terrorism. News feature stories on the subject.',
    medium:
      'Dramatic depiction of terrorism presented in the context of entertainment. Breaking News or Op–Ed coverage of acts of terrorism.',
    high: 'Depiction of terrorist actions that are disturbing, agitating or promotes harmful acts to others or society. Terrorist content requiring a viewer advisory Insensitive and irresponsible treatment of terrorism/related crimes.',
  }),
};

export interface ScoreData {
  riskLevel: GARM.RiskLevel;
  unsuitable?: boolean;
}
export interface GarmScoreCardProps {
  scoreData?: Partial<Record<string, ScoreData>>;
  scoreCardProps?: Partial<ScoreCardProps>;
}

const MAP = CATEGORIES_DESCRIPTIONS_MAP;
const GarmScoreCard: React.FC<GarmScoreCardProps> = ({ scoreData = {}, scoreCardProps = {} }) => {
  const rows = useMemo(
    () =>
      Object.keys(MAP).map((k) => {
        const title = MAP[k as keyof typeof MAP]?.title;
        const data = scoreData[k as keyof typeof MAP];
        const riskLevel =
          data?.riskLevel && data.riskLevel !== 'floor' ? data.riskLevel : GARM.NO_RISK;
        const children: React.ReactNode[] = makeSuitabilityRow(riskLevel, data?.unsuitable);
        const descriptionData = MAP[k as string];
        let description;
        let descriptionTitle;
        if (descriptionData) {
          description = descriptionData[riskLevel];
          descriptionTitle = descriptionData.title
            ? `${descriptionData.title} for "${GARM.RISK_LEVEL_MAP[riskLevel]}" includes:`
            : undefined;
        }

        return (
          <ScoreCardTableRow
            categoryTitle={title}
            descriptionTitle={descriptionTitle}
            description={description}
          >
            {children}
          </ScoreCardTableRow>
        );
      }),
    [scoreData],
  );
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexGrow: 1,
        '& .ScoreCardTable, & .ScoreCardTable > .Container': {
          width: '100%',
        },
        '& .ScoreCardTable *': {
          fontFamily: theme.typography.fontFamily,
        },
      })}
    >
      <ScoreCard
        headers={['Categories', ...Object.values(GARM.TABLE_RISK_LEVEL_MAP)]}
        {...scoreCardProps}
      >
        {rows}
      </ScoreCard>
    </Box>
  );
};

export default GarmScoreCard;
