import { ChevronRight } from '@mui/icons-material';
import { Box, Chip, ChipProps, Stack, StackProps, Typography } from '@mui/material';
import { reverse, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useDemoForm } from '../../../../context/DemoFormContext';

export interface ConceptsCellProps extends StackProps {
  values?: Record<string, number>;
  isExclusion?: boolean;
}

export const ConceptChip: React.FC<ChipProps> = ({ className, color = 'secondary', ...props }) => {
  return (
    <Chip
      color={color}
      className={`concept-chip${className ? ` ${className}` : ''}`}
      sx={(theme) => {
        return {
          height: 'fit-content',
          '& .MuiChip-label': {
            //   padding: theme.spacing(2),
          },
          '&.MuiChip-root, & .MuiTypography-root, & .MuiChip-label': {
            fontSize: theme.typography.subtitle2.fontSize,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
          },
        };
      }}
      {...props}
    />
  );
};

const ConceptsCell: React.FC<ConceptsCellProps> = ({
  values = {},
  isExclusion = false,
  ...props
}) => {
  const { apiConceptsBySlug, threshold } = useDemoForm();
  const visibleThreshold = useMemo(() => threshold || 0.7, [threshold]);

  const sortedItems = useMemo(() => {
    const itemKeys = Object.keys(values);
    const unsortedItems: { key: string; value: number }[] = [];
    itemKeys.forEach((key) => {
      const value = values[key];
      if (value >= visibleThreshold) {
        unsortedItems.push({
          key,
          value,
        });
      }
    });
    return reverse(sortBy(unsortedItems, 'value'));
  }, [values]);

  const itemCount = useMemo(() => {
    return sortedItems.length;
  }, [sortedItems]);

  return (
    <Stack
      gap={1}
      direction={'row'}
      flexWrap={'wrap'}
      overflow={'hidden'}
      textOverflow={'unset'}
      height={'100%'}
      justifyContent={'flex-start'}
      alignContent={'flex-start'}
      py={1}
      boxSizing={'border-box'}
      {...props}
    >
      {sortedItems.map(({ key, value }) => {
        return (
          <ConceptChip
            key={key}
            id={key}
            color={isExclusion ? 'error' : 'secondary'}
            label={
              <>
                {apiConceptsBySlug[key].title}:{' '}
                <Typography color="inherit" component={'span'} fontWeight={'bold'}>
                  {(value * 100).toFixed(0)}
                </Typography>
              </>
            }
          />
        );
      })}
      {itemCount > 1 && (
        <ConceptChip
          id={'overflow-chip'}
          color={isExclusion ? 'error' : 'secondary'}
          label={
            <Box display="flex" justifyContent={'center'} alignItems={'center'}>
              {itemCount - 1} more <ChevronRight fontSize="inherit" />
            </Box>
          }
        />
      )}
    </Stack>
  );
};

export default ConceptsCell;
