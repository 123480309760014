import Grid2, { Grid2Props } from '@mui/material/Unstable_Grid2';
import React from 'react';
import LanguageSelect from '../DemoComponents/Form/LanguageSelect';

const TargetAudienceLanguageSelect: React.FC<Grid2Props> = ({ ...props }) => {
  return (
    <Grid2 xs={12} md={6} {...props}>
      <LanguageSelect />
    </Grid2>
  );
};

export default TargetAudienceLanguageSelect;
