import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

const StyledChipDots = styled(Box)(() => {
  const fontSize = '6px';
  return {
    backgroundColor: 'grey',
    borderRadius: '25px 25px 25px 25px',
    padding: '1px',
    '&': {
      lineHeight: '4px',
      margin: 0,
      '& > *': {
        borderRadius: '50%',
        width: fontSize,
        height: fontSize,
      },
    },
  };
});

export interface ChipDotsOverviewProps extends BoxProps {}

const ChipDotsOverview: React.FC<ChipDotsOverviewProps> = ({
  display = 'flex',
  flexDirection = 'column',
  justifyContent = 'flex-start',
  alignItems = 'center',
  gap = '1px',
  className = '',
  children,
  ...props
}) => {
  return (
    <StyledChipDots
      className={`chip-dots ${className ? ` ${className}` : ''}`}
      {...props}
      {...{ display, flexDirection, justifyContent, alignItems, gap }}
    >
      {children}
    </StyledChipDots>
  );
};
export default ChipDotsOverview;
