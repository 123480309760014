import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useDemoForm } from '../../../context/DemoFormContext';
import { Option } from '../../../types/launchDarkly';

export interface RiskSelectProps extends Omit<SelectProps, 'onChange'> {
  label?: React.ReactNode;
  options?: Option[];
}

const RiskSelect: React.FC<RiskSelectProps> = ({ label, options = [], ...props }) => {
  const { risk, setFormRisk } = useDemoForm();

  const handleChangeRisk = useCallback(
    (event: SelectChangeEvent<string>) => {
      setFormRisk(event.target.value as string);
    },
    [setFormRisk],
  );
  return (
    <FormControl className="zefr-form" fullWidth size={'medium'}>
      <InputLabel id="demo-risk-label">{label}</InputLabel>
      <Select
        value={risk || ''}
        onChange={handleChangeRisk as any}
        labelId="demo-risk-label"
        MenuProps={{
          MenuListProps: {
            className: 'zefr-form',
          },
        }}
        label={label}
        error={!risk}
        {...props}
      >
        {options.map(({ label, ...data }) => {
          return <MenuItem {...data}>{label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
export default RiskSelect;
