export interface PlatformOption {
    details: string[];
    labelIcon: string;
    learnMore: {
        body: string;
        title: string;
    };
    logoIcon: string;
    value: string;
}

const PLATFORM_OPTIONS: PlatformOption[] = [
    {
        details: [
            'Zefr Dynamic Scripting for Automatic Content Optimizations to Improve Performance',
            'Impression-Level Transparency',
            'Precision Placement Targeting',
        ],
        labelIcon: 'YouTubeTextIcon',
        learnMore: {
            body: `Optimized inclusions or exclusions across Google Ads using Zefr technology, with pre-bid settings + consistent optimization that improves your effective audience reach in suitable environments.`,
            title: 'Google Ads Campaign Activation',
        },
        logoIcon: 'GoogleAdsTextIcon',
        value: 'youtube',
    },
    {
        details: [
            'Zefr AI Powered Optimizations',
            'Enhance Safety & Suitability',
            'Flexible Audience Targeting & Content Controls',
        ],
        labelIcon: 'YouTubeTextIcon',
        learnMore: {
            body: `Optimized inclusions or exclusions across DV360 using Zefr technology, with pre-bid settings + consistent bid and content optimization that improves your effective audience reach in suitable environments.`,
            title: 'DV360 for Campaign Activation',
        },
        logoIcon: 'DV360TextIcon',
        value: 'dv360',
    },
];

export { PLATFORM_OPTIONS };
