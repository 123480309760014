import { Box } from '@mui/material';
import React from 'react';
import { useDemoForm } from '../../../../context/DemoFormContext';
import FormStepBox from '../FormStepBox';
import { FormTitle } from '../components';
import { FormStepProps } from '../types';
import KpiPreferences from './KpiPreferences';

export const FormStepKpiDetails: React.FC<FormStepProps> = ({ step: myStep = 2 }) => {
  const { kpiKeys } = useDemoForm();

  return (
    <FormStepBox
      titleElement={<FormTitle marginBottom={6}>What do you want to optimize towards?</FormTitle>}
      step={myStep}
      isNextDisabled={!Boolean(kpiKeys)}
    >
      <Box display={'flex'} flexDirection={'column'} gap={2} width={'100%'} height="100%">
        <KpiPreferences />
      </Box>
    </FormStepBox>
  );
};

export default FormStepKpiDetails;
