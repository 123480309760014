import {
  Box,
  Divider,
  DividerProps,
  Paper,
  Stack,
  Typography,
  TypographyProps,
  darken,
  styled,
} from '@mui/material';
import React, { useMemo } from 'react';
import { TEXT_GREY } from '../../../../gatsby-theme-zefr/theme';

const ATRIUM_START_COLOR = '#01A2FF';
const ATRIUM_END_COLOR = '#01D8D7';

const ATRIUM_RED_START_COLOR = '#95280F';
const ATRIUM_RED_END_COLOR = '#FA6F4F';

export const DIVIDER_COLORS: Record<string, Record<'startColor' | 'endColor', string>> = {
  brand: {
    startColor: ATRIUM_START_COLOR,
    endColor: ATRIUM_END_COLOR,
  },
  red: {
    startColor: ATRIUM_RED_START_COLOR,
    endColor: ATRIUM_RED_END_COLOR,
  },
};

export const ATRIUM_BACKGROUND_GRADIENT = `linear-gradient(90deg, ${DIVIDER_COLORS.brand.startColor} 4.35%, ${DIVIDER_COLORS.brand.endColor} 82.61%)`;

export const FORM_LINEAR_GRADIENTS = {
  left: `linear-gradient(115.41deg, #C6D5EF 8.49%, #FFFFFF 102.75%);`,
  right: `linear-gradient(115.41deg, #FFFFFF 8.49%, #C6D5EF 102.75%);`,
  top: `linear-gradient(304.38deg, #C6D5EF -32.96%, #FFFFFF 52.08%)`,
};

const StyledFormTitle = styled(Typography)(({ theme, color }) => ({
  color: typeof color === 'undefined' ? theme.palette.secondary.main : (color as string),
}));

export const FormTitle: React.FC<TypographyProps> = ({
  textAlign = 'center',
  variant = 'h1',
  fontWeight = '700',
  ...props
}) => {
  const originalProps = useMemo(() => {
    return {
      textAlign,
      variant,
      fontWeight,
    };
  }, [textAlign, variant, fontWeight]);
  return <StyledFormTitle {...props} {...originalProps} />;
};

export const ClickablePaper = styled(Paper)<{ active?: boolean; disabled?: boolean }>(({
  theme,
  active,
  disabled,
}) => {
  const borderColor = active ? theme.palette.primary.main : undefined;
  const borderWidth = active ? '1px' : 0;
  return {
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    minWidth: '225px',
    maxWidth: '670px',
    minHeight: '300px',
    width: '100%',
    height: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .has-active-state .MuiTypography-root': {
      fontWeight: active ? 'bold' : undefined,
    },
    '& .has-responsive-state .MuiSvgIcon-root': {
      fontSize: '2.5rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      '&.big': {
        fontSize: '4rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '5rem',
        },
      },
    },
    '& .has-disabled-state .MuiSvgIcon-root, & .follows-disabled-state': {
      opacity: disabled ? 0.25 : undefined,
    },
    borderColor,
    borderWidth,
    backgroundColor: disabled ? theme.palette.background.default : undefined,
    boxShadow: active ? `0px 4px 30px 0px rgba(1, 162, 255, 0.50)` : undefined,
    transition: theme.transitions.create(
      ['background-color', 'color', 'border-color', 'box-shadow'],
      {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      },
    ),
    '& .MuiTypography-root, & .MuiSvgIcon-root, & hr': {
      userSelect: 'none',
      transition: theme.transitions.create(
        ['transform', 'font-weight', 'border-width', 'border-color', 'box-shadow'],
        {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        },
      ),
    },
    '&:hover': {
      '& .MuiTypography-root:not(li), & .MuiSvgIcon-root, & hr': {
        transform: 'scale(1.1)',
      },
      cursor: disabled ? 'not-allowed' : 'pointer',
      borderColor: active ? theme.palette.primary.main : 'transparent',
      borderWidth: active ? '1.5px' : undefined,
      boxShadow: `0px 4px 40px 0px rgba(0, 0, 0, 0.2)`,
    },
    '&:active': {
      backgroundColor: darken(
        disabled ? theme.palette.background.default : theme.palette.background.paper,
        0.05,
      ),
    },
  };
});

export const IconOrLabelBox = styled(Box)(({ theme }) => {
  return {
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  };
});

export const LearnMoreBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(0),
    cursor: 'pointer',
    userSelect: 'none',
    '& .MuiTypography-root, & *': {
      userSelect: 'none',
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    '&:hover': {
      '& *, & .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiTypography-root, & *': {
        transform: 'scale(1.1)',
      },
    },
    '&:active': {
      '& *, & .MuiTypography-root': {
        color: theme.palette.primary.dark,
      },
    },
    '& *, & .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  };
});

export const DetailsList = styled('ul')(({ theme }) => {
  return {
    paddingInlineStart: '30px',
    '& li': {
      transition: theme.transitions.create(['textDecoration'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
      fontSize: theme.typography.body1.fontSize,
      fontWeight: '400',
      color: `${TEXT_GREY}`,
      '&:not(:first-child)': {
        paddingTop: '5px',
      },
      '&:hover': {
        // textDecoration: 'underline',
      },
    },
  };
});

export const CardStack = styled(Stack)(({ theme }) => {
  return {
    flexWrap: 'wrap',
    flexDirection: 'row',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'start',
    gap: theme.spacing(8),
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    maxHeight: '43vh',
    [theme.breakpoints.up('md')]: {
      maxHeight: 'unset',
    },
  };
});

export const ColoredDivider = styled(Divider)<{ startColor?: string; endColor?: string }>(({
  theme,
  startColor = ATRIUM_START_COLOR,
  endColor = ATRIUM_END_COLOR,
}) => {
  return {
    width: '3.25rem',
    borderRadius: '25px 25px 25px 25px',
    height: '6px',
    background: `linear-gradient(90deg, ${startColor} 4.35%, ${endColor} 82.61%)`,
    color: 'transparent',
    borderColor: 'transparent',
  };
});

export interface ColoredDividerProps extends DividerProps {
  startColor?: string;
  endColor?: string;
}

export const RedDivider: React.FC<ColoredDividerProps> = ({
  startColor = DIVIDER_COLORS.red.startColor,
  endColor = DIVIDER_COLORS.red.endColor,
  ...props
}) => {
  return <ColoredDivider {...{ startColor, endColor }} {...props} />;
};
