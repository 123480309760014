import React from 'react';
export interface TopNavTab {
  label: string;
  value: string;
  link?: string;
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  target?: string;
}

export type TopNavTabs = TopNavTab[];

export const SITE_APP_NONE = 'NONE';
export const SITE_APP_ATRIUM = 'ATRIUM';
export const SITE_APP_SETTINGS = 'SETTINGS';
export const SITE_APP_GROWTH = 'GROWTH';
export const SITE_APP_DEMO = 'DEMO';

export type SiteApps =
  | typeof SITE_APP_NONE
  | typeof SITE_APP_ATRIUM
  | typeof SITE_APP_SETTINGS
  | typeof SITE_APP_GROWTH
  | typeof SITE_APP_DEMO;
