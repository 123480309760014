import { datadogLogs } from '@datadog/browser-logs';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getInclusionChannelsList } from '../../../api/config/growth/v0';
import { postApiFn } from '../../../api/helper';
import { ProxyChannelsListResponse } from '../../../types/proxyApi';

export const QUERY_KEY = 'get-inclusion-channels';

interface RequestParams {
  page?: number;
  include_concepts?: string[];
  exclude_concepts?: string[];
  threshold?: number;
  limit?: number;
  languages?: string[];
  sort_field?: string;
  sort_dir?: 'asc' | 'desc';
  maximum_risk_level?: number;
  enabled?: boolean;
}

type Response = ProxyChannelsListResponse;

const useInclusionChannelsList = ({
  page = 1,
  limit = 1000, // 10k is server default if no value provided
  include_concepts = [],
  exclude_concepts = [],
  threshold = 0.7,
  sort_field,
  sort_dir,
  enabled = false,
  maximum_risk_level = 3,
  languages = [],
  ...params
}: RequestParams) => {
  const path = getInclusionChannelsList();
  const safePage = Math.max(0, page);
  const offset = limit * safePage;
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: [
      QUERY_KEY,
      `limit=${limit}`,
      `offset=${offset}`,
      `included_concepts=${include_concepts.join('')}`,
      `excluded_concepts=${exclude_concepts.join('')}`,
      `threshold=${threshold}`,
      `risk=${maximum_risk_level}`,
      `languages=${languages}`,
      `sort_dir=${sort_dir}`,
      `sort_field=${sort_field}`,
      `params=${params}`,
    ],
    queryFn: async () => {
      return await postApiFn(path, {
        ...params,
        languages,
        include_concepts,
        exclude_concepts,
        threshold,
        sort_field,
        sort_dir,
        limit,
        offset,
        maximum_risk_level: Math.min(Math.max(0, maximum_risk_level), 3),
      })
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results for the channels list', { res });
          return res;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results for the concepts list', {
            error,
          });
          throw new Error(error);
        });
    },
    // Only make the request once, and keep the results for the rest of our form creation
    retry: 0,
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export default useInclusionChannelsList;
