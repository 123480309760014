import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import Link from '../../../gatsby-theme-zefr/components/Link';
import useChannelIdImages from '../../hooks/api/v0/useChannelIdImages';

const TableImage = styled('img')(({ theme }) => ({
  display: 'block',
  maxWidth: '150px',
  maxHeight: '75px',
  width: 'auto',
  height: 'auto',
  transition: theme.transitions.create(['max-height', 'transform'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    transform: 'scale(1.1)',
    maxHeight: 'unset',
    transition: theme.transitions.create(['max-height'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}));

export interface VideoTableChannelIdCellProps {
  channelId: string;
}
const VideoTableChannelIdCell: React.FC<VideoTableChannelIdCellProps> = ({ channelId }) => {
  const { data, isLoading, isFetched } = useChannelIdImages({ channel_ids: [channelId] });

  const thumbnailSrc = useMemo(() => {
    if (isFetched) {
      if (data) {
        return data[channelId]?.thumbnail;
      }
    }
    return `/images/youtube-placeholder.jpeg`;
  }, [data, isFetched, isLoading, channelId]);
  return (
    <Link to={`https://youtube.com/channel/${channelId}`} target={'_blank'}>
      <TableImage src={thumbnailSrc} />
    </Link>
  );
};

export default VideoTableChannelIdCell;
