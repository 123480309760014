import { Box, BoxProps, styled } from '@mui/material';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import { DemoFilledWidthFlag } from '../../types/launchDarkly';
import { DIVIDER_COLORS } from './Form/components';

const StepLineWrapper = styled(Box)(() => {
  return {
    flexGrow: 1,
    float: 'left',
    width: 0,
    minWidth: '75px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
  };
});

const StepLineContent = styled(Box)(() => {
  return {
    gridArea: '2 / 1 / 2 / 2',
    alignSelf: 'center',
    height: '8px',
  };
});

const StepLineDots = styled(StepLineContent)(({ theme }) => {
  return {
    backgroundColor: 'white',
    boxShadow: `inset 0 1px 1px #CED5E8`,
    // backgroundImage: `radial-gradient(${theme.palette.action.active} 2px, transparent 0)`,
    // backgroundSize: '12px 9px',
    // backgroundPosition: '0px -9.5px',
    width: '100%',
    borderRadius: '5px 5px 5px 5px',
  };
});

const StepLineFilled = styled(StepLineContent)<{
  filled?: number;
  startColor?: string;
  endColor?: string;
}>(({
  filled,
  theme,
  startColor = DIVIDER_COLORS.brand.startColor,
  endColor = DIVIDER_COLORS.brand.endColor,
}) => {
  const hasFilled = typeof filled !== 'undefined';
  const filledPercent = hasFilled ? Math.max(0, Math.min(100, filled)) : 0;
  return {
    background: `linear-gradient(90deg, ${startColor} 4.35%, ${endColor} 82.61%)`,
    width: `${filledPercent}%`,
    borderRadius: '5px 5px 5px 5px',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  };
});

const DEFAULT_FILLED_WIDTH = 15;

export interface StepLineProps extends BoxProps {
  stepPassed?: boolean;
  stepActive?: boolean;
  stepCompleted?: boolean;
}

const StepLine: React.FC<StepLineProps> = ({ stepPassed, stepActive, stepCompleted, ...props }) => {
  const { nextStep, step } = useDemoForm();
  const flags = useFlags();
  const defaultFilledWidth = useMemo(() => {
    return flags[DemoFilledWidthFlag] || DEFAULT_FILLED_WIDTH;
  }, [flags]);

  const filledWidth = useMemo(() => {
    if (stepPassed) {
      return 100;
    }
    if (stepCompleted) {
      return 75;
    }
    if (stepActive) {
      return defaultFilledWidth;
    }
    return undefined;
  }, [stepActive, stepPassed, defaultFilledWidth, nextStep, step, stepCompleted]);

  return (
    <StepLineWrapper {...props}>
      <StepLineDots />
      <StepLineFilled filled={filledWidth} />
    </StepLineWrapper>
  );
};

export default StepLine;
