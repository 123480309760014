import { Check } from '@mui/icons-material';
import { Box, Stack, StackProps, styled } from '@mui/material';
import { Typography } from '@zefr/style-guide';
import { TOP_NAV_HEIGHT } from '@zefr/style-guide/dist/ui/TopNav';
import React, { useEffect, useMemo } from 'react';
import { MAX_DEMO_WIDTH, MAX_DEMO_WIDTH_XL } from '../../../gatsby-theme-zefr/theme';
import { useDemoForm, FINAL_STEP } from '../../context/DemoFormContext';
import StepLine from './StepLine';
import StepNumberAvatar from './StepNumberAvatar';

interface StepData {
  data: React.ReactNode;
  step: number;
  multiStep?: boolean;
}
const stepDataObj: StepData[] = [
  {
    data: <>Platform Placement</>,
    step: 1,
    multiStep: true,
  },
  {
    data: <>Optimize</>,
    step: 2,
    multiStep: true,
  },
  {
    data: <>Complete Details</>,
    step: 3,
    multiStep: true,
  },
];

const StepItem = styled(Box)<{
  active?: boolean;
  isCompleted?: boolean;
  stepPassed?: boolean;
  isDisabled?: boolean;
}>(({ theme, active, onClick, isCompleted, stepPassed, isDisabled: disabled }) => {
  const hasClick = typeof onClick === 'function';

  const specialBgColor = disabled
    ? theme.palette.action.disabled
    : stepPassed && !active
      ? theme.palette.success.main
      : theme.palette.primary.main;
  return {
    backgroundColor: 'transparent',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    cursor: hasClick ? (disabled ? 'not-allowed' : 'pointer') : undefined,
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    margin: `0 ${theme.spacing(4)}`,
    boxSizing: 'border-box',
    transform: active ? `scale(1.1)` : undefined,
    '&:hover': disabled
      ? undefined
      : {
          transform: `scale(1.1)`,
        },
    '& .MuiTypography-root': {
      color: active
        ? theme.palette.primary.main
        : // : filled
          //   ? theme.palette.primary.dark
          //   : disabled
          //     ? theme.palette.action.disabled
          theme.palette.action.disabled,
      fontWeight: '600',
      userSelect: 'none',
      transition: theme.transitions.create(['background-color', 'color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    '& .MuiAvatar-root': {
      height: '25px',
      width: '25px',
      color: theme.palette.common.white,
      fontWeight: '600',
      transition: theme.transitions.create(
        ['background-color', 'color', 'background', 'border-color', 'border-width'],
        {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        },
      ),
      borderWidth: isCompleted ? '4px' : '6px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      boxSizing: 'border-box',
      background: `linear-gradient(${specialBgColor}, ${specialBgColor}) padding-box, linear-gradient(142deg, ${'white'} 35%, rgba(177, 186, 207, 1) 70%) border-box`,
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
  };
});

const StepBar = styled(Stack)(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowX: 'auto',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    maxWidth: MAX_DEMO_WIDTH,
    [theme.breakpoints.up('lg')]: {
      maxWidth: MAX_DEMO_WIDTH_XL,
    },
  };
});

export interface DemoStepsBarProps extends StackProps {}

const DemoStepsBar: React.FC<DemoStepsBarProps> = ({
  display = 'flex',
  flexDirection = 'row',
  justifyContent = 'flex-start',
  ...props
}) => {
  const { step, setStep, nextStep, getStepIsDisabled } = useDemoForm();
  const originalProps = useMemo(() => {
    return {
      display,
      flexDirection,
      justifyContent,
    };
  }, [display, flexDirection, justifyContent]);

  const stepElements = useMemo(() => {
    const finalStepElements: React.ReactNode[] = [];
    stepDataObj.forEach(({ data, step: currentStep, multiStep }, index) => {
      const nextStep = stepDataObj[index + 1]?.step || FINAL_STEP;
      // The states
      // 1. disabled and small with no icon
      // 2. active and small with no icon
      // 3. completed and big with icon
      // 4. active and completed and big with icon
      const isCompleted = multiStep
        ? !getStepIsDisabled(nextStep) && !getStepIsDisabled(currentStep + 1)
        : !getStepIsDisabled(currentStep + 1);
      const active = multiStep ? step >= currentStep && step < nextStep : step === currentStep;
      const stepPassed = multiStep ? step >= nextStep : step > currentStep;
      const isDisabled = multiStep
        ? getStepIsDisabled(nextStep) === true && getStepIsDisabled(currentStep) === true
        : getStepIsDisabled(currentStep);
      const newElement = (
        <StepItem
          key={currentStep}
          id={`bar-step-${currentStep}`}
          active={active}
          isCompleted={isCompleted}
          stepPassed={stepPassed}
          isDisabled={isDisabled}
          onClick={() => {
            if (!isDisabled) {
              setStep(currentStep);
            }
          }}
        >
          <StepNumberAvatar filled={active} step={currentStep}>
            {isCompleted ? <Check /> : ''}
          </StepNumberAvatar>
          <Typography variant="h6" textAlign={'start'}>
            {data}
          </Typography>
        </StepItem>
      );
      finalStepElements.push(newElement);
      if (nextStep !== FINAL_STEP) {
        // Add Step line
        finalStepElements.push(
          <StepLine
            key={`${currentStep}-line`}
            stepActive={active}
            stepCompleted={isCompleted}
            stepPassed={stepPassed}
          />,
        );
      }
    });
    return finalStepElements;
  }, [step, setStep, nextStep, getStepIsDisabled]);

  useEffect(() => {
    const activeStepElement = document.getElementById(`bar-step-${step}`);
    if (activeStepElement) {
      try {
        activeStepElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      } catch {}
    }
  }, [step]);
  return (
    <StepBar
      position={'sticky'}
      top={TOP_NAV_HEIGHT}
      minHeight={'100px'}
      flexWrap={'nowrap'}
      zIndex={'100'}
      {...props}
      {...originalProps}
    >
      {stepElements}
    </StepBar>
  );
};

export default DemoStepsBar;
