import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import List from '@zefr/style-guide/dist/core/List';
import Typography from '@zefr/style-guide/dist/core/Typography';
import colors from '@zefr/style-guide/dist/core/system/colors';
import { styled } from '@zefr/style-guide/dist/ui/styles';

export const Accordion = styled(MuiAccordion)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  marginBottom: -1,
  minHeight: 56,
  '&.Mui-expanded': {
    minHeight: 56,
  },
  padding: '0',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}));

export const DataLevelListItemText = styled(MuiListItemText)(({ theme }) => ({
  overflowWrap: 'break-word',
  '& > .MuiListItemText-primary': {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.common.black,
  },
}));

export const ItemDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  '&, & > span': {
    overflowWrap: 'break-word',
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

export const SelectedListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  borderRadius: '8px',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: colors.lightGrey,
}));

export const SelectableListItemButton = styled(MuiListItemButton)(({ theme, dense }) => ({
  borderRadius: '8px',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  padding: dense ? theme.spacing(2) : undefined,
  '&.Mui-selected': {
    backgroundColor: colors.lightGrey,
  },
}));

export const SelectableListItem = styled(MuiListItem)(({ theme }) => ({
  borderRadius: '8px',
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
}));

export const SelectedOrSelectableListItemText = styled(MuiListItemText)(() => ({
  '& .MuiListItemText-primary': {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '24px 1px 1fr',
  },
}));

export const SelectableListItemIconButton = styled(SelectableListItemButton)(
  ({ 'aria-multiselectable': ariaMultiSelectable }) => ({
    display: 'flex',
    flexDirection: 'row',
    '& .MuiCheckbox-root, & .startEdge': {
      alignSelf: 'start',
    },
    paddingLeft: ariaMultiSelectable ? 0 : undefined,
  }),
);

export const LeftText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.subtitle1.fontSize,
  display: 'inline-block',
  color: colors.veryDarkGreyishBlue,
  fontFamily: theme.typography.fontFamily,
}));

export const RightText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  display: 'inline-block',
  color: theme.palette.common.black,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle1.fontSize,
}));

export const Text = styled(Typography)(() => ({
  color: colors.veryDarkGreyishBlue,
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '16px',
  lineHeight: '22px',
}));

export const SpacedList = styled(List)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
