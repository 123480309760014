import { commonNS } from '@zefr/style-guide/dist/core/i18next';

export const LOCALE = 'en-US';
export type Resources = Record<string, Record<string, Record<string, unknown>>>;

export const resources: Resources = {
  [LOCALE]: {
    [commonNS]: {
      loading: 'Loading...',
      log_in: 'Log In',
      log_out: 'Log Out',
      copy_jwt: 'Copy JWT to Clipboard',
      markdown_helper: 'Markdown Helper',
      next: 'Next',
      previous: 'Back',
      cancel: 'Cancel',
      submit: 'Submit',
      welcome_back: 'Welcome Back,',
      your_apps: 'Your Apps',
      page_not_found_title: 'Page Not Found',
      page_not_found: 'Page Not Found...',
      go_home: 'Go Home',
      home_title: 'Home',
      logging_out: 'Logging you out',
      logging_in: 'Logging you in',
      site: {
        title: 'Zefr, Inc.',
        description:
          'The global technology leader for content adjacency in complex walled garden environments',
      },
    },
  },
};
