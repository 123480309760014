import { atom } from 'jotai';

type State = string | undefined;

const initialState: State = undefined;

const markdownAtom = atom(initialState as State);

markdownAtom.debugLabel = 'Markdown Helper Content';

export { markdownAtom };
