import { Add } from '@mui/icons-material';
import { Box, BoxProps, Button, Typography, styled } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import useChipDialog from '../../hooks/dialog/useChipDialog';
import ProxyConceptChip from '../ChipSwapper/ProxyConceptChip';
import ClickablePlaceholderBox from '../ClickablePlaceholderBox';

const ConceptsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: '10px',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  flexWrap: 'wrap',
}));

const ChipsDisplayBoxWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

const ChipsDisplayBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  boxSizing: 'border-box',
  overflow: 'auto',
}));

export interface ConceptsBoxProps extends BoxProps {
  isExclusion: boolean;
}

const ConceptsBox: React.FC<ConceptsBoxProps> = ({ isExclusion, ...props }) => {
  const { excludedConcepts, includedConcepts } = useDemoForm();
  const openDialog = useChipDialog({ isExclusion });

  const myConcepts = useMemo(
    () => (isExclusion ? excludedConcepts : includedConcepts) || [],
    [isExclusion, excludedConcepts, includedConcepts],
  );

  const handleClick = useCallback(() => {
    openDialog();
  }, [openDialog]);

  if (myConcepts.length === 0) {
    return (
      <ClickablePlaceholderBox
        onClick={handleClick}
        data-testid={'concepts-placeholder-box'}
        className={`concepts-placeholder-box${isExclusion ? ' exclusion' : ' inclusion'}`}
        label={isExclusion ? 'Add Exclusions' : 'Add Inclusions'}
        aria-label={isExclusion ? 'Add exclusions' : 'Add inclusions'} // Accessibility
      />
    );
  }

  return (
    <ConceptsContainer
      className={`concepts-container${isExclusion ? ' exclusion' : ' inclusion'}`}
      {...props}
    >
      <HeaderBox className="concepts-header-box" gap={1}>
        <Typography variant="subtitle1" color="black" fontWeight="bold" className="concepts-title">
          {isExclusion ? 'Excluded Concepts' : 'Included Concepts'}
        </Typography>
        <Button variant="text" onClick={handleClick} className="manage-concepts-button">
          <Add fontSize="small" className="add-icon" /> Manage
        </Button>
      </HeaderBox>
      <ChipsDisplayBoxWrapper>
        <ChipsDisplayBox className="chips-display-box">
          {myConcepts.map((conceptData) => (
            <ProxyConceptChip
              className={`proxy-concept-chip${isExclusion ? ' exclusion' : ' inclusion'}`}
              color={isExclusion ? 'error' : 'secondary'}
              direction={isExclusion ? 'left' : 'right'}
              ignoreFilters={true}
              hideFilters={true}
              key={conceptData}
              id={conceptData}
              aria-label={`Concept: ${conceptData}`} // Accessibility
            />
          ))}
        </ChipsDisplayBox>
      </ChipsDisplayBoxWrapper>
    </ConceptsContainer>
  );
};

export default ConceptsBox;
