import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useDemoForm } from '../../../context/DemoFormContext';
import { Option } from '../../../types/launchDarkly';

export interface LanguageSelectProps extends Omit<SelectProps, 'onChange'> {
  label?: React.ReactNode;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ label = 'Language(s)', ...props }) => {
  const { apiLanguages, languages, setFormLanguages } = useDemoForm();

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setFormLanguages(event.target.value as string[]);
    },
    [setFormLanguages],
  );

  const options: Option[] = useMemo(() => {
    const opts: Option[] = [];
    Object.keys(apiLanguages || {}).forEach((key) => {
      opts.push({
        label: key,
        value: key,
      });
    });
    return opts;
  }, [apiLanguages]);
  return (
    <FormControl className="zefr-form" fullWidth size={'medium'}>
      <InputLabel id="demo-language-label">{label}</InputLabel>
      <Select
        value={languages || []}
        onChange={handleChange as any}
        labelId="demo-language-label"
        MenuProps={{
          MenuListProps: {
            className: 'zefr-form',
          },
        }}
        multiple={true}
        label={label}
        {...props}
      >
        {options.map(({ label, ...data }) => {
          return <MenuItem {...data}>{label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
export default LanguageSelect;
