import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { postApiFn } from '../../../api/brandHelper';
import { getChannelIdsImages } from '../../../api/config/growth/v0';
import {
  BrandChannelImage,
  BrandChannelImagesRequest,
  BrandChannelImagesResponse,
} from '../../../types/proxyApi';

export const QUERY_KEY = 'channels_images';

interface RequestParams extends BrandChannelImagesRequest {}

type Response = BrandChannelImagesResponse;

const useChannelIdImages = ({ channel_ids, ...params }: RequestParams) => {
  const path = getChannelIdsImages();
  return useQuery({
    queryKey: [QUERY_KEY, `channel_ids=${channel_ids}`],
    queryFn: async () => {
      return await postApiFn(path, {
        ...params,
        channel_ids,
      })
        .then((res: Response) => {
          datadogLogs.logger.info(
            `Retrieved results for ${channel_ids.length} channel image${
              channel_ids.length !== 1 ? 's' : ''
            }`,
            { res },
          );
          const newData: Record<string, BrandChannelImage> = {};
          res.forEach((r) => {
            newData[r.id] = r;
          });
          return newData;
        })
        .catch((error) => {
          datadogLogs.logger.error(
            `Failed to retrieve results for ${channel_ids.length} channel image${
              channel_ids.length !== 1 ? 's' : ''
            }`,
            {
              error,
            },
          );
          throw new Error(error);
        });
    },
    // Only make the request once, and keep the results for the rest of our form creation
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export default useChannelIdImages;
