import { createTheme } from '@mui/material';
import { defaultThemeOptions } from '@zefr/style-guide/dist/ui/styles/theme/v2';

export const BRAND_BLUE = '#01A2FF';
export const BRAND_GREEN = '#31F5F4';
export const BRAND_PINK = '#E152B9';
export const BRAND_PURPLE = '#6037F5';

export const BG_COLOR = '#F4FAFF';
export const PRIMARY_DARK = '#0B1D37';

export const SOFT_BLUE = '#D9E9F9';

export const MAX_DEMO_WIDTH = '800px';
export const MAX_DEMO_WIDTH_XL = '1400px';
export const TEXT_GREY = '#64737E';

const theme = createTheme(defaultThemeOptions, {
  palette: {},
});
export default theme;
