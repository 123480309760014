import { Add } from '@mui/icons-material';
import { Box, BoxProps, Button, Slide, Typography, styled } from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';
import { StyledSwapChip } from '../ChipSwapper/components';
import ClickablePlaceholderBox from '../ClickablePlaceholderBox';
import { demoCreativeAlignmentDialogOpenAtom } from '../CreativeAlignment/atom';

// Styled components
const AlignmentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: '10px',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`, // equivalent to py=1, px=2 in MUI sx props
  boxSizing: 'border-box',
}));

const ItemDisplayBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`, // equivalent to py=1, px=2 in MUI sx props
  flexWrap: 'wrap',
  gap: theme.spacing(2), // equivalent to gap=2 in MUI sx props
  boxSizing: 'border-box',
  overflow: 'auto',
}));

// Interface for props
export interface CreativeAlignmentBoxProps extends BoxProps {}

const CreativeAlignmentBox: React.FC<CreativeAlignmentBoxProps> = ({
  className = '',
  ...props
}) => {
  const { creativeAlignment } = useDemoForm();
  const setDialog = useSetAtom(demoCreativeAlignmentDialogOpenAtom);

  const myItems = useMemo(() => {
    const stableString = creativeAlignment || '';
    return stableString ? stableString.split('\n').filter((s) => Boolean(s?.trim())) : [];
  }, [creativeAlignment]);

  const handleClick = useCallback(() => {
    setTimeout(() => {
      setDialog(true);
    }, 100);
  }, [setDialog]);

  if (myItems.length === 0) {
    return (
      <ClickablePlaceholderBox
        onClick={handleClick}
        className="creative-alignment-placeholder-box"
        data-testid="creative-alignment-placeholder-box"
        label={'Creative Alignment'}
        aria-label="Empty creative alignment container, click to manage alignments."
      />
    );
  }

  return (
    <AlignmentContainer
      className={`creative-alignment-container${className ? ` ${className}` : ''}`}
      {...props}
      role="group"
      aria-labelledby="creative-alignment-header"
    >
      <HeaderBox id="creative-alignment-header" className="creative-alignment-header-box">
        <Typography
          variant="subtitle1"
          color="black"
          fontWeight={'bold'}
          className="creative-alignment-title"
        >
          Creative Alignment
        </Typography>
        <Button
          variant="text"
          onClick={handleClick}
          className="manage-alignments-button"
          aria-label="Manage creative alignments"
        >
          <Add fontSize="small" className="add-icon" /> Manage
        </Button>
      </HeaderBox>
      <ItemDisplayBox
        aria-label="List of creative alignments"
        className="creative-alignment-item-box"
      >
        {myItems.map((item) => (
          <Slide
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={500}
            key={item}
            direction={'right'}
            in={true}
            id={`creative-alignment-item-${item}`}
            aria-labelledby={`creative-alignment-item-${item}`}
            className="creative-alignment-slide"
          >
            <StyledSwapChip
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              id={`chip-${item}`}
              data-key={item}
              key={item}
              color="info"
              skipFocusWhenDisabled
              label={item}
              aria-label={`Creative alignment: ${item}`}
              className="creative-alignment-chip"
            />
          </Slide>
        ))}
      </ItemDisplayBox>
    </AlignmentContainer>
  );
};

export default CreativeAlignmentBox;
