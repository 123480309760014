import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import { SalesDemoTargetAudienceOptionsData } from '../../types/launchDarkly';

const initialState: SalesDemoTargetAudienceOptionsData = {
  genders: {
    label: 'Include Gender(s)',
    type: 'multiselect',
    options: {
      men: 'Men',
      women: 'Women',
      nba: 'Non-Binary',
    },
  },
  ageRange: {
    label: 'Age Range',
    type: 'multiselect',
    options: {
      '20-30': '20-30',
      '55-65+': '55-65+',
      kids: '5-12',
    },
  },
  regions: {
    label: 'Region(s)',
    type: 'multiselect',
    options: {
      us: 'U.S',
      uk: 'UK',
      canada: 'Canada',
      imagination: 'Imagination Land',
    },
  },
  interests: {
    type: 'text',
    label: 'Audience Interests',
    placeholder: 'Must be comma-separated',
  },
};

const initialFormState: Record<string, string | string[] | number> = {};

const targetAudienceOptionsAtom = atomWithReset(initialState);
targetAudienceOptionsAtom.debugLabel = 'Target Audience Options State';
const targetAudienceOptionsFormAtom = atomWithReset(initialFormState);
targetAudienceOptionsFormAtom.debugLabel = 'Target Audience Form State';
const readTargetAudienceOptionsFormAtom = atom((get) => {
  return get(targetAudienceOptionsFormAtom);
});

const writeTargetAudienceOptionFormAtom = atom(
  null,
  (get, set, data: { key: string; value?: string | string[] | number }) => {
    const { key, value } = data;
    const existingForm = get(targetAudienceOptionsFormAtom);
    if (typeof value === 'undefined') {
      if (typeof existingForm[key] !== 'undefined') {
        delete existingForm[key];
      }
    } else {
      existingForm[key] = value;
    }
    set(targetAudienceOptionsFormAtom, existingForm);
  },
);

const clearTargetAudienceOptionsFormAtom = atom(null, (get, set) => {
  set(targetAudienceOptionsFormAtom, RESET);
});

clearTargetAudienceOptionsFormAtom.debugPrivate = true;
writeTargetAudienceOptionFormAtom.debugPrivate = true;
readTargetAudienceOptionsFormAtom.debugPrivate = true;

const filteredTargetAudienceOptionsAtom = atom((get) => {
  const data = get(targetAudienceOptionsAtom);
  const newData: Record<
    'text' | 'select' | 'multiselect' | string,
    SalesDemoTargetAudienceOptionsData
  > = {
    multiselect: {},
    select: {},
    text: {},
  };
  Object.keys(data).forEach((key) => {
    const myType = data[key].type;
    if (typeof myType !== 'undefined') {
      if (typeof newData[myType] === 'undefined') {
        newData[myType] = {};
      }
      newData[myType][key] = data[key];
    }
  });
  return newData;
});

filteredTargetAudienceOptionsAtom.debugLabel = 'Filtered Target Audience Options';

const multiSelectTargetAudienceOptionsAtom = atom((get) => {
  const data = get(filteredTargetAudienceOptionsAtom);
  return data['multiselect'] || {};
});
multiSelectTargetAudienceOptionsAtom.debugPrivate = true;

export {
  clearTargetAudienceOptionsFormAtom,
  filteredTargetAudienceOptionsAtom,
  multiSelectTargetAudienceOptionsAtom,
  readTargetAudienceOptionsFormAtom,
  targetAudienceOptionsAtom,
  writeTargetAudienceOptionFormAtom,
};

export default targetAudienceOptionsFormAtom;
