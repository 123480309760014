import { Box, Grid2Props, LinearProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import InternalIcon from '../../InternalIcon';

export interface InformationalBoxProps extends Grid2Props {
  label: React.ReactNode;
  description: React.ReactNode;
  iconName?: string;
  progressDistance?: number;
  barColor?: string;
}

const InformationalBox: React.FC<InformationalBoxProps> = ({
  iconName,
  label,
  description,
  progressDistance = 35,
  barColor,
  ...props
}) => {
  return (
    <Grid2 flexGrow={1} xs={12} md={6} lg={4} p={2} boxSizing={'border-box'} {...props}>
      <Box
        p={8}
        boxSizing={'border-box'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={6}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          gap={7}
        >
          {iconName && (
            <InternalIcon className="icon" sx={{ fontSize: '4rem' }} iconName={iconName} />
          )}
          {label}
        </Box>
        <LinearProgress
          sx={{
            '&, & .MuiLinearProgress-bar': {
              borderRadius: '25px',
            },
            height: '8px',
            backgroundColor: 'white',
            '& .MuiLinearProgress-bar': {
              backgroundColor: barColor,
            },
          }}
          variant="determinate"
          value={progressDistance}
        />
        <Box>{description}</Box>
      </Box>
    </Grid2>
  );
};

export default InformationalBox;
