import { Warning } from '@mui/icons-material';
import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowHeightParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { YouTubeIcon } from '@zefr/style-guide/dist/ui/icons/index';
import getViewsAbbreviated from '@zefr/style-guide/dist/ui/utils/strings/getViewsAbbreviated';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { paginationModelAtom, tableSortAtom, tableStateAtom } from '../atoms/videoTableAtom';
import { ProxyChannel } from '../types/proxyApi';
import ConceptsCell from './DemoComponents/Form/VideoTableElements/ConceptsCell';
import VideoTableChannelIdCell from './DemoComponents/VideoTableChannelIdCell';
import VideoTableGarmScoreCell from './VideoTableGarmScoreCell';

const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    backgroundColor: theme.palette.common.white,

    '--unstable_DataGrid-headWeight': 'bold',
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    '& .MuiDataGrid-cell': {
      padding: `${theme.spacing(2)}`,
      transition: theme.transitions.create(['height', 'max-height', 'min-height'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    '& .MuiDataGrid-row': {
      transition: theme.transitions.create(['height', 'max-height', 'min-height'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    '& .MuiDataGrid-cellContent': {
      height: '100%',
      textWrap: 'wrap',
      transition: theme.transitions.create(['height', 'max-height', 'min-height'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
    },
    '& .scores': {
      '&.shrink .concept-chip:not(:first-of-type):not(#overflow-chip)': {
        display: 'none',
      },
      '&:not(.shrink) #overflow-chip.concept-chip': {
        display: 'none',
      },
    },
    '& .title, & .description, & .channel_summary': {
      fontWeight: '800',
    },
  };
});

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}
export interface VideoTableProps extends BoxProps {
  rows?: ProxyChannel[];
  totalRows?: number;
  isExclusion?: boolean;
  isLoading?: boolean;
}

const VideoTable: React.FC<VideoTableProps> = ({
  rows: myRows = [],
  totalRows = 0,
  isExclusion = false,
  isLoading = false,
  ...props
}) => {
  const [paginationModel, setPaginationModel] = useAtom(paginationModelAtom);
  const [tableState, setTableState] = useAtom(tableStateAtom);
  const setTableSort = useSetAtom(tableSortAtom);
  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      // Here you save the data you need from the sort model
      if (sortModel.length > 0) {
        // The API sends down 'average_daily_views' but expects us to send back 'avg_daily_views'
        if (sortModel[0].field === 'average_daily_views') {
          setTableSort({ field: 'avg_daily_views', sort: sortModel[0].sort });
        } else {
          setTableSort(sortModel[0]);
        }
      } else {
        setTableSort(RESET);
      }
    },
    [setTableSort],
  );

  const handleGetCellClassName = useCallback(
    ({ id, field }: GridCellParams) => {
      let newClassName = field;
      if (!tableState[id]) {
        return `${newClassName} shrink`;
      }
      return newClassName;
    },
    [tableState],
  );
  const transformedRows = useMemo(() => {
    const results = myRows.map((r) => {
      const newData: Record<string, any> = { id: r.channel_id, ...r };
      return newData;
    });
    return results;
  }, [myRows]);

  // TODO: See if we want to both with this pro-feature, or if we already have a license
  // Pro feature
  // const handleRowMouseEnter: GridEventListener<'rowMouseEnter'> = useCallback(
  //   ({ id }) => {
  //     setTableState((oldState) => {
  //       const newState: Record<string, boolean> = { ...oldState };
  //       newState[id] = true;
  //       return newState;
  //     });
  //   },
  //   [setTableState],
  // );

  // const handleRowMouseLeave: GridEventListener<'rowMouseLeave'> = useCallback(
  //   ({ id }) => {
  //     setTableState((oldState) => {
  //       const newState: Record<string, boolean> = { ...oldState };
  //       newState[id] = false;
  //       return newState;
  //     });
  //   },
  //   [setTableState],
  // );
  // useGridApiEventHandler(apiRef, 'rowMouseEnter', handleRowMouseEnter);
  // useGridApiEventHandler(apiRef, 'rowMouseLeave', handleRowMouseLeave);
  const getRowHeight = useCallback(
    ({ id }: GridRowHeightParams) => {
      return tableState[id] ? 'auto' : 80;
    },
    [tableState],
  );

  const onCellClick: GridEventListener<'cellClick'> = useCallback(
    ({ field, row }) => {
      if (['id', 'garm_levels'].indexOf(field) === -1) {
        setTableState((oldState) => {
          const newState: Record<string, boolean> = { ...oldState };
          newState[row.id] = !Boolean(newState[row.id]);
          return newState;
        });
      }
    },
    [setTableState],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Channels',
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        minWidth: 150,
        align: 'center',
        renderCell: (params) => {
          return <VideoTableChannelIdCell channelId={params.row.id} />;
        },
        renderHeader: () => {
          return (
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
              sx={{ '& *': { filter: 'grayscale(1)' } }}
            >
              <YouTubeIcon /> Channels
            </Box>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        maxWidth: 200,
        sortable: true,
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'YouTube Details',
        sortable: true,
        flex: 1,
      },
      {
        field: 'channel_summary',
        headerName: 'Zefr Details',
        sortable: true,
        flex: 1,
      },
      {
        field: 'scores',
        headerName: 'Content Score',
        minWidth: 200,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const value = params.value as Record<string, number>;
          return <ConceptsCell values={value} isExclusion={isExclusion} />;
        },
        flex: 1,
      },
      {
        field: 'average_daily_views',
        headerName: 'Avg. Daily Views',
        type: 'number',
        sortable: true,
        valueFormatter: ({ value }) => {
          return getViewsAbbreviated(value as number | undefined, true);
        },
        renderCell: ({ value }) => {
          const text = getViewsAbbreviated(value as number | undefined, true);
          return (
            <>
              <Warning
                sx={{
                  display: isExclusion ? undefined : 'none',
                  color: '#FA6F4F',
                  fontSize: '1rem',
                }}
              />
              {text}
            </>
          );
        },
        // valueGetter: (params) => {
        //   const value = params.value as Record<string, number>;
        //   const dailyViews = value['total_average_daily_views'] || undefined;
        //   return dailyViews;
        // },
        align: 'center',
      },
      {
        field: 'garm_levels',
        headerName: 'More',
        sortable: false,
        disableColumnMenu: false,
        filterable: false,
        headerAlign: 'center',
        resizable: false,
        width: 60,
        // valueGetter: (params: GridValueGetterParams) => `...`,
        renderCell: ({ value }) => <VideoTableGarmScoreCell values={value} />,
      },
    ],
    [isExclusion],
  );
  useEffect(() => {
    return () => {
      setTableState(RESET);
    };
  }, [setTableState]);
  const getRowId = useCallback((row: Record<string, any>) => {
    return row.channel_id;
  }, []);
  return (
    <Box {...props}>
      <StyledDataGrid
        rows={transformedRows}
        columns={columns}
        disableColumnFilter={true}
        density={'comfortable'}
        pageSizeOptions={[5, 10, 25, 100]}
        editMode="row"
        autoHeight={true}
        getCellClassName={handleGetCellClassName}
        autoPageSize={false}
        hideFooterSelectedRowCount
        onCellClick={onCellClick}
        getRowHeight={getRowHeight}
        pagination={true}
        paginationMode="server"
        rowCount={totalRows}
        paginationModel={paginationModel}
        loading={isLoading}
        getRowId={getRowId}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={setPaginationModel}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
      />
    </Box>
  );
};

export default VideoTable;
