import { FormControl, FormControlProps, TextField, TextFieldProps, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useCallback, useMemo } from 'react';
import { useDemoForm } from '../../context/DemoFormContext';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '8px',
  paddingRight: 0,
  transition: 'all 500ms ease-in-out',
  width: '100%',
  border: 'none',
  '& .MuiInputBase-input': {
    fontSize: '12px',
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export interface TargetAudienceTextProps extends FormControlProps {}

const TargetAudienceText: React.FC<TargetAudienceTextProps> = ({
  fullWidth = true,
  id = '',
  ...props
}) => {
  const {
    targetAudienceOptions: items,
    setTargetAudienceFormOption,
    targetAudience: activeItems,
  } = useDemoForm();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
      const {
        target: { value },
      } = event;
      setTargetAudienceFormOption({
        key,
        // On autofill we get a stringified value.
        value: value,
      });
    },
    [setTargetAudienceFormOption],
  );

  const data = useMemo(() => {
    if (id) {
      const v = items[id];
      if (v) {
        return v;
      }
    }
    return undefined;
  }, [id, items]);

  return (
    <Grid2 xs={12}>
      <FormControl className="zefr-form" {...props} {...{ fullWidth }}>
        <StyledTextField
          onChange={(e) => {
            handleChange(e, id);
          }}
          fullWidth
          value={(activeItems || {})[id]}
          defaultValue={(activeItems || {})[id] || ''}
          name={id}
          {...data}
        />
      </FormControl>
    </Grid2>
  );
};
export default TargetAudienceText;
