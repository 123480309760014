import { ListItemButtonProps, ListItemText } from '@mui/material';
import React from 'react';
import {
  LeftText,
  SelectableListItemButton,
} from '../../gatsby-theme-zefr/components/SideBar/styled-components';

interface SideNavContentItemProps extends Omit<ListItemButtonProps, 'children'> {}

const SideNavContentItem: React.FC<SideNavContentItemProps> = ({ content, ...props }) => {
  return (
    <SelectableListItemButton {...props}>
      <ListItemText
        primaryTypographyProps={{ component: 'div' }}
        primary={
          <>
            <LeftText>{content}</LeftText>
          </>
        }
      />
    </SelectableListItemButton>
  );
};

export default SideNavContentItem;
