import { atom } from 'jotai';
import { formParamsAtom } from './encodedFormAtoms';

type State = boolean | undefined;

const initialState: State = false;

const demoKpiDialogOpenAtom = atom(initialState as State);

demoKpiDialogOpenAtom.debugLabel = 'Demo KPI Dialog Open';

export interface KpiData {
  title?: string;
  disabled?: boolean;
  icon?: string;
}
const initialKpiData: Record<string, KpiData> = {
  suitability: {
    title: 'Suitability',
    disabled: false,
    icon: 'inclusionList',
  },
  performance: {
    title: 'Performance',
    disabled: false,
    icon: 'PerformanceIcon',
  },
  engagement: {
    title: 'Engagement',
    disabled: false,
    icon: 'EngagementIcon',
  },
  costEfficiencies: {
    title: 'Cost Efficiencies',
    disabled: false,
    icon: 'totalSpend',
  },
  reach: {
    title: 'Reach',
    disabled: false,
    icon: 'ReachIcon',
  },
};
const demoKpiDataAtomRoot = atom(initialKpiData);
export type KpiWrapperType =
  | Record<string, KpiData>
  | ((prev: Record<string, KpiData>) => Record<string, KpiData>);
const demoKpiDataAtom = atom(
  (get) => get(demoKpiDataAtomRoot),
  (get, set, arg: KpiWrapperType) => {
    set(demoKpiDataAtomRoot, (prev) => {
      return typeof arg === 'function' ? arg(prev) : arg;
    });
  },
);
demoKpiDataAtom.debugLabel = 'KPI Data';

const demoKpiSelectedDataAtom = atom((get) => {
  const data = get(demoKpiDataAtom);
  const kpiKeys = get(formParamsAtom)['kpiKeys'] || [];
  const filteredData: Record<string, KpiData> = {};
  Object.keys(data).map((key) => {
    const result = data[key];
    if (kpiKeys.indexOf(key) !== -1) {
      filteredData[key] = result;
    }
  });
  return filteredData;
});

demoKpiSelectedDataAtom.debugPrivate = true;

export { demoKpiDataAtom, demoKpiDialogOpenAtom, demoKpiSelectedDataAtom };
