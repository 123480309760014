/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useMemo } from 'react';
import Helmet, { HelmetProps } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type MetaProps = JSX.IntrinsicElements['meta'];

interface SEOReq extends Omit<HelmetProps, 'meta'> {
  description: string;
  lang: string;
  meta: MetaProps[];
}

const SEO = (props: SEOReq) => {
  const { t } = useTranslation();
  const { description, lang, meta, title } = props;

  const metaDescription = description || t('site.description', { defaultValue: '' });

  const defaultMeta = [
    {
      name: `viewport`,
      content: `minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no`,
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: '@ZEFRinc',
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ] as MetaProps[];

  const localizedSiteTitle = useMemo(() => t('site.title', { defaultValue: 'Zefr' }), [t]);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || localizedSiteTitle}
      titleTemplate={title ? `%s | ${localizedSiteTitle}` : undefined}
      meta={[...defaultMeta, ...(meta ? meta : [])]}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
