import { GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import { atomWithReset } from 'jotai/utils';

type InitialTableState = Record<string, boolean>;
const tableState: InitialTableState = {};
const pageModelState: GridPaginationModel = {
  pageSize: 25,
  page: 0,
};

const tableStateAtom = atomWithReset(tableState);
const paginationModelAtom = atomWithReset(pageModelState);
type InitalTableSortState = undefined | GridSortItem;
const tableSortAtom = atomWithReset(undefined as InitalTableSortState);

tableStateAtom.debugLabel = 'Table State';
tableSortAtom.debugLabel = 'Table Sort';
paginationModelAtom.debugLabel = 'Pagination Model State';

export { paginationModelAtom, tableSortAtom, tableStateAtom };
