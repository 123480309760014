/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { ChevronRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SuitableMaxIcon } from '@zefr/style-guide/dist/ui/icons';
import { getViewsAbbreviated } from '@zefr/style-guide/dist/ui/utils/strings/getViewsAbbreviated';
import React, { useCallback, useMemo, useState } from 'react';
import { RISK_LEVEL_MAP } from '../../../../atoms/contentAlignmentAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import AverageDailyViewsGridItem from '../../../ChannelResults/AverageDailyViewsGridItem';
import ConceptsBox from '../../../ChannelResults/ConceptsBox';
import DonutGraphic from '../../../ChannelResults/DonutGraphic';
import FakeLinearProgressBar from '../../../ChannelResults/FakeLinearProgressBar';
import RiskGridItem from '../../../ChannelResults/RiskGridItem';
import SpendGridItem from '../../../ChannelResults/SpendGridItem';
import {
  PaddedFormControl,
  SummaryAccordion,
  SummaryItems,
} from '../../../ChannelResults/components';
import InternalIcon from '../../../InternalIcon';
import VideoTable from '../../../VideoTable';
import DownloadListButton from '../DownloadListButton';
import LanguageSelectIconButton from '../LanguageSelectIconButton';
import { FormStepProps } from '../types';
import ChannelResultsHeader from './ChannelResultsHeader';

const itemGridSize = {
  xs: 12,
  md: 4,
};

export const StepInclusionResults: React.FC<FormStepProps> = ({
  step: myStep = 5,
  'data-testid': testId
}) => {
  const [videoExpanded, setVideoExpanded] = useState(true);

  const {
    step,
    selectedPlatform,
    inclusionResults,
    riskLevel,
    listType,
    isInclusionResultFetching,
    isInclusionResultLoading,
    isInclusionResultSuccess,
  } = useDemoForm();

  const riskLevelTitle = useMemo(() => {
    return typeof riskLevel !== 'undefined' && riskLevel >= 0
      ? Object.values(RISK_LEVEL_MAP)[riskLevel!]
      : 'Unknown';
  }, [riskLevel]);

  const handleVideoAccordionClick = useCallback(
    (e: any) => {
      if (e.target.id !== 'download-button') {
        setVideoExpanded((o) => !o);
      }
    },
    [setVideoExpanded],
  );

  const isLoading = useMemo(
    () => isInclusionResultLoading || isInclusionResultFetching,
    [isInclusionResultFetching, isInclusionResultLoading],
  );

  const isSuccess = useMemo(() => isInclusionResultSuccess, [isInclusionResultSuccess]);


  if (step !== myStep || listType !== 'inclusion') {
    return null;
  }

  return (
    <PaddedFormControl fullWidth focused={true} data-testid={testId}>
      <Grid
        container
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={10}
        boxSizing={'border-box'}
      >
        <Grid id="projected-performance-summary" xs={12}>
          <SummaryAccordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={8}
              >
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.secondary.main,
                  })}
                  variant="h6"
                  fontWeight={'bold'}
                  color="inherit"
                >
                  Projected Performance Summary
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'stretch'}
                  gap={3}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      height: 'unset',
                    },
                  }}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    boxSizing={'border-box'}
                  >
                    <InternalIcon
                      sx={{ fontSize: '3.75rem' }}
                      iconName={selectedPlatform?.logoIcon}
                    />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    boxSizing={'border-box'}
                  >
                    <Divider orientation={'vertical'} />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    gap={1}
                    boxSizing={'border-box'}
                    p={1}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={1}
                    >
                      <InternalIcon
                        sx={{ fontSize: '3.75rem' }}
                        iconName={selectedPlatform?.labelIcon}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <SummaryItems container gap={3} id="project-performance-summary-splitter">
                <DonutGraphic />
                <SummaryItems
                  xs={true}
                  rowGap={1}
                  container
                  columnGap={3}
                  id="project-performance-summary-items"
                >
                  <Grid container gap={3} width="100%">
                    <Grid
                      xs={12}
                      container
                      gap={3}
                      sx={(theme) => ({
                        flexWrap: 'wrap',
                        [theme.breakpoints.up('md')]: {
                          flexWrap: 'nowrap',
                        },
                      })}
                    >
                      <SpendGridItem
                        {...itemGridSize}
                        value={
                          isLoading ? (
                            <FakeLinearProgressBar />
                          ) : isSuccess ? (
                            inclusionResults?.aggregates.spend
                          ) : (
                            <FakeLinearProgressBar
                              linearProgressProps={{
                                variant: 'determinate',
                                value: 100,
                                color: 'error',
                              }}
                            />
                          )
                        }
                      />
                      <AverageDailyViewsGridItem
                        {...itemGridSize}
                        value={
                          isLoading ? (
                            <FakeLinearProgressBar />
                          ) : isSuccess ? (
                            inclusionResults?.aggregates.total_average_daily_views
                          ) : (
                            <FakeLinearProgressBar
                              linearProgressProps={{
                                variant: 'determinate',
                                value: 100,
                                color: 'error',
                              }}
                            />
                          )
                        }
                      />
                      <RiskGridItem
                        {...itemGridSize}
                        label={`Garm Risk Tolerance Level`}
                        value={
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            {riskLevelTitle}
                            <ChevronRight />
                          </Box>
                        }
                      />
                    </Grid>
                    <Grid xs={12} container gap={3} wrap="nowrap">
                      <ConceptsBox isExclusion={false} />
                      <ConceptsBox isExclusion={true} />
                    </Grid>
                  </Grid>
                </SummaryItems>
              </SummaryItems>
            </AccordionDetails>
          </SummaryAccordion>
        </Grid>
        <Grid id="channel-inclusion-list" xs={12}>
          <SummaryAccordion
            expanded={videoExpanded}
            color="black"
            sx={{ backgroundColor: 'white' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
              onClick={handleVideoAccordionClick}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{
                  width: '100%',
                }}
              >
                <ChannelResultsHeader>
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.secondary.main,
                    })}
                    variant="h6"
                    fontWeight={'bold'}
                    color="inherit"
                  >
                    Channel Inclusion List:{' '}
                    <Typography component={'span'} variant="h6" fontWeight={'thin'} color="inherit">
                      {getViewsAbbreviated(inclusionResults?.aggregates.total_number_of_channels, true)}{' '}
                      Total
                    </Typography>
                  </Typography>
                  <SuitableMaxIcon />
                </ChannelResultsHeader>
                <Box
                  display={videoExpanded ? 'flex' : 'none'}
                  flexDirection={'row'}
                  justifyContent={'space-evenly'}
                  alignItems={'center'}
                  gap={2}
                >
                  <LanguageSelectIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                  <DownloadListButton />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={4} pb={4}>
                <Typography component={'span'} variant="body1" fontWeight={'thin'}>
                  Below are a list of unique channels that you can include in your campaign
                  placement
                </Typography>
              </Box>
              <VideoTable
                rows={inclusionResults?.channel_results}
                totalRows={inclusionResults?.aggregates.total_number_of_channels}
                isLoading={isInclusionResultLoading || isInclusionResultFetching}
              />
            </AccordionDetails>
          </SummaryAccordion>
        </Grid>
      </Grid>
    </PaddedFormControl>
  );
};

export default StepInclusionResults;
