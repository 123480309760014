import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import GarmScoreCard, {
  ScoreData,
} from '@zefr/style-guide/dist/ui/GarmScoreCarousel/GarmScoreCard';
import { Category } from '@zefr/style-guide/dist/ui/types/atrium/garm';
import { useSetAtom } from 'jotai';
import React, { useCallback } from 'react';
import Link from '../../../gatsby-theme-zefr/components/Link';
import { demoDialogContentNodeAtom, demoDialogOpenAtom } from '../../atoms/demoDialogAtom';

const HeaderContent = () => {
  return (
    <Box
      position={'relative'}
      px={12}
      py={8}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
        Are you sure you want to start over?
      </Typography>
    </Box>
  );
};

interface BodyContentProps {
  scoreData?: Partial<Record<Category, ScoreData>>;
}
const BodyContent: React.FC<BodyContentProps> = ({ scoreData }) => {
  return (
    <DialogContent
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <GarmScoreCard scoreData={scoreData} />
    </DialogContent>
  );
};

const FooterContent = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const handleClose = useCallback(() => {
    setDemoDialog(false);
  }, [setDemoDialog]);

  return (
    <DialogActions
      sx={(theme) => ({
        justifyContent: 'space-evenly',
        '& > .MuiButton-root': {
          minWidth: '125px',
          [theme.breakpoints.up('md')]: {
            minWidth: '150px',
          },
        },
      })}
    >
      <Button
        size={'large'}
        variant="contained"
        onClick={handleClose}
        color="error"
        LinkComponent={Link}
        {...{ to: '../' }}
        className="form-button-cancel"
      >
        Yes
      </Button>
      <Button size={'large'} variant="contained" onClick={handleClose} color="primary">
        No
      </Button>
    </DialogActions>
  );
};

interface BodyContentProps {}

const useCancelDemoDialog = () => {
  const setDemoDialog = useSetAtom(demoDialogOpenAtom);
  const setDemoDialogContent = useSetAtom(demoDialogContentNodeAtom);
  const handleClick = useCallback(() => {
    setDemoDialogContent(
      <>
        <HeaderContent />
        {/* <BodyContent /> */}
        <FooterContent />
      </>,
    );
    setDemoDialog(true);
  }, [setDemoDialog, setDemoDialogContent]);
  return handleClick;
};

export default useCancelDemoDialog;
