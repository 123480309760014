/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import useTypeLearnMoreDialog from '../../../../hooks/dialog/useTypeLearnMoreDialog';
import FormStepDemoPlatformCards from '../FormStepDemoPlatformCards/FormStepDemoPlatformCards';
import { FormStepProps } from '../types';

export const FormStepListTypeChoice: React.FC<FormStepProps> = ({ step: myStep = 2, ...props }) => {
  const { t } = useTranslation();
  const { listTypeOptions, listType, setListType } = useDemoForm();
  const handleClick = useTypeLearnMoreDialog();
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */

  /* #endregion */
  return (
    <FormStepDemoPlatformCards
      titleLabel={t(`growth.${APP_Demo}.step.two.title`)}
      options={listTypeOptions}
      selectedOption={listType}
      setValue={setListType}
      ctaClick={handleClick}
      ctaLabel="Learn More"
      floatingIcon={true}
      step={myStep}
      {...props}
    />
  );
};

export default FormStepListTypeChoice;
