import { Button, darken, styled } from '@zefr/style-guide';

const StyledTopNavButton = styled(Button)(({ theme }) => {
  return {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.common.white,
    display: 'flex',
    gap: '5px',
    padding: '20px 0',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: darken(theme.palette.common.white, 0.2),
    },
  };
});

export default StyledTopNavButton;
