/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_Demo } from '../../../../atoms/toolsAtom';
import { useDemoForm } from '../../../../context/DemoFormContext';
import { LaunchDarklyFlagSet } from '../../../../types/launchDarkly';
import FormStepBox from '../FormStepBox';
import SetPrefabSelect from '../SetPrefabSelect';
import { FormTitle } from '../components';
import { FormStepProps } from '../types';
import { INCLUSION_LIST_PROMPT } from '../../../../constants/listTypes';

export const FormStepInclusionPrefabForm: React.FC<FormStepProps> = ({
  step: myStep = 3,
  'data-testid': testId,
  ...props
}) => {
  const { t } = useTranslation();
  const { step, setFormInclusionListType, prefab } = useDemoForm();
  const { inclusionTextEnabled } = useFlags<LaunchDarklyFlagSet>();
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */
  if (step !== myStep) {
    return null;
  }

  /* #endregion */
  return (
    <FormStepBox
      data-testid={testId}
      isNextDisabled={!Boolean(prefab)}
      titleElement={
        <Grid width={'100%'} container pb={2}>
          <Grid xs={2} sx={{ opacity: 0 }}>
            <Button variant="text" disabled sx={{ opacity: 0 }}>
              Hidden
            </Button>
          </Grid>
          <Grid
            xs={8}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <FormTitle sx={{ marginBottom: 0 }} marginBottom={6}>
              {t(`growth.${APP_Demo}.step.three.title`)}
            </FormTitle>
          </Grid>
          <Grid xs={2} sx={{ opacity: inclusionTextEnabled ? undefined : 0 }}>
            <Button
              fullWidth
              sx={{ height: '100%' }}
              disabled={!inclusionTextEnabled}
              variant="text"
              onClick={() => {
                setFormInclusionListType(INCLUSION_LIST_PROMPT);
              }}
            >
              Text Form
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        width={'100%'}
        className={'max-width-percentage'}
      >
        <SetPrefabSelect />
      </Box>
    </FormStepBox>
  );
};

export default FormStepInclusionPrefabForm;
