import { BoxProps } from '@zefr/style-guide/dist/core/Box';
import { SideNav as ZefrSideNav } from '@zefr/style-guide/dist/ui/SideNav';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { sidebarComponentAtom } from '../../atoms/sidebarComponentAtom';
import { sidebarHiddenAtom } from '../../atoms/sidebarHiddenAtom';
import { sidebarOpenAtom } from '../../atoms/sidebarOpenAtom';

export interface SideBarProps extends BoxProps {}

const SideBar: React.FC<SideBarProps> = ({ children, id: _, ...props }) => {
  const content = useAtomValue(sidebarComponentAtom);
  const open = useAtomValue(sidebarOpenAtom);
  const sidebarHidden = useAtomValue(sidebarHiddenAtom);

  const isOpen = useMemo(() => {
    return sidebarHidden === true ? false : open;
  }, [sidebarHidden, open]);

  return (
    <ZefrSideNav id="SideBar" open={isOpen} externallyControlled {...props}>
      {content}
    </ZefrSideNav>
  );
};

export default SideBar;
