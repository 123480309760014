import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import Link from '../../../../gatsby-theme-zefr/components/Link';
import { BRAND_BLUE, SOFT_BLUE } from '../../../../gatsby-theme-zefr/theme';
import { DEMO_FORM_URL } from '../../../routes/routes';
import { FORM_LINEAR_GRADIENTS } from '../Form/components';
import ActionTitle from './ActionTitle';
import InformationalBox from './InformationalBox';
import InformationalDescription from './InformationalDescription';
import InformationalTitle from './InformationalTitle';

const HomeContainerTwo = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Grid2 xs={12} zIndex={2} id="growth-home-container-2">
      <Grid2
        container
        className="h-page"
        rowGap={4}
        px={matches ? 16 : 0}
        boxSizing={'border-box'}
        sx={{
          background: FORM_LINEAR_GRADIENTS.left,
        }}
      >
        <Grid2
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          pt={12}
          boxSizing={'border-box'}
        >
          <ActionTitle
            label={`Explore SuitableMax: Zefr's dynamic, AI-powered video or channel level targeting and optimization for safety, suitability and performance on YouTube.`}
            coloredWords={6}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Box boxSizing={'border-box'} mb={12}>
            <Grid2
              container
              sx={{
                backgroundColor: SOFT_BLUE,
                borderRadius: '10px',
              }}
              px={matches ? 6 : undefined}
              py={matches ? 6 : undefined}
              pb={matches ? undefined : 2}
              boxSizing={'border-box'}
              position="relative"
            >
              <InformationalBox
                iconName="ScaledReachIcon"
                barColor={BRAND_BLUE}
                label={<InformationalTitle>Scaled Reach</InformationalTitle>}
                description={
                  <InformationalDescription>
                    Maximize reach and efficiencies in brand suitable environments.
                  </InformationalDescription>
                }
              />
              <InformationalBox
                iconName="AdvancedPerformanceOptimizationIcon"
                barColor={'#00DFFB'}
                label={<InformationalTitle>Advanced Performance Optimizations</InformationalTitle>}
                description={
                  <InformationalDescription>
                    Campaign suitability and performance optimizations that drive improvements on
                    key metrics (i.e. engagement, efficiencies, pricing and many others)
                  </InformationalDescription>
                }
              />
              <InformationalBox
                iconName="SimpleOnboardingIcon"
                barColor={'#00E0D6'}
                label={<InformationalTitle>Simple Onboarding</InformationalTitle>}
                description={
                  <InformationalDescription>
                    Simple adoption within existing programmatic workflows, and automated dynamic
                    optimizations.
                  </InformationalDescription>
                }
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={(theme) => {
                  return {
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    fontSize: '1.25rem',
                    padding: '0.65rem 3.5rem',
                    borderRadius: '35px',
                    webkitTransform: 'translateX(-50%) translateY(50%)',
                    mozTransform: 'translateX(-50%) translateY(50%)',
                    transform: 'translateX(-50%) translateY(50%)',
                    textTransform: 'capitalize',
                    textAlign: 'center',
                  };
                }}
                LinkComponent={Link}
                {...{ to: `./${DEMO_FORM_URL}` }}
              >
                Get Started
              </Button>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default HomeContainerTwo;
