import { datadogLogs } from '@datadog/browser-logs';
import { useMutation } from '@tanstack/react-query';
import { getExclusionChannelsList } from '../../../api/config/growth/v0';
import { postApiFn } from '../../../api/helper';
import { ProxyChannelsListResponse } from '../../../types/proxyApi';

export const QUERY_KEY = 'sample-exclusion-channels';

interface RequestParams {
  bsp_concepts?: string[];
  limit?: number;
  page?: number;
  maximum_risk_level?: number;
  sort_field?: string;
  sort_dir?: 'asc' | 'desc';
}

type Response = ProxyChannelsListResponse;

const useExclusionChannelsListMutation = ({
  page = 1,
  limit = 1000, // 10k is server default if no value provided
  bsp_concepts = [],
  sort_field: _ignore, // Extracting to safely ignore
  sort_dir: __ignore, // Extracting to safely ignore
  maximum_risk_level = 3,
  ...params
}: RequestParams) => {
  const path = getExclusionChannelsList();
  const safePage = Math.max(0, page);
  const offset = limit * safePage;
  return useMutation({
    mutationKey: [
      QUERY_KEY,
      `limit=${limit}`,
      `offset=${offset}`,
      `bsp_concepts=${bsp_concepts.join('')}`,
      `risk=${maximum_risk_level}`,
    ],
    mutationFn: async () => {
      return await postApiFn(path, {
        ...params,
        bsp_concepts,
        limit,
        offset,
        maximum_risk_level: Math.min(Math.max(0, maximum_risk_level), 3),
      })
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results for the sample exclusion channels list', {
            res,
          });
          return res;
        })
        .catch((error) => {
          datadogLogs.logger.error(
            'Failed to retrieve results for the sample exclusion channels list',
            {
              error,
            },
          );
          throw new Error(error);
        });
    },
  });
};

export default useExclusionChannelsListMutation;
