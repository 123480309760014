import { Box, BoxProps, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import {
  RISK_LEVEL_OPTIONS,
  filteredContentAlignmentOptionsAtom,
} from '../../atoms/contentAlignmentAtom';
import RiskSelect from '../DemoComponents/Form/RiskSelect';
import ConceptsBox from './ConceptsBox';
import ContentAlignmentSelect from './ContentAlignmentSelect';
import CreativeAlignmentBox from './CreativeAlignmentBox';

export interface ContentAlignmentProps extends BoxProps {}

const ContentAlignment: React.FC<ContentAlignmentProps> = ({
  display = 'flex',
  flexDirection = 'column',
  width = '100%',
  gap = 4,
  className = '',
  ...props
}) => {
  const dataOptions = useAtomValue(filteredContentAlignmentOptionsAtom);

  const selectElements = useMemo(() => {
    return Object.keys(dataOptions['select'] || {}).map((key) => {
      return <ContentAlignmentSelect key={key} id={key} />;
    });
  }, [dataOptions]);

  return (
    <Box
      className={`content-alignment-container${className ? ` ${className}` : ''}`}
      {...props}
      {...{ width, display, flexDirection, gap }}
    >
      <Typography className={`content-alignment-text`} variant="subtitle1">
        What type of content do you want to surround?
      </Typography>
      <Box
        className={`content-alignment-select-elements`}
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        gap={5}
      >
        {selectElements}
        <RiskSelect
          required={true}
          label="Brand Risk Tolerance Level"
          options={RISK_LEVEL_OPTIONS}
        />
      </Box>
      <Typography className={`content-alignment-prefences-text`} variant="subtitle1">
        What are your brand content preferences? (Optional)
      </Typography>
      <Grid
        container
        sx={(theme) => {
          return {
            '& > *': { maxHeight: '18rem', minWidth: '15rem' },
            '& .placeholder-box': {
              height: '100%',
            },
          };
        }}
        alignItems={'stretch'}
        spacing={2}
        flexWrap={'wrap'}
        className={`content-alignment-preferences-container`}
      >
        <Grid md={4} flexGrow={1} className={`content-alignment-preferences-item inclusions-box`}>
          <ConceptsBox isExclusion={false} />
        </Grid>
        <Grid md={4} flexGrow={1} className={`content-alignment-preferences-item exclusions-box`}>
          <ConceptsBox isExclusion={true} />
        </Grid>

        <Grid
          md={4}
          flexGrow={1}
          className={`content-alignment-preferences-item content-alignment-box`}
        >
          <CreativeAlignmentBox />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentAlignment;
