import { Box } from '@mui/material';
import React from 'react';
import Link from '../../../gatsby-theme-zefr/components/Link';
import useRiskFormDialog from '../../hooks/dialog/useRiskFormDialog';
import TextGridItem, { TextGridItemProps } from './TextGridItem';

export interface RiskGridItemProps extends TextGridItemProps {}

const RiskGridItem: React.FC<RiskGridItemProps> = ({ ...props }) => {
  const handleClick = useRiskFormDialog();
  return (
    <TextGridItem
      valueTypographyProps={{
        component: Link,
        onClick: (e) => {
          e.preventDefault();
          handleClick();
        },
        sx: (theme) => ({
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }),
        ...{
          to: '#',
        },
      }}
      tooltipTitle={
        <Box display={'flex'} flexDirection={'column'}>
          Brand safety risk tolerance determines the types of content within sensitive categories as
          per the GARM framework, that are permitted in the campaign.
          <Box display={'flex'} flexDirection={'column'} py={1} gap={1}>
            <Box>
              Low risk includes sensitive content that is educational and informative in nature.
            </Box>
            <Box>
              Medium risk includes dramatic depictions, breaking news, and op-eds of sensitive
              content.
            </Box>
            <Box>
              High risk includes suggestive, glamorous / gratuitous, and extreme depictions of
              sensitive content.
            </Box>
          </Box>
        </Box>
      }
      {...props}
    />
  );
};

export default RiskGridItem;
