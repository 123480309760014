import React, { useCallback, useRef } from 'react';
import { Button, Paper } from '@mui/material';
import { SOFT_BLUE } from '../../gatsby-theme-zefr/theme';
import { useDemoForm, KPI_STEP, FINAL_STEP } from '../context/DemoFormContext';
import FormStepExclusionsRoute from './DemoComponents/Form/StepExclusionChoice/FormStepExclusionsRoute';
import StepExclusionResults from './DemoComponents/Form/StepExclusionResults/StepExclusionResults';
import FormStepInclusionForm from './DemoComponents/Form/StepInclusionChoice/FormStepInclusionForm';
import StepInclusionResults from './DemoComponents/Form/StepInclusionResults/StepInclusionResults';
import FormStepKpiDetails from './DemoComponents/Form/StepKpiDetails/StepKpiDetails';
import FormStepListTypeChoice from './DemoComponents/Form/StepListTypeChoice/FormStepListTypeChoice';
import FormStepPlatformChoice from './DemoComponents/Form/StepPlatformChoice/FormStepPlatformChoice';

const MultiStepSalesForm = () => {
  const { finalStep } = useDemoForm();

  const formRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
    }, []
  );

  return (
    <Paper
      component={'form'}
      onSubmit={handleSubmit}
      sx={{
        borderRadius: '15px',
        backgroundColor: !finalStep ? SOFT_BLUE : 'transparent',
        boxShadow: !finalStep ? `0px 0px 5px 0px rgba(255, 255, 255, 1)` : '',
        border: 'none',
        borderEndEndRadius: 0,
        borderEndStartRadius: 0,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        px: !finalStep ? 4 : undefined,
        height: '100%',
        width: '100%',
      }}
    >
      <FormStepPlatformChoice step={1} />
      <FormStepKpiDetails step={KPI_STEP} />
      <FormStepListTypeChoice step={3} />
      <FormStepExclusionsRoute step={4} />
      <FormStepInclusionForm step={4} />
      <StepExclusionResults step={FINAL_STEP} />
      <StepInclusionResults step={FINAL_STEP} />
      <Button sx={{ display: 'none' }} type="submit" ref={formRef} />
    </Paper>
  );
};

export default MultiStepSalesForm;
