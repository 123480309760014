import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { getLanguagesList } from '../../../api/config/growth/v0';
import { getApiFn } from '../../../api/helper';
import { ProxyLanguage } from '../../../types/proxyApi';
import { LANGUAGE_NAME_TO_CODE_MAP } from './languageCodes';

export const QUERY_KEY = 'languages';

interface RequestResponse {
  languages: ProxyLanguage[];
}

type Response = RequestResponse;

const useLanguagesList = () => {
  const path = getLanguagesList();
  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      return await getApiFn(path)
        .then((res: Response) => {
          datadogLogs.logger.info('Retrieved results for the languages list', { res });
          const languages = res.languages.filter((i) => i.selectable);
          const languageOptions: Record<string, ProxyLanguage> = {};
          languages.forEach((l) => {
            languageOptions[l.language] = {
              ...l,
              code: LANGUAGE_NAME_TO_CODE_MAP[l.language]
            };
          });

          return languageOptions;
        })
        .catch((error) => {
          datadogLogs.logger.error('Failed to retrieve results for the languages list', {
            error,
          });
          throw new Error(error);
        });
    },
    // Only make the request once, and keep the results for the rest of our form creation
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });
};

export default useLanguagesList;
