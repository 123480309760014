import { atom } from 'jotai';

type State = string | undefined;

const initialState: State = undefined;

const pageTitleAtom = atom(initialState as State);

pageTitleAtom.debugLabel = 'Current Page Title';

export { pageTitleAtom };
