/* eslint-disable max-len, no-mixed-operators, prefer-arrow-callback, valid-jsdoc */
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDemoForm } from '../../../../context/DemoFormContext';
import {
  INCLUSION_LIST_FORM,
  INCLUSION_LIST_PREFAB,
  INCLUSION_LIST_PROMPT
} from '../../../../constants/listTypes';
import { LaunchDarklyFlagSet } from '../../../../types/launchDarkly';
import FormStepDemoPlatformCards from '../FormStepDemoPlatformCards/FormStepDemoPlatformCards';
import { FormStepProps } from '../types';
import FormStepInclusionCustomForm from './FormStepInclusionCustomForm';
import FormStepInclusionFormFreeText from './FormStepInclusionFormFreeText';
import FormStepInclusionPrefabForm from './FormStepInclusionPrefabForm';

export const FormStepInclusionForm: React.FC<FormStepProps> = ({ step: myStep = 2, ...props }) => {
  const { t } = useTranslation();
  const {
    step,
    inclusionFormTypeData,
    listType,
    inclusionListType,
    setFormInclusionListType,
    setStep,
  } = useDemoForm();
  /* #region 🐙 States */
  /* #endregion */
  /* #region 🎃 Events Handlers */
  const { inclusionTextEnabled } = useFlags<LaunchDarklyFlagSet>();
  const handleSetValue = useCallback(
    (value: string) => {
      setFormInclusionListType(value);
      setStep(step + 1);
    },
    [setStep, step, setFormInclusionListType],
  );
  if (listType !== 'inclusion') {
    return null;
  }

  if (step === myStep + 1) {
    if (inclusionListType === INCLUSION_LIST_PROMPT && inclusionTextEnabled) {
      return <FormStepInclusionFormFreeText step={myStep + 1} />;
    } else if (
      inclusionListType === INCLUSION_LIST_PREFAB ||
      (!inclusionTextEnabled && inclusionListType === INCLUSION_LIST_PROMPT)
    ) {
      return <FormStepInclusionPrefabForm step={myStep + 1} />;
    } else if (inclusionListType === INCLUSION_LIST_FORM) {
      return <FormStepInclusionCustomForm step={myStep + 1} />;
    }
  }

  /* #endregion */
  return (
    <FormStepDemoPlatformCards
      titleLabel={`How do you want to build your lists?`}
      options={inclusionFormTypeData}
      selectedOption={inclusionListType}
      setValue={handleSetValue}
      floatingIcon={true}
      step={myStep}
      isNextHidden={!Boolean(inclusionListType)}
      {...props}
    />
  );
};

export default FormStepInclusionForm;
