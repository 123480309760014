import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { appAtom } from '../../gatsby-theme-zefr/atoms/appAtom';
import { SITE_APP_NONE, SiteApps } from '../../gatsby-theme-zefr/types/shared';

const useApp = (appName: SiteApps) => {
  const [app, setApp] = useAtom(appAtom);
  useEffect(() => {
    // When mounted, if our app is not selected switch to it
    if (app !== appName) {
      setApp(appName);
    }
    return () => {
      // When unmounted, if our app is still selected switch to NONE
      if (app === appName) {
        setApp(SITE_APP_NONE);
      }
    };
  }, [setApp, app]);

  return useMemo(() => {
    return {
      app,
      setApp,
    };
  }, [app, setApp]);
};

export default useApp;
