import { RouteComponentProps, Router } from '@reach/router';
import React from 'react';
import useApp from '../../gatsby-theme-zefr/hooks/useApp';
import { SITE_APP_GROWTH } from '../../gatsby-theme-zefr/types/shared';
import SinglePageTools from './SinglePageTools/router';

// This component holds all locations for our app that uses authenticated client-only routes
export const GrowthApp: React.FC<RouteComponentProps> = ({ ...props }) => {
  useApp(SITE_APP_GROWTH);

  return (
    <Router className="h-full" {...props}>
      <SinglePageTools path="/*" />
    </Router>
  );
};

export default GrowthApp;
