import { Language } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import React, { useCallback } from 'react';
import useLanguageFormDialog from '../../../hooks/dialog/useLanguageFormDialog';

export interface LanguageSelectIconButtonProps extends Omit<ButtonProps, 'children'> {}

const LanguageSelectIconButton: React.FC<LanguageSelectIconButtonProps> = ({
  onClick = () => {},
  ...props
}) => {
  const openForm = useLanguageFormDialog();
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      onClick(e);
      openForm();
    },
    [openForm],
  );
  return (
    <Button
      color={'primary'}
      variant="contained"
      sx={{
        minWidth: 'unset',
        width: '3em',
        height: '3em',
        padding: 0,
        '& > .MuiSvgIcon-root': {
          width: '1.25rem',
          height: '1.25rem',
        },
      }}
      {...props}
      onClick={handleClick}
    >
      <Language />
    </Button>
  );
};

export default LanguageSelectIconButton;
