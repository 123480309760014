import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import targetAudienceOptionsFormAtom, {
  targetAudienceOptionsAtom,
  writeTargetAudienceOptionFormAtom,
} from './atom';

export interface TargetAudienceSelectFormProps extends FormControlProps {}

const TargetAudienceSelectForm: React.FC<TargetAudienceSelectFormProps> = ({
  fullWidth = true,
  id = '',
  ...props
}) => {
  const items = useAtomValue(targetAudienceOptionsAtom);
  const setTargetAudienceFormOption = useSetAtom(writeTargetAudienceOptionFormAtom);
  const activeItems = useAtomValue(targetAudienceOptionsFormAtom);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleSelect = useCallback(
    (event: SelectChangeEvent<string[]>, key: string) => {
      const {
        target: { value },
      } = event;
      setTargetAudienceFormOption({
        key,
        // On autofill we get a stringified value.
        value: typeof value === 'string' ? value.split(',') : value,
      });
      forceUpdate();
    },
    [setTargetAudienceFormOption, forceUpdate],
  );
  const data = useMemo(() => {
    if (id) {
      const v = items[id];
      if (v) {
        return v;
      }
    }
    return undefined;
  }, [id, items]);

  return (
    <Grid2 xs={12} md={6}>
      <FormControl {...props} {...{ fullWidth }}>
        <InputLabel id={`${id}-label`}>{data?.label}</InputLabel>
        <Select
          defaultValue={((activeItems || {})[id] as string[]) || []}
          value={((activeItems || {})[id] as string[]) || []}
          onChange={(e: SelectChangeEvent<string[]>) => {
            handleSelect(e, id);
          }}
          labelId={`${id}-label`}
          label={data?.label}
        >
          {Object.keys(data?.options || {}).map((optionKey) => {
            const label = data?.options![optionKey];
            return (
              <MenuItem value={optionKey} key={optionKey}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid2>
  );
};
export default TargetAudienceSelectForm;
