import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import React from 'react';
import { setFormCreativeAlignmentAtom } from '../../atoms/encodedFormAtoms';
import CreativeAlignment from '../CreativeAlignment/CreativeAlignment';
import {
  creativeAlignmentLocalAtom,
  demoCreativeAlignmentDialogOpenAtom,
} from '../CreativeAlignment/atom';

export interface DemoCreativeAlignmentDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {}

const DemoCreativeAlignmentDialog: React.FC<DemoCreativeAlignmentDialogProps> = ({ ...props }) => {
  const setFormValue = useSetAtom(setFormCreativeAlignmentAtom);
  const [localValue, setLocalValue] = useAtom(creativeAlignmentLocalAtom);
  const [openDialog, setOpenDialog] = useAtom(demoCreativeAlignmentDialogOpenAtom);
  const handleClose = React.useCallback(() => {
    setOpenDialog(undefined);
  }, [setOpenDialog]);

  const handleSave = React.useCallback(() => {
    setFormValue(localValue || '');
    setLocalValue(RESET);
    handleClose();
  }, [setFormValue, localValue, handleClose, setLocalValue]);

  return (
    <Dialog
      fullWidth={true}
      onClose={handleClose}
      // disableRestoreFocus prevents the page from jumping on dialog close
      disableRestoreFocus={true}
      open={Boolean(openDialog)}
      maxWidth={'md'}
      sx={{ m: 1 }}
      {...props}
    >
      <Box
        position={'relative'}
        px={12}
        py={8}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
          Content Preferences
        </Typography>
      </Box>
      <DialogContent>
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          gap={1}
        >
          <CreativeAlignment />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-evenly' }}>
        <Button size={'large'} variant="contained" onClick={handleClose} color="info">
          Cancel
        </Button>
        <Button size={'large'} variant="contained" onClick={handleSave}>
          Add Channels
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoCreativeAlignmentDialog;
