import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import { ColoredDivider } from '../../gatsby-plugin-zefr-growth/components/DemoComponents/Form/components';
import { markdownAtom } from '../atoms/markdownAtom';
import { markdownDialogOpenAtom } from '../atoms/markdownDialogOpenAtom';
import MarkdownHelper from './MarkdownHelper';

export interface MarkdownDialogProps extends Omit<DialogProps, 'open' | 'onClose'> {}

const MarkdownDialog: React.FC<MarkdownDialogProps> = ({ ...props }) => {
  const [openDialog, setOpenDialog] = useAtom(markdownDialogOpenAtom);
  const handleClose = React.useCallback(() => {
    setOpenDialog(undefined);
  }, [setOpenDialog]);
  const internalMarkdown = useAtomValue(markdownAtom);

  const handleCopy = useCallback(async () => {
    const previousValue = await navigator.clipboard.readText();
    let m = '';
    m = internalMarkdown?.replace(/(?:\r\n|\r|\n)/g, '\\n') || '';

    await navigator.clipboard.writeText(m || previousValue);
  }, [internalMarkdown]);

  return (
    <Dialog
      fullWidth={true}
      fullScreen={true}
      onClose={handleClose}
      // disableRestoreFocus prevents the page from jumping on dialog close
      disableRestoreFocus={true}
      open={Boolean(openDialog)}
      sx={{ m: 1 }}
      {...props}
    >
      <Box
        position={'relative'}
        px={12}
        py={8}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <IconButton sx={{ left: '25px', position: 'absolute' }} onClick={handleClose}>
          <Close />
        </IconButton>
        <Typography variant="h5" color="black" fontWeight={'800'} textAlign={'center'}>
          Markdown Helper
        </Typography>
        <ColoredDivider sx={{ bottom: '15px', position: 'absolute' }} />
      </Box>
      <DialogContent>
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          gap={1}
        >
          <MarkdownHelper />
        </Box>
      </DialogContent>
      <DialogActions data-testid="markdown-actions" sx={{ justifyContent: 'center' }}>
        <Button size={'large'} variant="contained" onClick={handleCopy}>
          Copy to Clipboard
        </Button>
        <Button size={'large'} variant="contained" onClick={handleClose}>
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkdownDialog;
